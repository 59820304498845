import * as Sentry from '@sentry/browser';
import axios, { AxiosHeaders } from 'axios';
import { apiSetting } from 'config';
import { auth } from 'firebase-app';
import { logErrors } from 'helpers/logging';
import { toast } from 'react-toastify';
import { ApiCallParams, HTTPMethods } from 'types';

const { LOGOUT_WAIT_TIME } = apiSetting;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_VERSION_2 = 'v2';

function logout() {
  setTimeout(() => {
    document.location.href = '/logout';
  }, LOGOUT_WAIT_TIME);
}
export class NetworkError extends Error {
  description: any;
  constructor(msg: string | undefined, description: any) {
    super(msg);
    this.name = 'NetworkError';
    this.description = description;
  }
}

export const apiCallV2 = async ({
  method = HTTPMethods.GET,
  url,
  headerOptions = {},
  params = undefined,
  data = undefined,
  signal = undefined,
  redirect404Error = false,
}: ApiCallParams) => {
  let headers = new AxiosHeaders();

  const token = await auth?.currentUser?.getIdToken();

  headers = {
    ...(token ? { authorization: `Bearer ${token}` } : {}),
    'Content-Type': headerOptions['Content-Type']
      ? headerOptions['Content-Type']
      : 'application/json',
    'x-time_zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    ...headerOptions,
  };

  try {
    const response = await axios({
      method,
      url,
      baseURL: `${API_BASE_URL}/${API_VERSION_2}/`,
      params,
      headers,
      data,
      signal,
    });

    if (response.status < 200 || response.status >= 300) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    if (response?.data.status === false) {
      throw new Error(`HTTP error status: ${response.status}`);
    }
    return response?.data;
  } catch (error: any) {
    if (error?.response?.status === 403) {
      window.open('/errors/403', '_self');
      toast.error('Permission denied!');
      return;
    }
    if (redirect404Error && error?.response?.status === 404) {
      window.open('/errors/404', '_self');
      return;
    }
    if (error?.response?.status === 401) {
      toast.error('Permission denied! Please log in again!');
      logout();
      return;
    }

    const networkError = new NetworkError(error?.response?.data?.message, {
      statusText: error.response.statusText,
      errorCode: error.response.status,
      ...error?.response?.data?.data,
    });

    if (error?.response?.status === 500 || error?.response?.status === 404) {
      Sentry.captureException(networkError, {
        level: 'debug',
        extra: {
          url,
          method,
          headers,
          params,
          data,
          signal,
        },
      });
    }

    throw networkError;
  }
};
