import { t } from 'i18next';
import IconFA from 'components/common/IconFA';
import { Flex, Grid, Text, Tooltip } from '@mantine/core';
import {
  SectionBody,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { currencyFormatter } from 'helpers/utils';
import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import ReportItem from 'pages/affiliates/ReportItem';

type PropType = {
  customer?: any;
};
const CustomerSaleSummary = (props: PropType) => {
  const { customer } = props;
  const renderSummaryItem = ({
    title,
    decs,
    value,
    format,
  }: {
    title: string;
    decs: string;
    value: number;
    format?: (x: number) => string;
  }) => {
    return (
      <Grid.Col span={{ base: 6, sm: 3 }}>
        <ReportItem
          label={title}
          note={decs}
          current={value || 0}
          format={format}
          viewOnly
        />
      </Grid.Col>
    );
  };
  return (
    <SectionWrapper>
      <SectionBody>
        <Grid>
          {[
            {
              title: t('dashboard.total_paid_orders'),
              decs: t('dashboard.total_paid_orders_desc'),
              value: customer?.sale_report?.total_order,
            },
            {
              title: t('dashboard.total_paid_sales'),
              decs: t('dashboard.total_paid_sales_desc'),
              value: customer?.sale_report?.total_sale,
              format: (x: number) => currencyFormatter.format(x),
            },
            {
              title: t('dashboard.total_unpaid_orders'),
              decs: t('dashboard.total_unpaid_orders_desc'),
              value: customer?.sale_report?.total_unpaid_order,
            },
            {
              title: t('dashboard.total_unpaid_sales'),
              decs: t('dashboard.total_unpaid_sales_desc'),
              value: customer?.sale_report?.total_unpaid_sale,
              format: (x: number) => currencyFormatter.format(x),
            },
            {
              title: t('dashboard.total_refunded_orders'),
              decs: t('dashboard.total_refunded_orders_desc'),
              value: customer?.sale_report?.total_refunded_order,
            },
            {
              title: t('dashboard.total_refunded_sales'),
              decs: t('dashboard.total_refunded_sales_desc'),
              value: customer?.sale_report?.total_refunded_sale,
              format: (x: number) => currencyFormatter.format(x),
            },
          ]?.map((item) => renderSummaryItem(item))}
        </Grid>
      </SectionBody>
    </SectionWrapper>
  );
};

export default CustomerSaleSummary;
