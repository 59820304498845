import { faMap, faPen, faTrash } from '@fortawesome/pro-light-svg-icons';
import { AddressInfo } from 'types';
import IconFA from 'components/common/IconFA';
import CopyButton from 'components/MantineUI/Buttons/CopyButton';
import { phoneFormatter } from 'helpers/utils';
import { Badge, Flex, Text } from '@mantine/core';
import { useUserProfile } from 'apis/AuthApis';
import { useTranslation } from 'react-i18next';

type Props = {
  address: AddressInfo;
  className?: string;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  viewOnly?: boolean;
};

const AddressInfoComponent = ({
  address,
  className,
  onClickEdit,
  onClickDelete,
  viewOnly,
}: Props) => {
  const formattedPhone = phoneFormatter(address?.phone);
  const { isSeller, isFulfillSeller } = useUserProfile();
  const { t } = useTranslation();

  return (
    <div className={className}>
      <div className="flex gap-2 mb-1 items-center">
        <Text fw={600}>
          {address?.f_name} {address?.l_name}
        </Text>
        <Badge color="blue" variant="light">
          {address?.type === 'business'
            ? t('orders.business_address')
            : t('orders.residential_address')}
        </Badge>
      </div>

      {address?.business_name ? (
        <div className="flex flex-row items-center justify-between">
          <Text>{address?.business_name}</Text>
        </div>
      ) : null}
      <div className="flex flex-row items-center justify-between mb-1">
        <Text>{address?.location}</Text>
      </div>
      {!isSeller && !isFulfillSeller ? (
        <Text size="sm">{formattedPhone}</Text>
      ) : null}
      <Flex align={'center'}>
        <div className="flex-1">
          {address?.is_default ? (
            <Badge bg="gray.3" c={'gray.7'}>
              {t('general.default')}
            </Badge>
          ) : null}
        </div>
        <Flex>
          {!viewOnly && onClickEdit ? (
            <IconFA color="gray" onClick={onClickEdit} icon={faPen} />
          ) : null}
          {!viewOnly && onClickDelete ? (
            <IconFA color="gray" onClick={onClickDelete} icon={faTrash} />
          ) : null}
          <IconFA
            color="gray"
            onClick={() =>
              window.open(
                `https://maps.google.com?q=${address?.location}`,
                '_blank',
              )
            }
            icon={faMap}
          />
          {address?.location && <CopyButton value={address?.location} />}
        </Flex>
      </Flex>
    </div>
  );
};
export default AddressInfoComponent;
