import { t } from 'i18next';
import { checkPermission } from 'helpers/utils';
import { useState } from 'react';
import { useGetCustomers } from 'apis/CustomersApi';
import { getColor, phoneFormatter } from '../../../helpers/utils';
import CopyButton from 'components/MantineUI/Buttons/CopyButton';
import { useAuth } from 'contexts/AuthContext';
import { CustomerItem } from 'types';
import { SectionBody, SectionHeader, SectionWrapper } from '../CommonSection';
import { Button, Flex } from '@mantine/core';
import IconFA from 'components/common/IconFA';
import { faAdd } from '@fortawesome/pro-light-svg-icons';
import CustomerSelect from '../Selects/CustomerSelect';
import AddEditModal from '../Modals/AddEditModal';
import SellerFormModal from 'pages/sellers/components/SellerFormModal';
import { UserFormModal } from 'pages/customers/components/UserFormModal';
import CustomerDetailsModal from 'pages/customers/components/CustomerDetailsModal';

type Props = {
  customer?: CustomerItem;
  onSave: (cus?: CustomerItem) => void;
  isCompleted?: boolean;
};

const CustomerCard = ({ customer, onSave, isCompleted }: Props) => {
  const { first_name, last_name, email, phone_number } = customer || {};

  const [tempCus, setTempCus] = useState<CustomerItem>();
  const [editing, setEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState();

  const onAddCustomer = () => {
    setShowModal(true);
  };

  return (
    <SectionWrapper>
      <SectionHeader
        isSubSection
        title={t('orders.customer')}
        actionButtons={
          <>
            {isCompleted ? null : customer ? (
              editing ? (
                <Flex gap="xs">
                  <Button
                    variant="default"
                    size="xs"
                    onClick={() => setEditing(false)}
                  >
                    {t('general.cancel')}
                  </Button>
                  <Button
                    variant="outline"
                    size="xs"
                    onClick={() => {
                      onSave?.(tempCus);
                      setEditing(false);
                    }}
                  >
                    {t('general.save')}
                  </Button>
                </Flex>
              ) : (
                <Button
                  variant="default"
                  size="xs"
                  onClick={() => setEditing(true)}
                >
                  {t('general.change')}
                </Button>
              )
            ) : (
              <Flex gap="xs">
                <Button
                  variant="outline"
                  size="xs"
                  onClick={() => onAddCustomer()}
                  leftSection={<IconFA icon={faAdd} />}
                >
                  {t('general.add')}
                </Button>
                <Button
                  disabled={!tempCus}
                  variant="outline"
                  size="xs"
                  onClick={() => {
                    onSave?.(tempCus);
                    setEditing(false);
                  }}
                >
                  {t('general.save')}
                </Button>
              </Flex>
            )}
          </>
        }
      />
      <SectionBody>
        {customer && !editing ? (
          <div>
            <div className="fs--1 fw-semi-bold text-black">
              {first_name} {last_name}
            </div>
            {email ? (
              <div className="d-flex flex-row justify-content-between align-items-center fs--1 text-black">
                <div className="flex-1 overflow-hidden">{email}</div>
                <CopyButton value={email} />
              </div>
            ) : null}
            {phone_number ? (
              <div className="d-flex flex-row justify-content-between align-items-center fs--1 text-black">
                <div className="flex-1 overflow-hidden">
                  {phoneFormatter(phone_number)}
                </div>
                <CopyButton value={phone_number} />
              </div>
            ) : null}
          </div>
        ) : (
          <div>
            <CustomerSelect
              value={tempCus}
              onChange={(value: any) =>
                setTempCus(value?.origin as CustomerItem)
              }
            />
          </div>
        )}
      </SectionBody>
      {/* Add/edit modal */}
      <CustomerDetailsModal
        opened={!!showModal}
        onClose={() => setShowModal(false)}
        value={showModal}
      />
      {/* <AddEditModal
        responseData={(res: any) => {
          onSave?.(res);
        }}
        size="xl"
        onHide
        title={t('customer.create_customer')}
        seller={user}
        setValue={setUser}
        show={showModal}
        setShow={setShowModal}
        ValueForm={UserFormModal}
        // refetch={refetch}
      /> */}
    </SectionWrapper>
  );
};
export default CustomerCard;
