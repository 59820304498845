import OrderPackingList from 'pages/orders/packing-list-view';

const emptyLoggedInRoutes = [
  {
    access: 'orders',
    path: '/orders/:id/packing-list',
    isSeller: true,
    isAdmin: true,
    component: OrderPackingList,
  },
];
export default emptyLoggedInRoutes;
