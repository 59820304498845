import { Anchor, Flex, Text } from '@mantine/core';
import CopyButton from 'components/MantineUI/Buttons/CopyButton';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { phoneFormatter } from 'helpers/utils';
import { t } from 'i18next';
import { getCustomerTitle } from 'pages/customers';

type PropType = {
  customer?: any;
};

const CustomerInfoCard = (props: PropType) => {
  // ### CONSTANTs
  const { customer } = props;
  const {
    ref_code,
    email,
    business_name,
    business_phone,
    phone_number,
    title_type,
  } = customer || {};
  const refLink =
    process.env.REACT_APP_WEBSITE_URL + '/auth?ref_code=' + ref_code;
  // ### RENDERs
  const InfoList = [
    {
      label: t('customer.referral_code'),
      value: ref_code,
      copy: true,
    },
    {
      label: t('customer.referral_link'),
      value: refLink,
      formattedValue: (
        <Anchor href={refLink} target="_blank">
          nailzy.com/auth?ref_code={ref_code}
        </Anchor>
      ),
      copy: true,
    },
    {
      label: 'Email',
      value: email,
      copy: true,
    },
    {
      label: t('general.phone'),
      value: phone_number,
      formattedValue: phoneFormatter(phone_number),
      copy: true,
    },
    {
      label: t('customer.business_name'),
      value: business_name,
      copy: true,
    },
    {
      label: t('customer.business_phone'),
      value: business_phone,
      formattedValue: phoneFormatter(business_phone),
      copy: true,
    },
    {
      label: t('customer.role_type'),
      value: t(getCustomerTitle(title_type)),
      copy: false,
    },
    {
      label: t('customer.auth_provider'),
      value: '',
      copy: false,
    },
  ];

  return (
    <SectionWrapper>
      <SectionHeader
        isSubSection
        title={t('navigation.information')}
      ></SectionHeader>
      <SectionBody>
        {InfoList?.map((info, index) => {
          return (
            <div key={index} className="mb-3">
              <Text size="sm" c={'gray.7'}>
                {info?.label}
              </Text>
              <Flex align={'center'} justify={'space-between'}>
                {info?.value ? (
                  <>
                    <Text size="sm">{info?.formattedValue || info?.value}</Text>
                    {info?.copy ? <CopyButton value={info?.value} /> : null}
                  </>
                ) : (
                  '--'
                )}
              </Flex>
            </div>
          );
        })}
      </SectionBody>
    </SectionWrapper>
  );
};
export default CustomerInfoCard;
