import { useMutation, useQuery } from '@tanstack/react-query';
import { apiCallV2 } from './ApiCallV2';

export const usePromotionDetail = (id: string) =>
  useQuery({
    queryKey: ['usePromotionDetail', id],
    queryFn: () =>
      apiCallV2({
        method: 'get',
        url: 'admin/promotions/' + id,
        redirect404Error: true,
      }),
    enabled: !!id,
  });

export const useSelectedPromotionProducts = (params: any) => {
  const { promotion_id, ...els } = params;
  return useQuery({
    queryKey: ['useSelectedPromotionProducts', params],
    queryFn: () =>
      apiCallV2({
        method: 'get',
        url: `/admin/promotion-products/${promotion_id}`,
        params: { ...els, sort: 'name_desc' },
      }),
    enabled: !!promotion_id,
  });
};

export const useFetchSelectedPromotionProductVariants = () => {
  return useMutation({
    mutationFn: ({ product_id, promotion_id }: any) => {
      const url = `/admin/promotion-products/${promotion_id}/products/${product_id}`;
      return apiCallV2({
        url,
      });
    },
  });
};

export const usePromotionProducts = (params: any) => {
  const { promotion_id, ...els } = params;
  return useQuery({
    queryKey: ['usePromotionProducts', params],
    queryFn: () =>
      apiCallV2({
        method: 'get',
        url: `/admin/products?promotion_id=${promotion_id}`,
        params: els,
      }),
    enabled: !!promotion_id,
  });
};

export const useFetchPromotionProductVariants = () => {
  return useMutation({
    mutationFn: ({ product_id, promotion_id }: any) => {
      const url = `/admin/products/${product_id}/variants?promotion_id=${promotion_id}`;
      return apiCallV2({
        url,
      });
    },
  });
};

export const useSavePromotionProducts = () => {
  return useMutation({
    mutationFn: ({ promotion_id, params }: any) => {
      const url = `/admin/promotion-products/${promotion_id}`;
      return apiCallV2({
        url,
        method: 'PUT',
        data: params,
      });
    },
  });
};
