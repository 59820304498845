import { Button, Grid, InputLabel, Select, Switch } from '@mantine/core';
import { StatusEnum } from 'types';
import { useEffect, useState } from 'react';
import { SectionFooter } from 'components/MantineUI/CommonSection';
import { t } from 'i18next';
import { useURLParams } from 'hooks/useURLParams';
import StatusSelect from 'components/MantineUI/Selects/StatusSelect';
type PropType = {
  onClose?: () => void;
};
type CommissionRateFilterType = {
  status?: StatusEnum;
};

const CommissionRateFilter = (props: PropType) => {
  const { onClose } = props;
  const { params, handleParamsChange }: any = useURLParams();
  // ### CONSTANTs
  const [filter, setFilter] = useState<CommissionRateFilterType>({});
  // ### FUNCTIONs
  useEffect(() => {
    setFilter({
      status: params?.status,
    });
  }, []);

  const onApply = () => {
    handleParamsChange({
      page: 1,
      status: filter?.status || undefined,
    });
    onClose?.();
  };
  const onReset = () => {
    setFilter({});
    handleParamsChange({
      page: 1,
      status: undefined,
    });
    onClose?.();
  };
  // ### RENDERs
  return (
    <Grid>
      <Grid.Col span={{ base: 12 }}>
        <StatusSelect
          hideDraft
          hidePending={false}
          required={false}
          nativeSelect={false}
          value={filter?.status}
          onChange={(value: any) => {
            setFilter((prev) => ({
              ...prev,
              status: value,
            }));
          }}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12 }}>
        <SectionFooter>
          <Button onClick={onApply}>{t('general.apply')}</Button>
          <Button onClick={onReset} variant="default">
            {t('general.reset')}
          </Button>
        </SectionFooter>
      </Grid.Col>
    </Grid>
  );
};
export default CommissionRateFilter;
