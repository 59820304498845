import { Badge, Flex, Text } from '@mantine/core';

const CouponItem = ({ item }: { item: any }) => {
  const { name, code, type, value, total, disabled } = item || {};

  return (
    <Flex
      align={'center'}
      gap={'xs'}
      className="w-100"
      style={
        disabled
          ? {
              opacity: 0.5,
            }
          : {}
      }
    >
      <Badge variant="light" color="red">
        <Text size="xs">
          -{type === 'percentage' ? `${total || value}%` : `$${total || value}`}
        </Text>
      </Badge>
      <Flex align={'center'} gap={'xs'} className={`flex-1 `}>
        <Text size="xs" fw={'bold'} className="one-line-text">
          {name}
        </Text>
        <Text size="xs" c={'dimmed'}>
          {code}
        </Text>
      </Flex>
    </Flex>
  );
};
export default CouponItem;
