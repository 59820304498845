import { Anchor, Button, Flex } from '@mantine/core';
import {
  CommissionRateItem,
  CommissionRateSortEnum,
  CommonTableColumn,
} from 'types';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import PageLayout from 'components/MantineUI/PageLayout';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePaginationParams } from 'hooks/usePaginationParams';
import { CommonTable, TableSearchBox } from 'components/MantineUI/CommonTable';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';
import IconFA from 'components/common/IconFA';
import { faEllipsis, faPlus } from '@fortawesome/pro-solid-svg-icons';
import {
  useCommissionRates,
  useDeleteCommissionRate,
} from 'apis/AffiliateApis';
import { dateFormatter } from 'helpers/utils';
import StatusBadge from 'components/MantineUI/StatusBadge';
import SkeletonWrapper from 'components/MantineUI/Skeleton/SkeletonWrapper';
import ListPageSkeleton from 'components/MantineUI/Skeleton/ListPageSkeleton';
import AddCommissionRateModal from './AddCommissionRateModal';
import DeleteWarningModal from 'components/common/DeleteWarningModal';
import FilterButton from 'components/MantineUI/Buttons/FilterButton';
import SortButton from 'components/MantineUI/CommonTable/SortButton';
import { Link } from 'react-router-dom';
import { useURLParams } from 'hooks/useURLParams';
import CommonDrawer from 'components/MantineUI/CommonDrawer';
import CommissionRateFilter from './CommissionRateFilter';

const CommissionRates = () => {
  // ### CONSTANTs
  const { t } = useTranslation();
  const { params }: any = useURLParams();
  const { page, limit, onPaginationChange } = usePaginationParams();
  const [deleteCR, setDeleteCR] = useState<CommissionRateItem | undefined>();
  const [showFilter, setShowFilter] = useState(false);

  const [selectedCommissionRate, setSelectedCR] = useState<
    CommissionRateItem | undefined | {}
  >();
  const { mutate: deleteCommissionRate, isPending: deleting } =
    useDeleteCommissionRate(deleteCR?._id);
  // ### FUNCTIONs
  const { data, isLoading, isRefetching } = useCommissionRates({
    ...params,
  });
  const rates = data?.data?.results || [];
  const total = data?.data?.total;
  // ### RENDERs
  const title = t('affiliates.commission_rates');
  const onDeleteCR = () => {
    if (deleteCR?._id) {
      deleteCommissionRate(undefined, {
        onSuccess: () => setDeleteCR(undefined),
        onError: () => setDeleteCR(undefined),
      });
    }
  };
  const columns: CommonTableColumn[] = [
    {
      accessor: 'name',
      Header: t('general.name'),
      Cell: (row: CommissionRateItem) =>
        row?._id ? (
          <Anchor
            size="sm"
            component={Link}
            to={'/affiliates/commission-rates/' + row?._id}
          >
            {row?.name}
          </Anchor>
        ) : (
          row?.name || '--'
        ),
    },
    {
      accessor: 'commission_rates',
      Header: t('affiliates.commission_rate'),
      Cell: (row: CommissionRateItem) =>
        row?.commission_rate
          ? `${parseFloat((row?.commission_rate * 100).toFixed(2))}%`
          : '--',
    },
    {
      accessor: 'products',
      Header: t('orders.products'),
      Cell: (row: CommissionRateItem) => row?.total_products || '--',
    },
    {
      accessor: 'created_on',
      Header: t('general.created_date'),
      Cell: (row: CommissionRateItem) => dateFormatter(row?.created_on) || '--',
    },
    {
      accessor: 'status',
      Header: t('general.status'),
      Cell: (row: CommissionRateItem) =>
        row?.status && <StatusBadge status={row?.status} />,
    },
    {
      accessor: 'actions',
      Cell: (row: CommissionRateItem) => (
        <DropdownButton
          button={
            <Button size="xs" variant="subtle" color="gray">
              <IconFA icon={faEllipsis} />
            </Button>
          }
          dropdownData={[
            {
              key: 'edit',
              label: t('general.edit'),
              onClick: () => setSelectedCR(row),
            },
            { isDivider: true },
            {
              key: 'delete',
              color: 'red',
              label: t('general.delete'),
              onClick: () => setDeleteCR(row),
            },
          ]}
        />
      ),
    },
  ];
  return (
    <SkeletonWrapper skeleton={<ListPageSkeleton />} show={isLoading}>
      <PageLayout
        title={title}
        breadCrumds={[
          {
            title: t('affiliates.affiliates'),
            href: '/affiliates',
          },
          { title },
        ]}
      >
        <SectionWrapper>
          <SectionHeader
            title={title}
            actionButtons={
              <Button
                onClick={() => setSelectedCR({})}
                leftSection={<IconFA icon={faPlus} />}
              >
                {t('affiliates.add_commission_rates')}
              </Button>
            }
          />

          <SectionBody>
            <Flex gap={'sm'} mb={'md'} wrap={'wrap'} align={'center'}>
              <TableSearchBox searchParams="name" />
              <FilterButton onClick={() => setShowFilter(true)} />

              <CommonDrawer
                title={t('filter.filters')}
                opened={showFilter}
                onClose={() => setShowFilter(false)}
              >
                <CommissionRateFilter onClose={() => setShowFilter(false)} />
              </CommonDrawer>
              <SortButton
                sortOptions={[
                  {
                    label: t('general.name_asc'),
                    key: CommissionRateSortEnum.NAME_ASC,
                  },
                  {
                    label: t('general.name_desc'),
                    key: CommissionRateSortEnum.NAME_DESC,
                  },
                  {
                    label: t('affiliates.rate_asc'),
                    key: CommissionRateSortEnum.COMMISSION_RATE_ASC,
                  },
                  {
                    label: t('affiliates.rate_desc'),
                    key: CommissionRateSortEnum.COMMISSION_RATE_DESC,
                  },
                  {
                    label: t('general.created_desc'),
                    key: CommissionRateSortEnum.CREATED_ON_DESC,
                  },
                  {
                    label: t('general.created_asc'),
                    key: CommissionRateSortEnum.CREATED_ON_ASC,
                  },
                  {
                    label: t('general.updated_desc'),
                    key: CommissionRateSortEnum.UPDATED_ON_DESC,
                  },
                  {
                    label: t('general.updated_asc'),
                    key: CommissionRateSortEnum.UPDATED_ON_ASC,
                  },
                ]}
              />
            </Flex>
            <CommonTable
              loading={isRefetching}
              data={rates}
              columns={columns}
              pagination={{
                page,
                limit,
                onPaginationChange,
                total,
              }}
            />
          </SectionBody>
        </SectionWrapper>
        <AddCommissionRateModal
          onClose={() => setSelectedCR(undefined)}
          opened={!!selectedCommissionRate}
          data={selectedCommissionRate}
        />
        <DeleteWarningModal
          name={`${deleteCR?.name}`}
          onShow={!!deleteCR}
          onDelete={onDeleteCR}
          onClose={() => setDeleteCR(undefined)}
          isLoading={deleting}
        />
      </PageLayout>
    </SkeletonWrapper>
  );
};
export default CommissionRates;
