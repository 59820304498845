import { Grid, NativeSelect } from '@mantine/core';
import { DatePickerInput, DatesRangeValue, DateValue } from '@mantine/dates';
import dayjs from 'dayjs';
import { t } from 'i18next';
import React, { useEffect, useMemo, useState } from 'react';
type PropType = {
  value?: ReportFilter;
  onChange?: (value: ReportFilter) => void;
};

export type ReportFilter = {
  from: number;
  to: number;
  pastFrom: number;
  pastTo: number;
  duration: number;
  durationDay: number;
  groupType: 'day' | 'hour' | 'week';
};

enum ReportRangeType {
  TODAY = 'today',
  D7 = '7day',
  D30 = '30day',
  D90 = '90day',
  CUSTOM = 'custom',
}
const ReportRangeSelect = (props: PropType) => {
  // ### CONSTANTs
  const [type, setType] = useState(ReportRangeType.TODAY);
  const { value, onChange } = props;

  const [currentRange, setCurrentRange] = useState<DatesRangeValue>([
    dayjs().startOf('date').toDate(),
    dayjs().toDate(),
  ]);
  const [durationDay, pastRange] = useMemo(() => {
    let durDay =
      dayjs(currentRange?.[1])
        ?.clone()
        ?.diff?.(dayjs(currentRange?.[0])?.clone(), 'days') + 1;
    const past = [
      dayjs(currentRange?.[0])?.clone()?.add(-durDay, 'day'),
      dayjs(currentRange?.[0])?.clone()?.add(-1, 'day'),
    ];
    return [durDay, past];
  }, [currentRange]);
  const [from, to, pastFrom, pastTo] = useMemo(() => {
    const endDate = dayjs(currentRange?.[1])
      ?.clone()
      ?.endOf('date')
      ?.set('second', 0)
      .isAfter(dayjs())
      ? dayjs()
      : dayjs(currentRange?.[1])?.clone()?.endOf('date')?.set('second', 0);

    return [
      dayjs(currentRange?.[0])
        ?.clone()
        ?.startOf('date')
        ?.set('second', 0)
        ?.unix(),
      endDate?.set('second', 0)?.unix(),
      dayjs(pastRange?.[0])?.clone()?.startOf('date')?.set('second', 0)?.unix(),
      dayjs(pastRange?.[1])?.clone()?.endOf('date')?.set('second', 0)?.unix(),
    ];
  }, [currentRange, pastRange]);

  const reportDateOptions = [
    {
      label: t('dashboard.today'),
      value: ReportRangeType.TODAY,
    },
    {
      label: t('dashboard.last_x_day', { days: 7 }),
      value: ReportRangeType.D7,
    },
    {
      label: t('dashboard.last_x_day', { days: 30 }),
      value: ReportRangeType.D30,
    },
    {
      label: t('dashboard.last_x_day', { days: 90 }),
      value: ReportRangeType.D90,
    },
    // {
    //   label: t('dashboard.last_x_day', { days: 360 }),
    //   value: '360day'
    // },
    {
      label: t('dashboard.custom'),
      value: ReportRangeType.CUSTOM,
    },
  ];
  // ### FUNCTIONs

  const onChangeType = (e: any) => {
    setType(e.target.value as ReportRangeType);
    if (e.target.value !== ReportRangeType.CUSTOM) {
      let startDate = dayjs().add(-7, 'day');
      let endDate = dayjs().add(-1, 'day');
      if (e.target.value === ReportRangeType.TODAY) {
        startDate = dayjs().startOf('day');
        endDate = dayjs();
      } else if (e.target.value === ReportRangeType.D7) {
        startDate = endDate?.clone()?.add(-6, 'day');
      } else if (e.target.value === ReportRangeType.D30) {
        startDate = endDate?.clone()?.add(-29, 'day');
      } else if (e.target.value === ReportRangeType.D90) {
        startDate = endDate?.clone()?.add(-89, 'day');
      }
      setCurrentRange?.([startDate.toDate(), endDate.toDate()]);
    }
  };
  useEffect(() => {
    onChange?.({
      from,
      to,
      pastFrom,
      pastTo,
      durationDay,
      duration:
        durationDay <= 3
          ? dayjs
              .unix(to)
              ?.clone()
              ?.diff?.(dayjs.unix(from)?.clone(), 'hour') || 0
          : durationDay,
      groupType: durationDay <= 3 ? 'hour' : durationDay < 91 ? 'day' : 'week',
    });
  }, [durationDay, from, to, pastFrom, pastTo]);
  // ### RENDERs

  return (
    <Grid className="w-full">
      <Grid.Col span={{ base: 12, sm: 4 }}>
        <NativeSelect
          value={type}
          data={reportDateOptions}
          onChange={onChangeType}
        />
      </Grid.Col>
      <Grid.Col span={{ base: 12, sm: 8 }}>
        <DatePickerInput
          type="range"
          allowSingleDateInRange
          placeholder={t('general.select')}
          value={currentRange}
          onChange={(range) => {
            setCurrentRange?.(range);
            setType(ReportRangeType.CUSTOM);
          }}
          maxDate={dayjs().toDate()}
        />
      </Grid.Col>
    </Grid>
  );
};
export default ReportRangeSelect;
