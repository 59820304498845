import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faArrowsUpDownLeftRight,
  faCaretDown,
  faCaretUp,
  faChartPie,
  faCircleQuestion,
  faHouse,
  faRotateLeft,
  faArrowDownShortWide,
  faSquare,
  faSquareCheck,
  faTrash,
  faArrowsRotate,
  faAdd,
  faUpload,
  faBan,
  faImage,
  faCheckCircle,
  faSave,
  faArchive,
  faClock,
  faCheck,
  faFilter,
  faMagnifyingGlass,
  faAnglesUpDown,
  faChevronDown,
  faHome,
  faUserCircle,
  faSignOutAlt,
  faLanguage,
  faQuestionCircle,
  faEnvelope,
  faPhone,
  faLock,
  faEllipsis,
  faChevronRight,
  faFile,
  faArrowDownToLine,
  faTrashCan,
  faGlobe,
  faNoteSticky,
  faSearch,
  faStar,
  faDownload,
  faPaperclip,
  faAt,
  faStore,
  faEdit,
  faEye,
  faMap,
  faPlus,
  faCircle,
  faRotate,
  faTruck,
  faCircleCheck,
  faChevronLeft,
  faChevronUp,
  faGripDotsVertical,
  faBox,
  faShoppingCart,
  faPercentage,
  faBolt,
  faWarehouse,
  faBullhorn,
  faTag,
  faDollarSign,
  faUsers,
  faNewspaper,
  faUserGraduate,
  faGear,
  faTriangleExclamation,
  faGlasses,
  faHeart,
  faCameraRetro,
  faTruckFast,
  faBell,
  faCartShopping,
  faGift,
  faBookmark,
  faThumbsUp,
  faKey,
  faHandshake,
  faShop,
  faShirt,
  faBagShopping,
  faCartPlus,
  faCreditCard,
  faCashRegister,
  faTrophy,
  faReceipt,
  faGifts,
  faGem,
  faCartArrowDown,
  faBasketShopping,
  faVest,
  faMitten,
  faHatCowboySide,
  faGraduationCap,
  faHatWizard,
  faFire,
  faSun,
  faWater,
  faLeaf,
  faPlug,
  faWind,
  faLightbulb,
  faPlugCircleBolt,
  faGasPump,
  faFireFlameSimple,
  faFireFlameCurved,
  faFan,
  faCertificate,
  faSeedling,
  faShieldCheck,
  faChartMixed,
  faChartSimple,
  faList,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faArrowsUpDownLeftRight,
  faHouse,
  faChartPie,
  faRotateLeft,
  faCircleQuestion,
  faCaretUp,
  faCaretDown,
  faArrowDownShortWide,
  faSquare,
  faSquareCheck,
  faTrash,
  faArrowsRotate,
  faAdd,
  faUpload,
  faBan,
  faImage,
  faCheckCircle,
  faSave,
  faArchive,
  faClock,
  faCheck,
  faFilter,
  faMagnifyingGlass,
  faAnglesUpDown,
  faChevronDown,
  faHome,
  faUserCircle,
  faSignOutAlt,
  faLanguage,
  faQuestionCircle,
  faEnvelope,
  faPhone,
  faLock,
  faEllipsis,
  faChevronRight,
  faFile,
  faArrowDownToLine,
  faTrashCan,
  faGlobe,
  faNoteSticky,
  faSearch,
  faStar,
  faDownload,
  faPaperclip,
  faAt,
  faStore,
  faEdit,
  faEye,
  faMap,
  faPlus,
  faCircleQuestion,
  faCircle,
  faRotate,
  faTruck,
  faCircleCheck,
  faChevronLeft,
  faChevronUp,
  faGripDotsVertical,
  faArrowsUpDownLeftRight,
  faBox,
  faShoppingCart,
  faPercentage,
  faCameraRetro,
  faTruckFast,
  faBell,
  faCartShopping,
  faGift,
  faBookmark,
  faThumbsUp,
  faKey,
  faHandshake,
  faShop,
  faShirt,
  faBagShopping,
  faCartPlus,
  faCreditCard,
  faCashRegister,
  faTrophy,
  faReceipt,
  faGifts,
  faGem,
  faCartArrowDown,
  faBasketShopping,
  faVest,
  faMitten,
  faHatCowboySide,
  faGraduationCap,
  faHatWizard,
  faFire,
  faSun,
  faWater,
  faLeaf,
  faPlug,
  faWind,
  faLightbulb,
  faPlugCircleBolt,
  faGasPump,
  faFireFlameSimple,
  faFireFlameCurved,
  faFan,
  faShieldCheck,
);
export const solidIcons = {
  'arrows-up-down-left-right': faArrowsUpDownLeftRight,
  'caret-down': faCaretDown,
  'caret-up': faCaretUp,
  'chart-pie': faChartPie,
  'circle-question': faCircleQuestion,
  house: faHouse,
  'rotate-left': faRotateLeft,
  'arrow-down-short-wide': faArrowDownShortWide,
  square: faSquare,
  'square-check': faSquareCheck,
  trash: faTrash,
  'arrows-rotate': faArrowsRotate,
  add: faAdd,
  upload: faUpload,
  ban: faBan,
  image: faImage,
  'check-circle': faCheckCircle,
  save: faSave,
  archive: faArchive,
  clock: faClock,
  check: faCheck,
  filter: faFilter,
  'magnifying-glass': faMagnifyingGlass,
  'angles-up-down': faAnglesUpDown,
  'chevron-down': faChevronDown,
  home: faHome,
  'user-circle': faUserCircle,
  'sign-out-alt': faSignOutAlt,
  language: faLanguage,
  'question-circle': faQuestionCircle,
  envelope: faEnvelope,
  phone: faPhone,
  lock: faLock,
  ellipsis: faEllipsis,
  'chevron-right': faChevronRight,
  file: faFile,
  'arrow-down-to-line': faArrowDownToLine,
  'trash-can': faTrashCan,
  globe: faGlobe,
  'note-sticky': faNoteSticky,
  search: faSearch,
  star: faStar,
  download: faDownload,
  paperclip: faPaperclip,
  at: faAt,
  store: faStore,
  edit: faEdit,
  eye: faEye,
  map: faMap,
  plus: faPlus,
  circle: faCircle,
  rotate: faRotate,
  truck: faTruck,
  'circle-check': faCircleCheck,
  'chevron-left': faChevronLeft,
  'chevron-up': faChevronUp,
  'grip-dots-vertical': faGripDotsVertical,
  box: faBox,
  'shopping-cart': faShoppingCart,
  percentage: faPercentage,
  bolt: faBolt,
  warehouse: faWarehouse,
  bullhorn: faBullhorn,
  tag: faTag,
  'dollar-sign': faDollarSign,
  users: faUsers,
  newspaper: faNewspaper,
  'user-graduate': faUserGraduate,
  gear: faGear,
  'triangle-exclamation': faTriangleExclamation,
  heart: faHeart,
  'camera-retro': faCameraRetro,
  'truck-fast': faTruckFast,
  bell: faBell,
  'cart-shopping': faCartShopping,
  gift: faGift,
  bookmark: faBookmark,
  'thumbs-up': faThumbsUp,
  key: faKey,
  handshake: faHandshake,
  shop: faShop,
  shirt: faShirt,
  'bag-shopping': faBagShopping,
  'cart-plus': faCartPlus,
  'credit-card': faCreditCard,
  'cash-register': faCashRegister,
  trophy: faTrophy,
  cartificate: faCertificate,
  receipt: faReceipt,
  gifts: faGifts,
  gem: faGem,
  'cart-arrow-down': faCartArrowDown,
  'basket-shopping': faBasketShopping,
  vest: faVest,
  mitten: faMitten,
  'hat-cowboy-side': faHatCowboySide,
  'graduation-cap': faGraduationCap,
  'hat-wizard': faHatWizard,
  fire: faFire,
  sun: faSun,
  water: faWater,
  leaf: faLeaf,
  seeding: faSeedling,
  plug: faPlug,
  wind: faWind,
  lightbulb: faLightbulb,
  'plug-circle-bolt': faPlugCircleBolt,
  'gas-pump': faGasPump,
  'fire-flame-simple': faFireFlameSimple,
  'fire-flame-curved': faFireFlameCurved,
  fan: faFan,
  glasses: faGlasses,
  'shield-check': faShieldCheck,
  'chart-mixed': faChartMixed,
  'chart-simple': faChartSimple,
  list: faList,
};
