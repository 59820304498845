import { Text, Title } from '@mantine/core';
import classNames from 'classnames';
import { ReactNode } from 'react';
type PropType = {
  title: ReactNode;
  classname?: string;
  subtitle?: ReactNode;
  actionButtons?: ReactNode;
  isSubSection?: boolean;
};
const SectionHeader = ({
  title = '',
  actionButtons,
  subtitle,
  isSubSection = false,
  classname,
}: PropType) => {
  // ### CONSTANTs
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <div
      className={classNames(
        'flex flex-row flex-wrap items-center justify-between gap-2',
        classname,
      )}
    >
      <div>
        <Title order={isSubSection ? 3 : 2}>{title}</Title>
        {subtitle ? (
          typeof subtitle === 'string' ? (
            <Text className="mt-1" size="sm" c={'neutral.7'}>
              {subtitle}
            </Text>
          ) : (
            subtitle
          )
        ) : null}
      </div>
      <div className="flex flex-row flex-wrap items-center gap-2">
        {actionButtons}
      </div>
    </div>
  );
};
export default SectionHeader;
