import { faDownload, faEdit } from '@fortawesome/pro-solid-svg-icons';
import { Anchor, Button, Flex, Image, Text } from '@mantine/core';
import {
  AffiliateItem,
  CommonTableColumn,
  PayoutItem,
  PayoutOrderItem,
} from 'types';
import {
  useAffiliateDetails,
  useDeleteAffiliate,
  useExportPayout,
  usePayoutOrders,
} from 'apis/AffiliateApis';
import IconFA from 'components/common/IconFA';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { CommonTable } from 'components/MantineUI/CommonTable';
import PageLayout from 'components/MantineUI/PageLayout';
import { t } from 'i18next';
import { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { currencyFormatter, dateTimeFormatter } from 'helpers/utils';
import { usePaginationParams } from 'hooks/usePaginationParams';
import { useURLParams } from 'hooks/useURLParams';
import DeleteWarningModal from 'components/common/DeleteWarningModal';
import UpdatePayoutModal from './UpdatePayoutModal';

const PayoutOrders = () => {
  // ### CONSTANTs
  const { params }: any = useURLParams();
  const { affiliate_id, id }: any = useParams();

  const { page, limit, onPaginationChange } = usePaginationParams();
  const [selectPayment, setSelectedPayment] = useState<PayoutItem>();
  const [deleteAffiliate, setDeleteAffiliate] = useState<
    AffiliateItem | undefined
  >();
  const { mutate: onDeleteAffiliate, isPending: deleting } =
    useDeleteAffiliate();
  const { data: payoutDetails } = usePayoutOrders(id);
  const { mutate: exportPayout, isPending: exporting } = useExportPayout();
  const { data: affiliateDetails } = useAffiliateDetails(affiliate_id);

  //   const { data, isLoading } = useAffiliateList({
  //     ...params,
  //     page,
  //     limit,
  //   });
  const orders: PayoutOrderItem[] = useMemo(() => {
    let array: PayoutOrderItem[] = [];
    const results: any[] = payoutDetails?.data?.results || [];
    for (let i = 0; i < results.length; i++) {
      const element = results[i];
      const { _id, created_on, code } = element;
      if (element?.items) {
        for (let j = 0; j < element?.items.length; j++) {
          const item = element?.items[j];
          array.push({
            _id,
            created_on,
            code,
            item,
            rowSpan:
              element?.items?.length > 1
                ? j === 0
                  ? element?.items?.length
                  : 0
                : 1,
          });
        }
      }
    }
    return array;
  }, [payoutDetails]);
  const total = payoutDetails?.data?.total;
  //   account_name
  // affiliate_code
  // commission_rate
  // extra_discount
  // ### FUNCTIONs
  const onExport = () => {
    exportPayout(id, {
      onSuccess: (res: any) => {
        if (res?.status) {
          window.open(res?.message, '_blank');
        }
      },
    });
  };
  // ### RENDERs
  const onDelete = () => {
    onDeleteAffiliate(deleteAffiliate?._id, {
      onSuccess: () => {
        setDeleteAffiliate(undefined);
      },
    });
  };
  const columns: CommonTableColumn[] = [
    {
      accessor: 'name',
      Header: t('orders.order_number'),
      RowSpan: (row: PayoutOrderItem) => row.rowSpan || 0,
      Cell: (row: PayoutOrderItem) => (
        <Anchor
          className="w-auto"
          component={Link}
          to={`/orders/${row?._id}`}
          size="sm"
        >
          {row?.code || '--'}
        </Anchor>
      ),
    },
    {
      accessor: 'order_date',
      Header: t('orders.order_date'),
      RowSpan: (row: PayoutOrderItem) => row.rowSpan || 0,
      Cell: (row: PayoutOrderItem) => dateTimeFormatter(row?.created_on),
    },
    {
      accessor: 'item',
      Header: t('orders.items'),
      Cell: (row: PayoutOrderItem) => {
        const {
          product_name,
          image_urls,
          product_id,
          variant_name,
          variant_id,
        } = row?.item;
        return (
          <Flex gap={'sm'}>
            <Image w={40} h={40} className="rounded" src={image_urls?.[0]} />
            <div>
              <Anchor component={Link} to={'/products/' + product_id} size="xs">
                {product_name}
              </Anchor>
              <Text size="xs" c={'dimmed'}>
                {variant_name}
              </Text>
            </div>
          </Flex>
        );
      },
    },
    {
      accessor: 'sold_items',
      Header: t('dashboard.items_sold'),
      Cell: (row: PayoutOrderItem) => {
        const { quantity } = row?.item;
        return quantity;
      },
    },
    {
      accessor: 'unit_price',
      Header: t('orders.unit_price'),

      Cell: (row: PayoutOrderItem) => {
        const { price } = row?.item;
        return currencyFormatter.format(price);
      },
    },
    {
      accessor: 'item_total',
      Header: t('orders.item_total'),

      Cell: (row: PayoutOrderItem) => {
        const { item_total, discount_price } = row?.item;
        return (
          <div>
            <Text size="sm" c={'red'}>
              {currencyFormatter.format(item_total)}
            </Text>
            <Text size="sm" c={'dimmed'} td="line-through">
              {currencyFormatter.format(discount_price)}
            </Text>
          </div>
        );
      },
    },
    {
      accessor: 'commissions',
      Header: t('dashboard.commissions'),

      Cell: (row: PayoutOrderItem) => {
        const { commission } = row?.item;
        return currencyFormatter.format(commission);
      },
    },
  ];

  const title = id;
  return (
    <PageLayout
      title={title}
      breadCrumds={[
        { title: t('affiliates.affiliates'), href: '/affiliates' },
        {
          title: affiliateDetails?.data?.display_name,
          href: '/affiliates/' + affiliate_id,
        },
        { title: title },
      ]}
    >
      <SectionWrapper>
        <SectionHeader
          title={t('sellers.completed_orders')}
          actionButtons={
            <>
              <Button
                loading={exporting}
                onClick={() => onExport()}
                variant="subtle"
                leftSection={<IconFA icon={faDownload} />}
              >
                {t('general.export')}
              </Button>
              <Button
                disabled
                onClick={() => setSelectedPayment(payoutDetails?.data)}
                leftSection={<IconFA icon={faEdit} />}
              >
                {t('payouts.update_payout')}
              </Button>
            </>
          }
        />
        <SectionBody>
          {/* <Flex gap={'sm'} mb={'md'} wrap={'wrap'} align={'center'}>
            <TableSearchBox searchParams="name" />
            <FilterButton />
            <SortButton />
          </Flex> */}
          <CommonTable
            columns={columns}
            data={orders}
            pagination={{
              page,
              limit,
              onPaginationChange,
              total,
            }}
          />
        </SectionBody>
        <UpdatePayoutModal
          data={selectPayment}
          opened={!!selectPayment}
          onClose={() => setSelectedPayment(undefined)}
        />
        <DeleteWarningModal
          name={deleteAffiliate?.display_name}
          onShow={!!deleteAffiliate}
          onClose={() => setDeleteAffiliate(undefined)}
          onDelete={onDelete}
          isLoading={deleting}
        />
      </SectionWrapper>
    </PageLayout>
  );
};
export default PayoutOrders;
