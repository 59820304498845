import { Card, Flex } from '@mantine/core';
import { ReactNode } from 'react';
type PropType = {
  children?: ReactNode;
};
const FixedBar = (props: PropType) => {
  // ### CONSTANTs
  const { children } = props;
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <div
      style={{
        position: 'fixed',
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 100,
      }}
      className="px-[15px] md:pl-[265px]"
    >
      <div className="border border-gray-200 bg-white px-5 py-4">
        <Flex gap={'sm'} align={'center'} direction={'row-reverse'}>
          {children}
        </Flex>
      </div>
    </div>
  );
};
export default FixedBar;
