import { useQuery } from '@tanstack/react-query';
import { apiCallV2 } from './ApiCallV2';
import { APP_ROLES } from 'constants/constants';

export const useUserProfile = () => {
  const query = useQuery({
    queryKey: ['useUserProfile'],
    queryFn: () => apiCallV2({ url: 'admin/profile' }).then((res) => res?.data),
  });

  const data = query.data;

  return {
    ...query,
    isFulfillSeller: data?.seller?.fulfill_type === 'seller',
    isFulfillNailzy: data?.seller?.fulfill_type === 'nailzy',
    isSeller: data?.type === APP_ROLES.seller,
    isAdmin: data?.type === APP_ROLES.system_user,
    isPartner: data?.type === 'partner',
    affiliate_program_id: data?.affiliate_program_id,
  };
};
