import { SelectItem } from 'components/MantineUI/Selects/SingleSelect';
import { StatusEnum } from './common.type';
import { OrderPaymentStatusEnum } from './order.type';
import { UserInfo } from './user.type';

export type AffiliateItem = {
  commission_rate?: number;
  created_on?: number;
  discount?: number | null;
  display_name?: string;
  code?: string;
  owner?: UserInfo;
  slug?: string;
  affiliate_code?: string;
  socials?: [{ platform: string; url: string }];
  status?: StatusEnum;
  updated_on?: number;
  _id?: string;
  type?: string;
  partner_id?: string;
  partner_name?: string;
  customer_id?: string;
  customer_name?: string;
  customer?: {
    full_name: string;
    _id: string;
  };
  partner?: UserInfo & {
    email: string;
    phone_number: string;
    full_name: string;
    _id: string;
  };
  commission_type?: 'account_level' | 'item_level';
  commission_rate_ids?: string[];
  commission_rates?: SelectItem[];
};

export type PayoutItem = {
  _id?: string;
  name?: string;
  earnings?: number;
  payment_status?: OrderPaymentStatusEnum;
  paid_orders?: number;
  conversion_rate?: number;
  sold_items?: number;
  net_sales?: number;
  total_clicks?: number;
  status?: OrderPaymentStatusEnum;
  reference?: string;
  updated_on?: number;
  created_on?: number;
};

export enum AffiliateTypeEnum {
  CUSTOMER = 'customer',
  PARTNER = 'partner',
}
export type PayoutOrderItem = {
  _id: string;
  code: string;
  rowSpan?: number;
  created_on: number;
  item: {
    _id: string;
    product_name: string;
    product_slug: string;
    product_id: string;
    seller_id: string;
    variant_id: string;
    variant_name: string;
    variant_slug: string;
    status: StatusEnum;
    price: number;
    discount_price: number;
    quantity: number;
    image_urls: string[];
    item_total: number;
    item_discounted: number;
    affiliate_discounted: number;
    commission: number;
  };
};

export type CommissionRateItem = {
  _id?: string;
  name?: string;
  status?: StatusEnum;
  commission_rate?: number;
  updated_on?: number;
  created_on?: number;
  total_products?: number;
};

export enum CommissionRateSortEnum {
  CREATED_ON_DESC = 'created_on_desc',
  CREATED_ON_ASC = 'created_on_asc',
  NAME_DESC = 'name_desc',
  NAME_ASC = 'name_asc',
  COMMISSION_RATE_DESC = 'commission_rate_desc',
  COMMISSION_RATE_ASC = 'commission_rate_asc',
  UPDATED_ON_DESC = 'updated_on_desc',
  UPDATED_ON_ASC = 'updated_on_asc',
}
