import axios from 'axios';
import { logErrors } from 'helpers/logging';
import { toast } from 'react-toastify';
import { apiSetting } from 'config';
import { auth } from 'firebase-app';
import * as Sentry from '@sentry/browser';
import { useNavigate } from 'react-router-dom';

const { LOGOUT_WAIT_TIME, CATCH_ERROR_TIME_OUT } = apiSetting;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_VERSION_1 = 'v1';
const API_VERSION_2 = 'v2';

let lastErrorMessage = null;
let lastErrorTime = null;

function logout() {
  setTimeout(() => {
    document.location.href = '/logout';
  }, LOGOUT_WAIT_TIME);
}

export class NetworkError extends Error {
  constructor(msg, description) {
    super(msg);
    this.name = 'NetworkError';
    this.description = description;
  }
}
export class DuplicateError extends Error {
  constructor(msg) {
    super(msg);
    this.name = 'DuplicateError';
  }
}

const handleError = (error) => {
  // Check if the error message is the same as the last error message
  if (error.message === lastErrorMessage) {
    // Prevent toast out the same errors many time
    if (Date.now() - lastErrorTime < CATCH_ERROR_TIME_OUT) {
      return;
    }
  }

  if (error.code !== 'ERR_CANCELED' && error.code !== 'ECONNABORTED') {
    logErrors(error);
  }

  if (error.response && error.response.status !== 401) {
    console.error('HTTP error:', error.response.status);
    console.error('Response data:', error.response.data);
  }

  if (error.isAxiosError) {
    console.error('Network error:', error.message);
    console.error('Error code:', error.code);
  }

  if (error?.response?.status === 401) {
    // toast.info('Session timeout. Please login again!');
    // logout();
  }

  // If the error is new, set the last error message and time
  lastErrorMessage = error.message;
  lastErrorTime = Date.now();
  if (error?.response?.status !== 401) {
    error?.resposne?.data
      ? toast.error(`${error.response?.data?.message}`)
      : toast.error(`${error.message}`);
  }

  return error;
};

/**
 * API function for request with 'application/json'
 * @param {string} url api request
 * @param {object} data request body
 * @param {object} newHeaders request header
 * @param {func} signal signal for cancelling request
 * @returns response data from server
 */
export const apiCall = async (url, data, newHeaders = {}, signal = null) => {
  const userLocalInfo = JSON.parse(localStorage.getItem('user'));
  try {
    const requestUrl = `${API_BASE_URL}/${API_VERSION_1}/${url}`;
    let headers = new Headers();

    headers = {
      authorization: `Bearer ${userLocalInfo?.data?.token}`,
      'Content-Type': 'application/json',
      'x-time_zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...newHeaders,
    };

    const source = axios.CancelToken.source();

    let res = await axios.post(requestUrl, data, {
      headers,
      signal,
      cancelToken: source.token,
    });

    return res?.data;
  } catch (error) {
    // handleError(error);
  }
};

/**
 * API function for request with 'application/json'
 * @param {string} url api request
 * @param {object} data request body if have
 * @param {object} headerOptions request header
 * @param {func} signal signal for cancelling request
 * @returns {object}
 *  {
 *    data?: {
 *      results[],
 *      total
 *    },
 *    status,
 *    message
 *  }
 */

/**
 * API function for request with 'multipart/form-data'
 * @param {string} url api request
 * @param {object} data request body
 * @param {object} newHeaders request header
 * @param {func} signal signal for cancelling request
 * @returns response data from server
 */
export const apiCallFile = async (
  url,
  data,
  newHeaders = {},
  signal = null,
) => {
  // get user logins
  const userLocalInfo = JSON.parse(localStorage.getItem('user'));

  try {
    const requestUrl = `${API_BASE_URL}/${API_VERSION_1}/${url}`;
    let headers = new Headers();
    headers = {
      authorization: `Bearer ${userLocalInfo?.data?.token}`,
      'Content-Type': 'multipart/form-data',
      'x-time_zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...newHeaders,
    };

    const source = axios.CancelToken.source();

    let res = await axios.post(requestUrl, data, {
      headers,
      signal,
      cancelToken: source.token,
    });

    return res?.data;
  } catch (error) {
    handleError(error);
  }
};
