import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons';
import { Flex, InputError, Loader, Text, Title } from '@mantine/core';
import IconFA from 'components/common/IconFA';
import { currencyFormatter, getColor } from 'helpers/utils';
import { t } from 'i18next';

import { Form } from 'react-bootstrap';

type Props = {
  shipping?: any;
  selectedShipping?: any;
  setSelectedShipping?: any;
  onRefetchShipping?: any;
  gettingShipping?: any;
};

const ShippingMethodCard = ({
  shipping,
  selectedShipping,
  setSelectedShipping,
  onRefetchShipping,
  gettingShipping,
}: Props) => {
  const shippingErrorMessages: any = {
    default_package_invalid: t('shipping.default_package_invalid'),
    not_have_default_package: t('shipping.not_have_default_package'),
    not_supported_your_address: t('shipping.not_supported_your_address'),
  };

  const onSelectItem = (x: { id: any }) => {
    if (selectedShipping?.id !== x?.id) {
      setSelectedShipping?.(x);
    }
  };
  return (
    <div className="bg-100 p-3 rounded-2 mt-3">
      <Flex className="mb-2" align={'center'} gap={'xs'}>
        <Title order={4}>{t('orders.shipping_method')}</Title>
        <IconFA
          onClick={() => onRefetchShipping()}
          icon={faArrowsRotate}
          color={getColor('gray-700')}
        />
      </Flex>
      {!gettingShipping ? (
        !shipping ? (
          <Text size="sm">{t('orders.please_fetch_data')}</Text>
        ) : shipping?.shipping_items?.length ? (
          shipping?.shipping_items?.map((x: any) => {
            const selected =
              selectedShipping?.id && selectedShipping?.id === x?.id;
            return (
              <Flex
                gap={'xs'}
                justify={'space-between'}
                align={'center'}
                key={x?.id}
                onClick={() => onSelectItem(x)}
                className="w-100 mt-2 clickable "
              >
                <Flex gap={'xs'} align={'center'}>
                  <Form.Check
                    key={x?.id}
                    id={x?.id}
                    type="radio"
                    onChange={(e) => {
                      onSelectItem(x);
                    }}
                    // checked={false}
                    checked={selected ? true : false}
                  />
                  <Text size="sm" fw={'semibold'}>
                    {x?.servicelevel_name} {x?.estimated_days || 0}{' '}
                    {t('orders.business_days')}
                  </Text>
                </Flex>
                <div
                  className={`fs-0 fw-medium ${selected ? 'text-primary' : ''}`}
                >
                  {currencyFormatter.format(x?.amount)}
                </div>
              </Flex>
            );
          })
        ) : (
          <InputError>
            {shippingErrorMessages[shipping?.reason_type]}
          </InputError>
        )
      ) : (
        <div className="d-flex flex-center flex-column">
          <Loader className="ms-1" size={15} />
          <Text className="mt-2 fs--1">{t('orders.fetching_shipment')}</Text>
        </div>
      )}
    </div>
  );
};
export default ShippingMethodCard;
