import { StatusEnum } from 'types';
import StatusBadge from 'components/MantineUI/StatusBadge';
import { Badge, ComboboxItem, Flex, Text } from '@mantine/core';

type Props = ComboboxItem & {
  label: string;
  value: string;
  status: StatusEnum;
  isSelected?: boolean;
  badge?: string;
};

export const RenderOptionWithStatus = (props: Props) => {
  const { label, status, isSelected, badge } = props;
  return (
    <Flex gap={'xs'} align={'center'}>
      <Text size="sm" c={isSelected ? 'primary' : ''}>
        {label}
      </Text>
      {status && status !== StatusEnum.ACTIVE && (
        <StatusBadge status={status} />
      )}
      {badge && (
        <Badge color="primary" variant="light" size="sm">
          {badge}
        </Badge>
      )}
    </Flex>
  );
};

export default RenderOptionWithStatus;
