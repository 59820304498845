import {
  faArrowsUpDownLeftRight,
  faPlus,
} from '@fortawesome/pro-solid-svg-icons';
import { Anchor, Button, Flex, Group, Text } from '@mantine/core';
import {
  BANNER_TARGET_INFO,
  BannerFilterFormType,
  BannerTargetType,
  CommonTableColumn,
} from 'types';
import IconFA from 'components/common/IconFA';
import NailzyImage from 'components/common/NailzyImage';
import FilterButton from 'components/MantineUI/Buttons/FilterButton';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { CommonTable, TableSearchBox } from 'components/MantineUI/CommonTable';
import SortButton from 'components/MantineUI/CommonTable/SortButton';
import PageLayout from 'components/MantineUI/PageLayout';
import {
  dateFormatter,
  dateTimeFormatter,
  getItemLink,
  textTruncate,
} from 'helpers/utils';
import { useAdminPromotions } from 'hooks/marketplace/promotion';
import { usePaginationParams } from 'hooks/usePaginationParams';
import { useURLParams } from 'hooks/useURLParams';
import { t } from 'i18next';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import StatusBadge from 'components/MantineUI/StatusBadge';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';
import { useAdminBannerList, useDeleteAdminBanner } from 'apis/BannerApis';
import SkeletonWrapper from 'components/MantineUI/Skeleton/SkeletonWrapper';
import ListPageSkeleton from 'components/MantineUI/Skeleton/ListPageSkeleton';
import DeleteWarningModal from 'components/common/DeleteWarningModal';
import ArrangeModal from './components/ArrangeModal';
import { env } from 'process';
import CommonDrawer from 'components/MantineUI/CommonDrawer';
import { useForm } from '@mantine/form';
import BannerFilterForm from './components/BannerFilterForm';
import dayjs from 'dayjs';
import DragDropList from 'pages/products/add-product/components/DragDropList';

const HomeBanners = () => {
  // ### CONSTANTs
  const { params, handleParamsChange }: any = useURLParams();
  const { page, limit, onPaginationChange } = usePaginationParams();
  const [deleteBanner, setDeleteBanner] = useState<any>();
  const [opened, setOpened] = useState(false);
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const { params: paramsUrl } = useURLParams() as any;
  const {
    data,
    refetch: refetchProductList,
    isLoading,
    isFetching,
  } = useAdminBannerList({
    ...params,
    page,
    limit,
  });
  const form = useForm({
    initialValues: {} as BannerFilterFormType,
  });
  const { onSubmit, getValues, reset } = form;
  const navigate = useNavigate();
  const { mutate: onDeteleBanner, isPending: deleting } =
    useDeleteAdminBanner();
  const total = data?.data?.total || 0;
  const banners = data?.data?.results || [];
  useEffect(() => {
    onApplyFilter(paramsUrl);
    form.setValues({
      status: paramsUrl?.status || '',
      type: paramsUrl?.type || '',
      start_date: paramsUrl?.start_date
        ? dayjs.unix(paramsUrl?.start_date)
        : undefined,
      end_date: paramsUrl?.end_date
        ? dayjs.unix(paramsUrl?.end_date)
        : undefined,
    });
  }, []);
  // ### FUNCTIONs
  const onClickReset = () => {
    onApplyFilter?.({
      page: 1,
      status: undefined,
      type: undefined,
      start_date: undefined,
      end_date: undefined,
    });
    reset();
  };
  const onApplyFilter = (values: any) => {
    if (Object.keys(values)?.length) {
      handleParamsChange(values);
    }
    setShowFilterDrawer(false);
  };

  const onClickApply = () => {
    const values = getValues();
    onApplyFilter?.({
      page: 1,
      status: values?.status || undefined,
      type: values?.type || undefined,
      start_date: values.start_date
        ? dayjs(values.start_date).startOf('day').unix()
        : undefined,
      end_date: values.end_date
        ? dayjs(values.end_date).endOf('day').unix()
        : undefined,
    });
  };
  const onDelete = () => {
    onDeteleBanner(deleteBanner?._id, {
      onSuccess: () => {
        setDeleteBanner(undefined);
      },
      onError: () => {},
    });
  };
  const onClickTarget = (target: any) => {
    let link = '';
    if (target?.type === BannerTargetType.PRODUCT) {
      link = process.env.REACT_APP_WEBSITE_URL + '/products/' + target?.slug;
    }
    if (target?.type === BannerTargetType.PROMOTION) {
      link = process.env.REACT_APP_WEBSITE_URL + '/promotions/' + target?.slug;
    }
    if (target?.type === BannerTargetType.BRAND) {
      link =
        process.env.REACT_APP_WEBSITE_URL +
        '/products?brand_ids[]=' +
        target?.brand_id;
    }
    if (target?.type === BannerTargetType.SELLER) {
      link =
        process.env.REACT_APP_WEBSITE_URL +
        '/sellers/' +
        target?.slug +
        '/store';
    }
    if (target?.type === BannerTargetType.CATEGORY) {
      link =
        process.env.REACT_APP_WEBSITE_URL +
        '/products?category_ids[]=' +
        target?.category_id;
    }
    if (link) {
      window.open(link);
    }
  };
  // ### RENDERs
  const columns: CommonTableColumn[] = useMemo(
    () => [
      {
        accessor: 'image',
        Header: t('promotions.banner'),
        Cell: (row: any) => {
          const { name, _id, image_url } = row;
          return (
            <Flex gap={'sm'} align={'center'}>
              <NailzyImage
                style={{
                  height: 50,
                  width: 130,
                }}
                className={'rounded-1'}
                image_url={image_url}
              />
              <Anchor
                size="sm"
                component={Link}
                to={`${`/home-banners/${_id}`}`}
              >
                {textTruncate(name, 50)}
              </Anchor>
            </Flex>
          );
        },
      },
      {
        accessor: 'target',
        Header: t('form.coupon.target'),
        Cell: (row: any) => {
          const { type, slug, name, store_name } = row?.target || {};
          return row?.target ? (
            <div>
              <Text truncate="end" size="sm">
                {t(
                  BANNER_TARGET_INFO[
                    (type as BannerTargetType) || BannerTargetType.NO_TARGET
                  ]?.type_label,
                )}
              </Text>
              <Anchor size="sm" onClick={() => onClickTarget(row?.target)}>
                {type !== BannerTargetType.NO_TARGET ? (
                  <Text truncate="end">
                    {textTruncate(name || store_name, 25)}
                  </Text>
                ) : null}
              </Anchor>
            </div>
          ) : (
            '--'
          );
        },
      },
      {
        accessor: 'apply_time',
        Header: t('general.validity_period'),
        Cell: (row) => {
          const { start_date, end_date } = row;
          return (
            <>
              <p>
                {t('general.start_date')}:{' '}
                {dateTimeFormatter(start_date) || '--'}
              </p>
              <p>
                {t('general.end_date')}: {dateTimeFormatter(end_date) || '--'}
              </p>
            </>
          );
        },
      },
      {
        accessor: 'created_on',
        Header: t('general.created_date'),
        Cell: (row) => {
          const { created_on } = row;
          return <>{dateFormatter(created_on) || '--'}</>;
        },
      },
      {
        accessor: 'status',
        Header: t('promotions.status'),
        Cell: (row) => {
          const { status } = row;
          return <StatusBadge fullWidth status={status} />;
        },
      },
      {
        accessor: 'action',
        Cell: (row) => {
          return (
            <DropdownButton
              //   disabled={disabled}
              button={
                <Button variant="subtle" color="gray" size="xs">
                  <IconFA icon={'ellipsis'} />
                </Button>
              }
              dropdownData={[
                {
                  key: 'edit',
                  label: t('general.edit'),
                  to: `/home-banners/${row?._id}`,
                },
                { isDivider: true },
                {
                  key: 'cancel_order',
                  label: t('general.delete'),
                  color: 'red',
                  onClick: () => {
                    setDeleteBanner(row);
                  },
                },
              ]}
            />
          );
        },
      },
    ],
    [banners],
  );
  return (
    <SkeletonWrapper show={isLoading} skeleton={<ListPageSkeleton />}>
      <PageLayout
        title={t('navigation.banners')}
        breadCrumds={[{ title: t('navigation.banners') }]}
      >
        <SectionWrapper>
          <SectionHeader
            title={t('navigation.banners')}
            actionButtons={
              <>
                <Button
                  leftSection={<IconFA icon={faArrowsUpDownLeftRight} />}
                  variant="subtle"
                  onClick={() => setOpened(true)}
                >
                  {t('general.arrange')}
                </Button>
                <Button
                  onClick={() => navigate('/home-banners/add')}
                  leftSection={<IconFA icon={faPlus} />}
                >
                  {t('promotions.add_banner')}
                </Button>
              </>
            }
          />

          <SectionBody>
            <form>
              <Flex gap={'sm'} mb={'md'} wrap={'wrap'} align={'center'}>
                <TableSearchBox searchParams="name" />
                <FilterButton onClick={() => setShowFilterDrawer(true)} />
                <CommonDrawer
                  title={t('filter.filters')}
                  opened={showFilterDrawer}
                  onClose={() => setShowFilterDrawer(false)}
                >
                  <BannerFilterForm form={form} />
                  <Group justify="flex-end" mt="md">
                    <Button onClick={onClickReset} variant="default">
                      {t('general.reset')}
                    </Button>
                    <Button onClick={onSubmit(onClickApply) as any}>
                      {t('general.apply')}
                    </Button>
                  </Group>
                </CommonDrawer>
                <SortButton />
              </Flex>
            </form>
            <CommonTable
              loading={isFetching}
              columns={columns}
              data={banners}
              pagination={{ page, limit, total, onPaginationChange }}
            />
          </SectionBody>
        </SectionWrapper>
        <DeleteWarningModal
          onShow={!!deleteBanner}
          name={deleteBanner?.name}
          onDelete={onDelete}
          onClose={() => setDeleteBanner(undefined)}
          isLoading={deleting}
        />
        <ArrangeModal
          data={banners}
          opened={opened}
          onClose={() => setOpened(false)}
        />
      </PageLayout>
    </SkeletonWrapper>
  );
};
export default HomeBanners;
