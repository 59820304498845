import {
  Combobox,
  InputError,
  Loader,
  MultiSelect as MTMultiSelect,
  Pill,
  PillsInput,
  Text,
  TextInput,
  useCombobox,
} from '@mantine/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { apiCallV2 } from 'apis/ApiCallV2';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { t } from 'i18next';
import IconFA from 'components/common/IconFA';
import { faAnglesUpDown } from '@fortawesome/pro-light-svg-icons';
import * as lodash from 'lodash';
import { SingleSelectProps } from './SingleSelect';
type SelectItem = {
  label: string;
  value: string;
  disabled?: boolean;
};
const MultiSelect = (
  props: SingleSelectProps & {
    selected: SelectItem[] | null;
    onChange: (items: SelectItem[]) => void;
  },
) => {
  // ### CONSTANTs
  const controller = new AbortController();
  const {
    route = '',
    className = '',
    selected,
    label,
    placeholder,
    onChange,
    required,
    errorMessage,
    renderOption,
    limit = 25,
    params = {},
    searchField,
    disabled,
    excludes,
    optionTransformer = (data) =>
      data?.results?.map((x: any) => ({
        label: x?.name,
        value: x?._id,
      })),
  } = props;
  // const [page, setPage] = useState(1);
  const [data, setData] = useState<{ label: string; value: string }[]>([]);
  const [search, setSearch] = useState<string>('');
  const [searchParams, setSearchParams] = useState<string>('');
  const LIMIT = limit;
  const debounceSearch = useCallback(
    lodash.debounce((search) => setSearchParams(search), 500),
    [],
  );

  const queryParams = useMemo(() => {
    let obj = { limit: LIMIT, ...params };

    if (searchField && searchParams) {
      obj = {
        ...obj,
        [searchField]: searchParams,
      };
    }
    return obj;
  }, [searchParams]);

  const {
    data: res,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
  } = useInfiniteQuery({
    queryKey: [route, 'infinity', queryParams],
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) =>
      apiCallV2({
        url: route,
        params: { ...queryParams, page: pageParam },
        signal: controller.signal,
      } as any),
    getNextPageParam: (lastPage: any, pages) => {
      const hasMore = lastPage?.data?.total > pages.length * LIMIT;
      const nextPage = pages.length + 1;
      return hasMore ? nextPage : undefined;
    },
    enabled: !!route && !disabled,
  });

  useEffect(() => {
    debounceSearch(search);
  }, [search]);

  useEffect(() => {
    if (!isFetching) {
      let newData: any[] = [];
      res?.pages?.map((page: any) =>
        optionTransformer?.(page?.data)?.map?.((item: any) =>
          newData.push(item),
        ),
      );
      setData(newData);
    }
  }, [res, isFetching]);
  // ### FUNCTIONs
  // ### RENDERs
  const generalProps = {
    key: route,
    error: !!errorMessage,
    required: required,
    label: label,
    rightSection: isLoading ? (
      <Loader size={12} />
    ) : (
      <Text size="10">
        <IconFA color={'black'} icon={faAnglesUpDown} />
      </Text>
    ),
  };
  const handleDropdownScroll = (event: any) => {
    const target = event.currentTarget;

    if (target.scrollHeight - target.scrollTop <= target.clientHeight + 1) {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  };

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });
  const options = useMemo(
    () => data?.filter((x) => !selected?.find((y) => y?.value === x?.value)),
    [data, selected, hasNextPage],
  );
  const comboboxOptions = useMemo(
    () =>
      options
        ?.filter(
          (x: { value: string }) =>
            !excludes?.length || !excludes?.includes(x?.value),
        )
        ?.map((item: any, index) => {
          return (
            <>
              {item?.group ? (
                <Combobox.Group label={item?.group}>
                  {item?.items
                    ?.filter(
                      (x: any) => !selected?.find((y) => y?.value === x?.value),
                    )
                    ?.map((child: SelectItem) => {
                      return (
                        <Combobox.Option
                          value={child?.value}
                          key={child?.value}
                          onClick={() => onClickOption(child)}
                        >
                          {renderOption ? renderOption(child) : child?.label}
                        </Combobox.Option>
                      );
                    })}
                </Combobox.Group>
              ) : (
                <Combobox.Option
                  onClick={() => onClickOption(item)}
                  value={item?.value}
                  key={item?.value}
                >
                  {renderOption ? renderOption(item) : item?.label}
                </Combobox.Option>
              )}
              {hasNextPage && index === options?.length - 1 ? (
                <Combobox.Option disabled value="loading" key={'loading'}>
                  <div className="flex w-full items-center justify-center gap-2">
                    <Loader size={12} />
                    {t('general.loading')}
                  </div>
                </Combobox.Option>
              ) : null}
            </>
          );
        }),
    [options],
  );
  const onClickOption = (option: SelectItem) => {
    if (option?.disabled) return;
    onChange?.([...(selected || []), option]);
  };
  const onChangeClear = (id: string) => {
    let array = [...(selected || [])]?.filter((x) => id !== x?.value);
    onChange?.(array);
  };

  return (
    <div className={className}>
      <Combobox position="bottom" store={combobox}>
        <Combobox.Target>
          <div>
            <PillsInput {...generalProps} required disabled={isLoading}>
              <Pill.Group>
                {(selected || [])?.map((x) => {
                  return (
                    <Pill
                      withRemoveButton
                      onRemove={() => onChangeClear(x?.value)}
                      key={x.value}
                    >
                      {x.label}
                    </Pill>
                  );
                })}
                <PillsInput.Field
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onFocus={() => combobox.openDropdown()}
                  placeholder={placeholder}
                />
              </Pill.Group>
            </PillsInput>
          </div>
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options
            onScroll={handleDropdownScroll}
            mah={200}
            style={{ overflowY: 'auto' }}
          >
            {isLoading ? (
              <Combobox.Empty>
                <Loader size={12} /> {t('general.loading')}
              </Combobox.Empty>
            ) : comboboxOptions?.length ? (
              comboboxOptions
            ) : (
              <Combobox.Empty>{t('general.no_data')}</Combobox.Empty>
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
      {errorMessage ? (
        <InputError className="mt-1">{errorMessage}</InputError>
      ) : null}
    </div>
  );
};
export default MultiSelect;
