import { Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { dateTimeFormatter, relativeTimestamp } from 'helpers/utils';

type Props = {
  created: any;
  updated?: any;
};

const JoinedUpdatedDate = ({ created, updated }: Props) => {
  const { t } = useTranslation();

  return (
    <Text size="xs" c={'gray.7'}>
      {t('general.joined_on')} {relativeTimestamp(created)}{' '}
      {updated && (
        <>
          {' '}
          &#x2022; {t('general.last_update_on')} {dateTimeFormatter(updated)}
        </>
      )}
    </Text>
  );
};

export default JoinedUpdatedDate;
