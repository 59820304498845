import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { dataFetchingQueryConfig } from 'config';
import { textTruncate } from 'helpers/utils';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { apiCall, apiCallFile } from './ApiCall';
import { apiCallV2 } from './ApiCallV2';

const config = dataFetchingQueryConfig;

/**
 * Custom hook to get products using `useQuery` from `@tanstack/react-query`
 * @param {Object} data - Optional data object to send with the request
 * @param {string} key - Key name to store in global storage. Should be consist
 *  for each API call.
 * @return {Object} The `useQuery` object with data, status, error, etc.
 */
export const useGetProducts = (data = {}, key) => {
  const controller = new AbortController();
  // Get global login info from useQuery
  const userLocalInfo = JSON.parse(localStorage.getItem('user'));
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData([
    'getLogin',
    textTruncate(userLocalInfo?.data?.token, 10),
  ]);

  return useQuery({
    queryKey: ['admin/products', data, user?.data?._id, key],
    queryFn: () =>
      apiCallV2({
        url: 'admin/products',
        params: data,
      }),
    ...config,
  });
};

/**
 * Custom hook to get products using `useQuery` from `@tanstack/react-query` with filter
 * ! Cannot use for Sub Cats
 * @param {Object} data - Optional data object to send with the request
 * seller_id, brand_id
 * @param {string} key - Key name to store in global storage. Should be consist
 *  for each API call.
 * @return {Object} The `useQuery` object with data, status, error, etc.
 */
export const useGetProductsWithFilter = (data = {}, key, isOn = true) => {
  const controller = new AbortController();
  const url = 'products/get_all_products_for_filter';
  const headers = {};
  const body = {
    ...data,
  };

  return useQuery([key], () => apiCall(url, body, headers, controller.signal), {
    ...config,
    enabled: isOn,
  });
};

/**
 * Custom hook to get product details using `useQuery` from `@tanstack/react-query`
 * @param {Object} data - Optional data object to send with the request
 * @return {Object} The `useQuery` object with data, status, error, etc.
 */
export const useGetProductDetails = (data = {}, key) => {
  const controller = new AbortController();
  const url = 'products/get_product_detail';
  const headers = {};
  const body = {
    ...data,
  };

  return useQuery(
    [key],
    () => apiCall(url, body, headers, controller.signal),
    config,
  );
};

/**
 * Custom hook to get all colors of a product using `useQuery` from `@tanstack/react-query`
 * @param {Object} data - Optional data object to send with the request
 * @return {Object} The `useQuery` object with data, status, error, etc.
 */
export const useGetVariantForProduct = (data = {}, key) => {
  const controller = new AbortController();
  const url = 'products/get_all_colors';
  const headers = {};
  const body = {
    ...data,
  };

  return useQuery(
    [key],
    () => apiCall(url, body, headers, controller.signal),
    config,
  );
};

/**
 * Custom hook to add product details using `useMutation` from `@tanstack/react-query`
 * @param {Object} data - Object to send with the request includes
 *  prod_name, prod_desc, brand_name (string id), seller_id, category_id,
 *  subcategory_id, colors ( with prod_media return from upload media )
 * @return {Object} The `useQuery` object with data, status, error, etc.
 */
export const useAddProduct = () => {
  // Get global login info from useQuery
  const userLocalInfo = JSON.parse(localStorage.getItem('user'));
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData([
    'getLogin',
    textTruncate(userLocalInfo?.data?.token, 10),
  ]);

  const mutation = useMutation((data = {}) => {
    const controller = new AbortController();
    const adminURL = 'products/add_new_product_new';
    const sellerURL = 'products/add_new_product_seller';
    const headers = {};

    const body = {
      ...data,
    };

    return apiCall(
      user?.data.type ? adminURL : sellerURL,
      body,
      headers,
      controller.signal,
    );
  });

  return mutation;
};

/**
 * Custom hook to add product images using `useMutation` from `@tanstack/react-query`
 * products_media (required)
 * @param {Object} data - Object with image files.
 * @return {Object} The `useQuery` object with data, status, error, etc.
 */
export const useUploadProductImages = () => {
  const mutation = useMutation((data) => {
    const controller = new AbortController();
    const url = 'products/upload_products_media';
    const headers = {};

    return apiCallFile(url, data, headers, controller.signal);
  });

  return mutation;
};

/**
 * Custom hook to update a product  using `useMutation` from `@tanstack/react-query`
 * @param {Object} data - Product infos.
 * @return {Object} The `useQuery` object with data, status, error, etc.
 */
export const useUpdateProduct = () => {
  // Get global login info from useQuery
  const userLocalInfo = JSON.parse(localStorage.getItem('user'));
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData([
    'getLogin',
    textTruncate(userLocalInfo?.data?.token, 10),
  ]);

  const mutation = useMutation((data = {}) => {
    const controller = new AbortController();

    const adminURL = 'products/update_product';
    const sellerURL = 'products/update_product_seller';

    const headers = {};
    const body = {
      ...data,
    };

    return apiCall(
      user?.data.type ? adminURL : sellerURL,
      body,
      headers,
      controller.signal,
    );
  });

  return mutation;
};

/**
 * Export all products to Excel
 */
export async function exportProducts(data = {}) {
  const controller = new AbortController();
  const url = 'products/export_excel';
  const body = {
    ...data,
  };
  const headers = {};

  return await apiCall(url, body, headers, controller.signal);
}

/**
 * Import products Excel using `useMutation` from `@tanstack/react-query`
 * products_excel (required)
 * @param {Object} data - Object with XLSX file.
 * @return {Object} The `useQuery` object with data, status, error, etc.
 */
export const useImportProducts = () => {
  const mutation = useMutation((data) => {
    const controller = new AbortController();
    const url = 'products/add_products_excel';
    const headers = {};

    return apiCallFile(url, data, headers, controller.signal);
  });

  return mutation;
};

/**
 * Upload .ZIP file containing product images using `useMutation` from `@tanstack/react-query`
 * product_zip (required)
 * @param {Object} data - Object with ZIP file.
 * @return {Object} The `useQuery` object with data, status, error, etc.
 */
export const useUploadZipImgProduct = () => {
  const mutation = useMutation((data) => {
    const controller = new AbortController();
    const url = 'products/upload_products_media_zip';
    const headers = {};

    return apiCallFile(url, data, headers, controller.signal);
  });

  return mutation;
};

export const useGetProductReviews = (params = {}, key = []) => {
  const url = 'admin/product-reviews';
  return useQuery({
    queryKey: ['useGetProductReviews', key, params],
    queryFn: () =>
      apiCallV2({
        url,
        params,
      }),
  });
};
export const useDeleteReview = () =>
  useMutation({
    mutationFn: (id) => {
      const url = 'admin/product-reviews/' + id;
      return apiCallV2({
        url: url,
        method: 'delete',
        data: {
          id,
        },
      });
    },
  });

export const useDeleteVariant = () =>
  useMutation({
    mutationFn: (id) => {
      const url = 'admin/products/variants/' + id;
      return apiCallV2({
        url: url,
        method: 'delete',
        data: {
          id,
        },
      });
    },
  });

export const useCheckSlug = () => {
  const controller = new AbortController();

  const mutation = useMutation({
    mutationFn: ({ slug, model }) => {
      const url = 'admin/check-exist-slugs';
      return apiCallV2({
        url: url,
        method: 'post',
        data: {
          slug,
          model,
        },
      });
    },
  });

  return mutation;
};

export const useCreateStockItem = (productId) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (data = {}) => {
      const url = 'admin/stock-items';
      return apiCallV2({
        url,
        method: 'POST',
        data,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['get-stock-items'] });
      queryClient.invalidateQueries({
        queryKey: ['useProductDetail', productId],
      });
      toast.success(t('general.update_success'));
    },
    onError: (error) => {
      toast.error(error?.message || t('general.update_fail'));
    },
  });

  return mutation;
};

export const useGetStockItems = (variantId, enabled) => {
  const url = `admin/stock-items?variant_id=${variantId}`;
  return useQuery({
    queryKey: ['get-stock-items', variantId],
    queryFn: () =>
      apiCallV2({
        url,
      }),
    enabled,
  });
};

export const useExportProductPricingTemplate = (params = {}, key) => {
  const url = 'admin/products/exports';
  return useQuery({
    queryKey: ['useExportProductPricingTemplate', params],
    enabled: false,
    queryFn: () =>
      apiCallV2({
        url,
        params,
      }),
  });
};
export const useExportProductInventoryTemplate = (params = {}, key) => {
  const url = '/admin/stock-items/exports';
  return useQuery({
    queryKey: ['useExportProductInventoryTemplate', params],
    enabled: false,
    queryFn: () =>
      apiCallV2({
        url,
        params,
      }),
  });
};

export const useImportProductImages = () => {
  const mutation = useMutation({
    mutationFn: (data) => {
      const { isPricing, media_id } = data;
      const url = isPricing
        ? 'admin/products/imports'
        : 'admin/stock-items/imports';

      return apiCallV2({
        url,
        method: 'POST',
        data: { media_id },
      });
    },
  });

  return mutation;
};
export const useSubmitDraftProduct = () => {
  const mutation = useMutation({
    mutationFn: (product_ids) => {
      const url = 'admin/products/submit';

      return apiCallV2({
        url,
        method: 'POST',
        data: { product_id: product_ids },
      });
    },
    onSuccess: () => {
      toast.success(t('products.product_submit_success'));
    },
    onError: (error) => {
      toast.error(error?.message || t('products.product_submit_failed'));
    },
  });

  return mutation;
};
export const useApproveProduct = () => {
  const mutation = useMutation({
    mutationFn: ({ product_id, is_approved }) => {
      const url = 'admin/products/approval';

      return apiCallV2({
        url,
        method: 'POST',
        data: { product_id, is_approved },
      });
    },
    onSuccess: () => {
      toast.success(t('general.update_success'));
    },
    onError: (error) => {
      toast.error(error?.message || t('general.update_fail'));
    },
  });

  return mutation;
};
