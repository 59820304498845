import { Grid, Modal, TextInput } from '@mantine/core';
import { t } from 'i18next';
import IconFA from 'components/common/IconFA';
import { useEffect, useState } from 'react';
import CategorySelect from 'components/MantineUI/Selects/CategorySelect';
import { useURLParams } from 'hooks/useURLParams';
import BrandSelect from 'components/MantineUI/Selects/BrandSelect';
import SellerStoreSelect from 'components/MantineUI/Selects/SellerStoreSelect';
import { useAsyncDebounce } from 'react-table';
import SelectProductList from './SelectProductList';

type PropType = {
  opened: boolean;
  onClose: () => void;
  selectedProducts: any;
  onChange: (products: any) => void;
};

const SelectProductModal = (props: PropType) => {
  // ### CONSTANTs
  const {
    opened = false,
    onClose: onCloseProps,
    selectedProducts: selectedProductsProps,
    onChange,
  } = props;
  const [search, setSearch] = useState('');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const { params: urlParams, handleParamsChange }: any = useURLParams();
  // ### FUNCTIONs
  useEffect(() => {
    if (opened) {
      setSelectedProducts(selectedProductsProps);
    } else {
      setSelectedProducts([]);
    }
  }, [opened]);
  const onClose = () => {
    handleParamsChange({
      category_id: undefined,
      brand_id: undefined,
      seller_id: undefined,
      category_name: undefined,
      brand_name: undefined,
      seller_name: undefined,
    });
    onCloseProps?.();
  };

  useEffect(() => {
    debounceSearch(search);
  }, [search]);
  const debounceSearch = useAsyncDebounce((value) => {
    handleParamsChange({ name: search || undefined, page: 1 });
  }, 500);
  // ### RENDERs

  return (
    <Modal
      size={'1200px'}
      onClose={onClose}
      opened={opened}
      title={t('promotions.select_products')}
    >
      <Grid>
        <Grid.Col span={{ base: 6, md: 3 }}>
          <TextInput
            value={search}
            placeholder={t('general.search')}
            onChange={(e) => {
              setSearch(e?.target?.value);
            }}
            label={t('promotions.find_by_name')}
            leftSection={<IconFA icon={'search'} />}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 6, md: 3 }}>
          <CategorySelect
            value={{
              value: urlParams?.category_id,
              label: urlParams?.category_name,
            }}
            label={t('promotions.find_by_cate')}
            onChange={(cate) => {
              handleParamsChange({
                category_id: cate?.value,
                category_name: cate?.label,
                page: 1,
              });
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 6, md: 3 }}>
          <BrandSelect
            value={{ value: urlParams?.brand_id, label: urlParams?.brand_name }}
            label={t('promotions.find_by_brand')}
            onChange={(brand) => {
              handleParamsChange({
                brand_id: brand?.value,
                brand_name: brand?.label,
                page: 1,
              });
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 6, md: 3 }}>
          <SellerStoreSelect
            label={t('promotions.find_by_seller')}
            value={{
              value: urlParams?.seller_id,
              label: urlParams?.seller_name,
            }}
            onChange={(seller) => {
              handleParamsChange({
                seller_id: seller?.value,
                seller_name: seller?.label,
                page: 1,
              });
            }}
          />
        </Grid.Col>
      </Grid>
      <SelectProductList
        selectedProducts={selectedProducts}
        onSave={(products: any) => {
          setSelectedProducts?.(products);
          onClose?.();
        }}
      />
    </Modal>
  );
};
export default SelectProductModal;
