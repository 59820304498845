import { Tabs } from '@mantine/core';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import PageLayout from 'components/MantineUI/PageLayout';
import TabList from 'components/MantineUI/TabList';
import { useTranslation } from 'react-i18next';
import InsightOrders from './InsightOrders';
import InsightCustomers from './InsightCustomers';
import { useNavigate } from 'react-router-dom';

type PropType = {
  type?: string;
};

const AnalyticsInsights = (props: PropType) => {
  // ### CONSTANTs
  const { type = 'orders' } = props;
  const { t } = useTranslation();
  const title = t('analytics.insights');
  const subtitle = t('analytics.insights_desc');
  const navigate = useNavigate();
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <PageLayout title={title} breadCrumds={[{ title }]}>
      <SectionWrapper>
        <SectionHeader title={title} subtitle={subtitle} />
        <SectionBody>
          <TabList
            tabs={[
              {
                key: 'orders',
                label: t('orders.orders'),
              },

              {
                key: 'customers',
                label: t('navigation.customers'),
              },
            ]}
            selectedTab={type}
            onChangeTab={(tab) => navigate(`/insights/${tab}`)}
            panels={
              <>
                <Tabs.Panel value="orders" className="my-4">
                  <InsightOrders />
                </Tabs.Panel>

                <Tabs.Panel value="customers" className="my-4">
                  <InsightCustomers />
                </Tabs.Panel>
              </>
            }
          />
        </SectionBody>
      </SectionWrapper>
    </PageLayout>
  );
};
export default AnalyticsInsights;
