import { useGetOrderDetails } from 'apis/OrdersApi';
import { useParams } from 'react-router-dom';
import './styles.css';
import { Button, Flex, Image, List, Loader, Space, Text } from '@mantine/core';
import IconFA from 'components/common/IconFA';
import { faPrint } from '@fortawesome/pro-light-svg-icons';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DATETIME_FORMAT } from 'constants/format';
import logo from 'assets/img/logos/nailzy.png';
import fb from 'assets/img/nav-icons/facebook.png';
import ig from 'assets/img/icons/instagram.png';
import tt from 'assets/img/nav-icons/tiktok.png';
import yt from 'assets/img/logos/youtube.png';
import type { OrderItemType } from 'types/order.type';
import { UseQueryResult } from '@tanstack/react-query';

const OrderPackingList = () => {
  // ### CONSTANTs
  const { id } = useParams();
  // const { t } = useTranslation();
  const {
    data,
    isLoading,
    refetch,
    isFetching,
  }: UseQueryResult<{ data: OrderItemType }> = useGetOrderDetails(id);
  const { code, created_on, customer_name, pricing, shipping_address, note } =
    data?.data || {};
  const store_name = pricing?.seller?.store_name;
  const { f_name, l_name, business_name, location } = shipping_address || {};

  useDocumentTitle(`Packing list order # ${code}`);
  // ### FUNCTIONs
  const handlePrint = () => {
    window.print();
  };

  // ### RENDERs
  return isLoading ? (
    <Flex align={'center'} justify={'center'} pt={100}>
      <Loader />
    </Flex>
  ) : (
    <div className="letter-container-wrapper">
      <Flex justify={'end'} mb={'sm'}>
        <Button
          variant="default"
          leftSection={<IconFA icon={faPrint} type="light" />}
          className="no-print"
          onClick={handlePrint}
        >
          Print
        </Button>
      </Flex>

      <div className="letter-container flex flex-col gap-3">
        <div className="avoid-break">
          <Flex align={'center'} justify={'space-between'} gap={'sm'}>
            <Flex align={'center'}>
              <IconFA icon="store" className={'me-1'} type="light" size="sm" />
              <Text fw={600} size="sm">
                Seller: {store_name}
              </Text>
            </Flex>
            <Text size={'22px'} fw={600}>
              Packing Slip
            </Text>
          </Flex>
          <Text size="xs">Order #: {code}</Text>
          <Text size="xs">
            Order date:{' '}
            {dayjs
              .unix(created_on || 0)
              .format(DATETIME_FORMAT.MMMMDDYYYYHHMMA)}
          </Text>
        </div>

        <div className="avoid-break">
          <Text size="10px">Ship to:</Text>
          <Text size="xs">{customer_name}</Text>
          <Text size="xs">{business_name}</Text>
          <Text size="xs">{location}</Text>
        </div>

        {note ? (
          <div className="avoid-break">
            <Text size="xs">Order notes: {note}</Text>
          </div>
        ) : null}

        <div className="avoid-break">
          <Flex
            className="border-b border-neutral-300"
            justify={'space-between'}
            gap={'sm'}
            py={'xs'}
          >
            <Text size="10px">Items</Text>
            <Text size="10px">Quantity</Text>
          </Flex>
          {(pricing?.items || [])?.map(
            (item: OrderItemType['pricing']['items'][0], index: number) => {
              return (
                <Flex
                  className="avoid-break border-b border-neutral-100"
                  py={'xs'}
                  key={index}
                  align={'center'}
                  justify={'space-between'}
                  gap={'lg'}
                >
                  <Flex gap={'sm'}>
                    <Image
                      radius={'sm'}
                      src={item?.image_urls?.[0]}
                      w={54}
                      h={54}
                      className="border-1 object-fit-cover border-neutral-100"
                    />
                    <Flex direction={'column'} justify={'center'}>
                      <Text fw={600} size="sm">
                        {item?.product_name}
                      </Text>
                      <Text size="sm">{item?.variant_name}</Text>
                      {item?.sku ? (
                        <Text size="sm">SKU: {item?.sku}</Text>
                      ) : null}
                      {item?.barcode ? (
                        <Text size="sm">Barcode: {item?.barcode}</Text>
                      ) : null}
                    </Flex>
                  </Flex>
                  <Text size="md" fw={600} className="whitespace-nowrap">
                    x {item?.quantity}
                  </Text>
                </Flex>
              );
            },
          ) || null}
        </div>

        <div className="avoid-break">
          <div className="avoid-break">
            <Text size="10px">
              Thanks for shopping on Nailzy! If you have any questions, please
              contact us at{' '}
              <a
                href="https://nailzy.com/help"
                target="_blank"
                rel="noopener noreferrer"
              >
                nailzy.com/help
              </a>
            </Text>
          </div>
          <div className="avoid-break mt-2">
            <p className="text-[8px] font-semibold">Shipping Damage</p>
            <p className="text-[8px]">
              Product damage and shortages must be reported to Nailzy within 24
              hours of receipt of delivery. Damages must be noted on the
              Delivery Receipt and with the driver prior to signing for
              acceptance of goods. NO EXCEPTIONS ALLOWED.
            </p>
          </div>
          <div className="avoid-break mt-2">
            <p className="text-[8px] font-semibold">Return Policy</p>
            <p className="text-[8px]">
              We accept returns of undamaged goods, in the original packaging,
              within 15 days of delivery. A 25% fee may be charged upon
              inspection. The customer is responsible for 2-way shipping
              charges. Custom orders, gels, lacquer, liquids, powders,
              closeouts, furniture, spas, and time-dated items may not be
              returned.
            </p>
          </div>
          <div className="avoid-break mt-2">
            <p className="text-[8px] font-semibold">Disclaimer</p>
            <p className="text-[8px]">
              Nailzy is not responsible for changes in manufacturing components,
              specifications, or color that may differ from literature,
              websites, or other promotional items.
            </p>
          </div>
          <div className="avoid-break mt-2">
            <p className="text-[8px] font-semibold">Warranty</p>
            <p className="text-[8px]">
              All warranties are from the manufacturer and not from Nailzy.
              Shipping costs are not covered and you are responsible for all
              shipping charges.
            </p>
          </div>
          <div className="avoid-break mt-2">
            <p className="text-[8px] font-semibold">Return Guidelines</p>
            <List withPadding listStyleType="disc" size="xs">
              <List.Item>
                <p className="text-[8px]">
                  Items must be within 15 days of shipment.
                </p>
              </List.Item>
              <List.Item>
                <p className="text-[8px]">
                  All items must be in the original packaging, and in good
                  condition.
                </p>
              </List.Item>
              <List.Item>
                <p className="text-[8px]">
                  Upon inspection, the item has to be in resalable condition.
                </p>
              </List.Item>
            </List>
          </div>
          <div className="avoid-break mt-2">
            <Flex
              justify={'center'}
              align={'center'}
              direction={'column'}
              mt={'lg'}
              gap={'sm'}
            >
              <a
                href="https://nailzy.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={logo} w={100} />
              </a>
              <Text size="10px">Follow us @nailzyusa</Text>
              <Flex gap={'lg'}>
                <a
                  href="https://www.facebook.com/nailzyusa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={fb} maw={24} mah={24} />
                </a>
                <a
                  href="https://www.instagram.com/nailzyusa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={ig} maw={24} mah={24} />
                </a>
                <a
                  href="https://www.youtube.com/@nailzyusa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={yt} maw={24} mah={24} />
                </a>
                <a
                  href="https://www.tiktok.com/@nailzyusa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={tt} maw={24} mah={24} />
                </a>
              </Flex>
            </Flex>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderPackingList;
