import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import SkeletonWrapper from 'components/MantineUI/Skeleton/SkeletonWrapper';
import SectionPageSkeleton from 'components/MantineUI/Skeleton/SectionPageSkeleton';
import PageLayout from 'components/MantineUI/PageLayout';
import { Button, Grid, Loader } from '@mantine/core';
import DCInformation from './component/DCInformation';
import {
  useDeleteCarts,
  useDeleteDraftCart,
  useDraftCartPlaceOrder,
  useGetDraftCartDetails,
  useGetDraftCartPricingMethod,
  useGetDraftCartShippingMethod,
  useGetPaymentIntent,
  useUpdateDraftCart,
} from 'apis/DraftCartApi';
import {
  AddressInfo,
  CustomerItem,
  DraftCartItem,
  OrderStatusEnum,
  ProductItem,
} from 'types';
import { useEffect, useMemo, useState } from 'react';
import DCSummary from './component/DCSummary';
import { ShippingAddress } from 'pages/orders/order-details/components';

import {
  SectionBody,
  SectionFooter,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { toast } from 'react-toastify';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';
import IconFA from 'components/common/IconFA';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import DeleteWarningModal from 'components/common/DeleteWarningModal';
import PaymentModal from 'pages/add-draft-carts/components/PaymentModal';
import SellerCard from './component/SellerCard';
import CouponCard from './component/CouponCard';
import CustomerCard from 'components/MantineUI/Cards/CustomerCard';
import CustomerAddressBookCard from 'components/MantineUI/Cards/AddressBookCard';
import GrandTotalView from 'pages/orders/order-details/components/GrandTotalView';

const DraftCartDetailsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const title = id
    ? `${t('draft_carts.draft_cart')} # ${id}`
    : t('draft_carts.add_draft_carts');
  const { data, isLoading }: any = useGetDraftCartDetails(id);
  const draftCart = data?.data as DraftCartItem;

  // APIs
  const { mutate: getShipping, isPending: gettingShipping } =
    useGetDraftCartShippingMethod();
  const { mutate: getPricing, isPending: gettingPricing } =
    useGetDraftCartPricingMethod();
  const { mutate: updateDraftCart, isPending: updatingDraftCart } =
    useUpdateDraftCart();
  const { mutate: deleteCarts, isPending: deletingCarts } = useDeleteCarts();
  const [deleteCart, setDeleteCart] = useState<any>();
  const { mutate: placeOrder, isPending: placingOrder } =
    useDraftCartPlaceOrder();
  const { mutate: getPaymentIntent, isPending: gettingPaymentIntent } =
    useGetPaymentIntent();
  const { mutate: deleteDraftCart, isPending: deletingDraftCart } =
    useDeleteDraftCart();

  // STATES
  const [selectedProducts, setSelectedProducts] = useState<ProductItem[]>([]);
  const [cacheQuantity, setCacheQuantity] = useState({});
  const [deleteVariant, setDeleteVariant] = useState<any>(null);
  const [shipping, setShipping] = useState([]);
  const [shippingData, setShippingData] = useState<any>([]);
  const [pricing, setPricing] = useState<any>();
  const [customer, setCustomer] = useState<CustomerItem>();
  const [coupons, setCoupons] = useState([]);
  const [needDelete, setNeedDelete] = useState<any>([]);
  const [paymentIntent, setPaymentIntent] = useState<any>();

  useEffect(() => {
    if (shippingData?.length) {
      onRefetchPricing();
    }
  }, [shippingData]);

  useEffect(() => {
    if (data?.data) {
      setCustomer(data?.data?.customer);
      setCoupons(data?.data?.coupons);
      if (data?.data?.cart_items?.length) {
        let array: any = [];
        for (let i = 0; i < data?.data?.cart_items.length; i++) {
          const cart = data?.data?.cart_items[i];
          if (cart?.items?.length) {
            for (let y = 0; y < cart?.items.length; y++) {
              const variant = cart?.items[y];
              const data = {
                ...variant,
                _id: variant?._id,
                variant_id: variant?.variant_id,
                original_price: variant?.price,
                selling_price: variant?.discount_price,
                quantity: variant?.quantity,
              };
              let prodIndex = array?.findIndex(
                (x: { _id: any }) => x?._id === variant?.product_id,
              );
              if (prodIndex > -1) {
                array[prodIndex].variants.push(data);
              } else {
                array.push({
                  _id: variant?.product_id,
                  name: variant?.product_name,
                  seller: cart?.seller,
                  order: cart?.order,
                  variants: [data],
                });
              }
            }
          }
        }
        setSelectedProducts(array);
      }
    }
  }, [data]);

  // MEMO
  const isCompleted = draftCart?.status === OrderStatusEnum.COMPLETED;
  const [address, setAddress] = useState<AddressInfo>();
  useEffect(() => {
    let addr = data?.data?.shipping_address_id
      ? customer?.address?.find(
          (x) => x?._id === data?.data?.shipping_address_id,
        )
      : customer?.address?.find((x) => !!x?.is_default);
    setAddress(addr || customer?.address?.[0]);
  }, [customer, data]);
  const cart_ids = useMemo(() => {
    let array = [];
    for (let i = 0; i < data?.data?.cart_items.length; i++) {
      const cart_item = data?.data?.cart_items[i];
      for (let y = 0; y < cart_item?.items.length; y++) {
        const item = cart_item?.items[y];
        array?.push(item?._id);
      }
    }
    return array;
  }, [data]);
  const sellersData = useMemo(() => {
    let array: any = [];
    for (let i = 0; i < selectedProducts.length; i++) {
      const product = selectedProducts[i];
      if (product?.seller?._id) {
        let seller = array?.find(
          (x: { _id: string | undefined }) => x?._id === product?.seller?._id,
        );
        if (seller) {
          seller.products.push(product);
        } else {
          array.push({
            _id: product?.seller?._id,
            store_name: product?.seller?.store_name,
            order: data?.data?.orders?.find(
              (x: { seller_id: string | undefined }) =>
                x?.seller_id === product?.seller?._id,
            ),
            products: [product],
          });
        }
      }
    }
    return array;
  }, [selectedProducts]);

  //FUNCTIONS

  const handleParams = () => {
    let carts = [];
    let deleteIds = needDelete?.map((x: any) => x?._id);
    for (let i = 0; i < selectedProducts.length; i++) {
      const product = selectedProducts[i];
      if (product?.variants?.length) {
        for (let y = 0; y < product?.variants.length; y++) {
          const variant = product?.variants[y];
          deleteIds = deleteIds?.filter(
            (x: string | undefined) => x !== variant?._id,
          );
          carts?.push({
            _id: variant?._id || undefined,
            product_id: product?._id,
            variant_id: variant?.variant_id || variant?._id,
            items_total: variant?.discount_price,
            promotion_product_id: variant?.promotion_product_id,
            quantity:
              (cacheQuantity as any)?.[variant?.variant_id || ''] ||
              variant?.quantity ||
              1,
          });
        }
      }
    }
    return [
      {
        _id: id,
        customer_id: customer?._id,
        shipping_address_id: address?._id,
        coupon_ids: coupons?.length ? coupons?.map((x: any) => x?._id) : [],
        carts,
      },
      deleteIds,
    ];
  };

  const onUpdateSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: ['useGetDraftCartDetails', id],
    });
    if (shipping?.length) {
      onRefetchShipping();
      onRefetchPricing();
    }
  };
  const onSaveCart = () => {
    const [params, deleteIds] = handleParams();
    updateDraftCart(params as any, {
      onSuccess: (res: any) => {
        if (res?.status) {
          toast.success(t('general.update_success'));

          if ((deleteIds as any)?.length) {
            deleteCarts({ _id: (params as any)?._id, ids: deleteIds } as any, {
              onSuccess: () => {
                onUpdateSuccess();
              },
            });
          } else {
            onUpdateSuccess();
          }
        }
      },
    });
  };

  const onCloseDC = () => {
    const [params] = handleParams();
    updateDraftCart({ ...params, status: 'closed' } as any, {
      onSuccess: (res: any) => {
        if (res?.status) {
          toast.success(t('general.update_success'));
          queryClient.invalidateQueries({
            queryKey: ['useGetDraftCart'],
          });
          navigate('/draft-carts');
        }
      },
    });
  };
  const onPlaceOrder = (is_pay = false) => {
    let sellers = (shippingData || [])?.map(
      (
        x: { seller: { _id: any }; shipping: { id: any; is_flat_rate: any } },
        i: any,
      ) => {
        return {
          seller_id: x?.seller?._id,
          shipping_id: x?.shipping?.id,
          is_flat_rate: !!x?.shipping?.is_flat_rate,
        };
      },
    );
    if (!sellers?.length) {
      toast.error(t('orders.please_select_shipping'));
      return;
    }
    if (pricing?.total <= 0.5) {
      toast.error(t('orders.minimum_place_order'));
      return;
    }

    let params = {
      _id: id,
      sellers,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    placeOrder(params as any, {
      onSuccess: (res: any) => {
        toast.success(t('orders.order_success'));
        queryClient.invalidateQueries({
          queryKey: ['useGetDraftCartDetails', id],
        });
        queryClient.invalidateQueries({
          queryKey: ['useGetDraftCart'],
        });
        queryClient.invalidateQueries({
          queryKey: ['useAdminProduct'],
        });
        if (is_pay) {
          getPaymentIntent(
            { order_transaction_id: res?.data?.order_transaction_id } as any,
            {
              onSuccess: (intent: any) => {
                setPaymentIntent(intent?.data);
              },
            },
          );
        }
      },
    });
  };
  const onClickOption = (key = '') => {
    if (key === 'close') {
      onCloseDC();
    } else if (key === 'delete') {
      setDeleteCart(id as string);
    } else if (key === 'no_pay') {
      onPlaceOrder();
    } else if (key === 'credit') {
      onPlaceOrder(true);
    } else if (key === 'link') {
      // console.log('________key:', key);
    }
  };
  const onChangeQuantity = (
    product_id: string,
    variant_id: string,
    quantity: number,
  ) => {
    setCacheQuantity((prev) => ({ ...prev, [variant_id]: quantity }));
  };

  const onChangeShipping = (shipping: any, seller: { _id: any }) => {
    setShippingData((array: any) => {
      array = array?.filter((x: any) => x?.seller?._id !== seller?._id);
      array.push({ seller, shipping });
      return array;
    });
  };

  const onDeleteDC = () => {
    deleteDraftCart({ _id: id } as any, {
      onSuccess: () => {
        toast.success(t('general.delete_success'));
        queryClient.invalidateQueries({
          queryKey: ['useGetDraftCart'],
        });
        navigate('/draft-carts');
      },
    });
  };

  const onDeleteVariant = () => {
    const { product_id, variant_id } = deleteVariant;
    setNeedDelete((prev: any) => [...prev, deleteVariant]);
    let clone = [...selectedProducts];
    let prodIndex = clone?.findIndex?.((x) => x?._id === product_id);
    if (prodIndex > -1) {
      let array = clone?.[prodIndex]?.variants?.filter?.(
        (x) => x?.variant_id !== variant_id,
      );
      if (array?.length) {
        clone[prodIndex].variants = array;
      } else {
        delete clone[prodIndex];
      }
    }
    setSelectedProducts(clone);
    setDeleteVariant(null);
  };
  const onRefetchShipping = () => {
    if (data?.data?._id) {
      getShipping(
        {
          _id: data?.data?._id,
          cart_ids: cart_ids,
          customer_id: data?.data?.customer?._id,
          shipping_address_id: data?.data?.shipping_address_id,
        } as any,
        {
          onSuccess: (res: any) => setShipping(res?.data || []),
        },
      );
    }
  };

  const onRefetchPricing = () => {
    let sellers: any = shippingData?.map(
      (
        x: { seller: { _id: any }; shipping: { id: any; is_flat_rate: any } },
        i: any,
      ) => {
        return {
          seller_id: x?.seller?._id,
          shipping_id: x?.shipping?.id,
          is_flat_rate: !!x?.shipping?.is_flat_rate,
        };
      },
    );
    getPricing({ _id: data?.data?._id, sellers } as any, {
      onSuccess: (res: any) => setPricing(res?.data),
      onError: (error) => toast.error(error?.message),
    });
  };

  // RENDER

  return (
    <SkeletonWrapper skeleton={<SectionPageSkeleton />} show={isLoading}>
      <PageLayout
        title={title}
        breadCrumds={[
          {
            title: t('draft_carts.draft_carts'),
            href: '/draft-carts',
          },
          {
            title: title,
          },
        ]}
      >
        <Grid>
          <Grid.Col span={{ base: 12, md: 8 }}>
            <DCInformation
              data={draftCart}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
            />
            {sellersData?.length ? (
              <>
                {sellersData?.map((x: any, i: number) => (
                  <SellerCard
                    isCompleted={isCompleted}
                    seller={x}
                    key={x?._id}
                    onChangeQuantity={onChangeQuantity}
                    cacheQuantity={cacheQuantity}
                    onDeleteVariant={setDeleteVariant}
                    shipping={shipping?.find(
                      (y: any) => y?.seller_id === x?._id,
                    )}
                    onChangeShipping={(v: any) => onChangeShipping(v, x)}
                    onRefetchShipping={onRefetchShipping}
                    gettingShipping={gettingShipping}
                    isAdd={false}
                  />
                ))}
                <DCSummary
                  selectedProducts={selectedProducts}
                  cacheQuantity={cacheQuantity}
                  isCompleted={isCompleted}
                  pricing={pricing}
                  onRefetchPricing={onRefetchPricing}
                  gettingPricing={gettingPricing}
                />
              </>
            ) : null}
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4 }}>
            <div className="sticky-sidebar top-navbar-height d-flex flex-column">
              <CustomerCard
                isCompleted={isCompleted}
                customer={customer}
                onSave={setCustomer}
              />

              <CustomerAddressBookCard
                title={t('orders.shipping_address')}
                customer={customer}
                addressSelection={{
                  value: address as AddressInfo,
                  onChangeAddress: (address: AddressInfo) => {
                    setAddress(address);
                  },
                }}
              />

              {cart_ids?.length ? (
                <CouponCard
                  id={id}
                  isCompleted={isCompleted}
                  coupons={coupons}
                  onSave={setCoupons}
                />
              ) : null}
              {isCompleted ? null : (
                <>
                  <SectionWrapper>
                    <SectionFooter>
                      <Button
                        loading={updatingDraftCart}
                        disabled={
                          !customer || !address || !selectedProducts?.length
                        }
                        onClick={() => onSaveCart()}
                      >
                        {t('draft_carts.save_draft')}
                      </Button>
                      <DropdownButton
                        button={
                          <Button variant="default">
                            <IconFA icon={faEllipsis} />
                          </Button>
                        }
                        dropdownData={[
                          {
                            key: 'order_without_paying',
                            onClick: () => onClickOption('no_pay'),
                            label: t('orders.order_without_paying'),
                          },
                          {
                            key: 'credit',
                            onClick: () => onClickOption('credit'),
                            label: t('orders.pay_with_credit'),
                          },
                          {
                            disabled: true,
                            key: 'send_payment_link',
                            onClick: () => onClickOption('link'),
                            label: t('orders.send_payment_link'),
                          },
                          {
                            isDivider: true,
                          },
                          {
                            key: 'close',
                            onClick: () => onClickOption('close'),
                            label: t('general.close'),
                          },
                          {
                            key: 'delete',
                            color: 'red',
                            onClick: () => onClickOption('delete'),
                            label: t('general.delete'),
                          },
                        ]}
                      />
                    </SectionFooter>
                  </SectionWrapper>
                </>
              )}
            </div>
          </Grid.Col>
        </Grid>

        {/* MODALS */}

        {!paymentIntent ? null : (
          <PaymentModal
            visible={!!paymentIntent}
            onHide={() => setPaymentIntent(null)}
            data={paymentIntent}
            total={pricing?.total}
          />
        )}
        <DeleteWarningModal
          name={deleteCart}
          onShow={!!deleteCart}
          onClose={() => setDeleteCart(undefined)}
          onDelete={onDeleteDC}
          isLoading={deletingDraftCart}
        />
        <DeleteWarningModal
          name={deleteVariant?.variant_name}
          onShow={!!deleteVariant}
          onClose={() => setDeleteVariant(null)}
          onDelete={onDeleteVariant}
        />
      </PageLayout>
    </SkeletonWrapper>
  );
};

export default DraftCartDetailsPage;
