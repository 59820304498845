import { t } from 'i18next';
import { useCallback, useState } from 'react';
import { getColor } from 'helpers/utils';
import { useGetDraftCartCoupons } from '../../../apis/DraftCartApi';
import { faArrowsRotate, faTrash } from '@fortawesome/pro-light-svg-icons';
import { Button, Flex, Loader } from '@mantine/core';
import IconFA from 'components/common/IconFA';
import CouponItem from './CouponItem';
import CouponSelect from 'components/MantineUI/Selects/CouponSelect';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';

type Props = {
  coupons?: any;
  onSave?: any;
  isCompleted?: boolean;
  id?: string;
};

const CouponCard = ({ coupons = [], onSave, isCompleted, id }: Props) => {
  const [tempCp, setTempCp] = useState<any>(null);

  const { data: res, refetch, isRefetching }: any = useGetDraftCartCoupons(id);
  const couponList = res?.data || [];

  const isDisabled = (item: any) => {
    let sameProvider = coupons?.filter(
      (x: { provider: any }) => x?.provider === item?.provider,
    );
    if (item?.provider === 'nailzy' && sameProvider?.length) {
      return true;
    } else if (item?.provider === 'seller') {
      let selected = sameProvider?.find(
        (x: { seller: { _id: any } }) => x?.seller?._id === item?.seller?._id,
      );
      if (selected) {
        return true;
      }
    }
    return false;
  };

  const couponOptions = couponList
    ?.filter(
      (x: { _id: any }) =>
        !coupons?.find((y: { _id: any }) => y?._id === x?._id),
    )
    ?.map((item: any) => ({
      ...item,
      value: item?._id,
      label: item?.name,
      total: item?.value,
      isDisabled: isDisabled(item),
    }));
  const onSelectCoupon = (value: any) => {
    let found = couponList?.find((x: any) => x?._id === value?.value);
    if (found) {
      setTempCp(found);
    } else {
      setTempCp(null);
    }
  };
  const onClickSave = () => {
    let array = [...(coupons || [])];
    array = array?.filter((x) => x?._id !== tempCp?.value);
    array.push(tempCp);

    onSave?.(array);
    setTempCp(null);
  };
  const isDisabledCoupon = useCallback(
    (item: any) => {
      let sameProvider = coupons?.filter(
        (x: any) => x?.provider === item?.provider,
      );
      if (item?.provider === 'nailzy' && sameProvider?.length) {
        return true;
      } else if (item?.provider === 'seller') {
        let selected = sameProvider?.find(
          (x: any) => x?.seller?._id === item?.seller?._id,
        );
        if (selected) {
          return true;
        }
      }
      return false;
    },
    [coupons],
  );
  return isCompleted && !coupons?.length ? null : (
    <SectionWrapper>
      <SectionHeader
        isSubSection
        title={
          <>
            {t('form.coupon.coupon')}{' '}
            {isCompleted ? null : isRefetching ? (
              <Loader className="ms-1" size={15} />
            ) : (
              <IconFA onClick={refetch} icon={faArrowsRotate} size="xs" />
            )}
          </>
        }
        actionButtons={
          isCompleted ? null : (
            <Button
              className="ms-2"
              variant="default"
              size="xs"
              disabled={!tempCp}
              onClick={onClickSave}
            >
              {t('general.apply')}
            </Button>
          )
        }
      />
      <SectionBody>
        {isCompleted ? null : (
          <CouponSelect
            key={coupons}
            route={`admin/draft-carts/${id}/coupons`}
            className="flex-1 mb-3"
            label=""
            value={tempCp}
            optionTransformer={(data) => {
              return data?.map((x: any) => ({
                ...x,
                total: x?.value,
                value: x?._id,

                label: x.name,
                code: x.code,
                status: x.status,
                disabled: isDisabledCoupon(x),
              }));
            }}
            renderOption={(item: any) => <CouponItem item={{ ...item }} />}
            onChange={setTempCp}
            excludes={coupons?.map((x: any) => x?._id || x?.value)}
          />
        )}
        <Flex direction={'column'} gap={'xs'}>
          {coupons?.length
            ? coupons?.map((coupon: any, index: number) => (
                <Flex key={'couponItems' + index} align={'center'}>
                  <CouponItem item={coupon} />
                  {isCompleted ? null : (
                    <IconFA
                      icon={faTrash}
                      color={getColor('gray-500')}
                      onClick={() => {
                        let array = [...(coupons || [])];
                        array = array?.filter((x) => x?._id !== coupon?._id);
                        onSave?.(array);
                      }}
                    />
                  )}
                </Flex>
              ))
            : null}
        </Flex>
      </SectionBody>
    </SectionWrapper>
  );
};
export default CouponCard;
