import { DraftCartItem, OrderStatusEnum, StatusEnum } from 'types';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex, Text } from '@mantine/core';

import { dateTimeFormatter } from 'helpers/utils';
import StatusBadge from 'components/MantineUI/StatusBadge';
import SelectProductModal from './SelectProductModal';
type PropType = {
  children?: ReactNode;
  className?: string;
  data?: DraftCartItem;
  selectedProducts: any;
  setSelectedProducts: any;
};
const DCInformation = (props: PropType) => {
  // ### CONSTANTs
  const { data, selectedProducts = [], setSelectedProducts } = props;
  const { t } = useTranslation();
  const [selectProductVisible, setSelectProductVisible] = useState(false);
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <SectionWrapper className="mb-3">
      <SectionHeader
        title={`${t('draft_carts.draft_cart')} # ${
          data?._id ? data?._id : ''
        } `}
        actionButtons={
          data?.status !== OrderStatusEnum.COMPLETED ? (
            <Button
              size="xs"
              variant="outline"
              onClick={() => setSelectProductVisible(true)}
            >
              {t('promotions.select_products')}
            </Button>
          ) : null
        }
      />
      {data?._id ? (
        <SectionBody>
          <Flex direction={'column'} gap={'xs'}>
            <Text size="sm">{dateTimeFormatter(data?.created_on)}</Text>
            <StatusBadge status={data?.status as StatusEnum} />
            <Text size="sm">
              {t('general.created_by')}{' '}
              <span className="fw-semi-bold">
                {`${data?.created_by?.first_name} ${data?.created_by?.last_name}`}
              </span>
            </Text>
          </Flex>
        </SectionBody>
      ) : null}
      <SelectProductModal
        selectedProducts={selectedProducts}
        visible={selectProductVisible}
        onHide={() => setSelectProductVisible(false)}
        onSave={setSelectedProducts}
      />
    </SectionWrapper>
  );
};
export default DCInformation;
