import { useMutation, useQuery } from '@tanstack/react-query';
import { apiCallV2 } from 'apis/ApiCallV2';

export const useDashboardReport = (params) => {
  const url = 'admin/dashboards/reports';
  return useQuery({
    queryKey: ['useDashboardReport', params],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'get',
        params: {
          ...params,
          // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }),
  });
};
export const useDashboardThingTodo = () => {
  const url = '/admin/dashboards/reports/things-to-do';
  return useQuery({
    queryKey: ['useDashboardThingTodo'],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'get',
      }),
  });
};

export const useDashboardBestSellingItems = (params) => {
  const { enabled, ...els } = params;
  const url = 'admin/dashboards/best-sellers';
  return useQuery({
    queryKey: ['useDashboardBestSellingItems', params],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'get',
        params: {
          ...els,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }),
    enabled: enabled,
  });
};

export const useDashboardTotalChart = (params) => {
  const { from, to } = params;
  const url = 'admin/dashboards/total-of-charts';
  return useQuery({
    queryKey: ['useDashboardTotalChart', params],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'get',
        params,
      }),
    enabled: !!from && !!to,
  });
};
export const useDashboardOrderChart = (params) => {
  const { from, to, enabled, ...els } = params;
  const url = 'admin/dashboards/order-charts';
  return useQuery({
    queryKey: ['useDashboardOrderChart', params],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'get',
        params: {
          ...els,
          from,
          to,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }),
    enabled: !!from && !!to && !!enabled,
  });
};
export const useDashboardCustomerChart = (params) => {
  const { from, to, enabled, ...els } = params;
  const url = 'admin/dashboards/customer-charts';
  return useQuery({
    queryKey: ['useDashboardCustomerChart', params],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'get',
        params: {
          ...els,
          from,
          to,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }),
    enabled: !!from && !!to && !!enabled,
  });
};

export const useExportOrderChart = () => {
  const url = 'admin/dashboards/exports/report-orders';
  return useMutation({
    mutationFn: (params) =>
      apiCallV2({
        url,
        method: 'get',
        params: {
          ...params,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }),
  });
};

export const useExportCustomerChart = () => {
  const url = 'admin/dashboards/exports/report-customers';
  return useMutation({
    mutationFn: (params) =>
      apiCallV2({
        url,
        method: 'get',
        params: {
          ...params,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }),
  });
};

export const useExportBestSellingItemsChart = () => {
  const url = 'admin/dashboards/exports/best-seller-items';
  return useMutation({
    mutationFn: (params) =>
      apiCallV2({
        url,
        method: 'get',
        params: {
          ...params,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }),
  });
};

export const useDashboardLowStockInventory = (params) => {
  const { enabled, ...els } = params;
  const url = 'admin/dashboards/low-stock-items';
  return useQuery({
    queryKey: ['useDashboardLowStockInventory', params],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'get',
        params: {
          ...els,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }),
    enabled: enabled,
  });
};

export const useExportBLowStockInventory = () => {
  const url = 'admin/dashboards/exports/low-stock-items';
  return useMutation({
    mutationFn: (params) =>
      apiCallV2({
        url,
        method: 'get',
        params: {
          ...params,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }),
  });
};
