import { Button, Grid, Modal, NumberInput, TextInput } from '@mantine/core';
import { Form, useForm } from '@mantine/form';
import { CommissionRateItem, StatusEnum } from 'types';
import { SectionFooter } from 'components/MantineUI/CommonSection';
import StatusSelect from 'components/MantineUI/Selects/StatusSelect';
import { t } from 'i18next';
import {
  useAddCommissionRate,
  useEditCommissionRate,
} from 'apis/AffiliateApis';
import { useEffect } from 'react';
type PropType = {
  opened: boolean;
  onClose: () => void;
  data?: CommissionRateItem;
};
const AddCommissionRateModal = (props: PropType) => {
  // ### CONSTANTs
  const { opened = false, onClose: onCloseProps, data } = props;
  const isEdit = !!data?._id;
  const { mutate: addCommissionRate, isPending: creating } =
    useAddCommissionRate();
  const { mutate: updateCommissionRate, isPending: updating } =
    useEditCommissionRate(data?._id);
  // ### FUNCTIONs
  useEffect(() => {
    setValues({
      name: data?.name || '',
      rate: data?.commission_rate
        ? parseFloat((data?.commission_rate * 100).toFixed(2))
        : 0,
      status: data?.status || StatusEnum.ACTIVE,
    });
  }, [data]);
  const form = useForm({
    initialValues: {
      name: '',
      rate: 0,
      status: StatusEnum.ACTIVE,
    },
  });
  const { setValues, getValues, reset } = form;
  const values = getValues();
  const onClose = () => {
    reset();
    onCloseProps?.();
  };
  const onSave = () => {
    if (isEdit) {
      updateCommissionRate(
        {
          name: values?.name,
          commission_rate: (values?.rate > 100 ? 100 : values?.rate) / 100,
          status: values?.status,
        },
        {
          onSuccess: () => onClose(),
        },
      );
    } else {
      addCommissionRate(
        {
          name: values?.name,
          commission_rate: (values?.rate > 100 ? 100 : values?.rate) / 100,
          status: values?.status,
        },
        {
          onSuccess: () => onClose(),
        },
      );
    }
  };
  // ### RENDERs
  return (
    <Modal
      size={'lg'}
      onClose={onClose}
      opened={opened}
      title={
        isEdit
          ? t('affiliates.edit_commission_rates')
          : t('affiliates.add_commission_rates')
      }
    >
      <Form form={form} onSubmit={onSave}>
        <Grid>
          <Grid.Col span={{ base: 12, sm: 6 }}>
            <TextInput
              required
              label={t('general.name')}
              placeholder="Rate 5% for KOL"
              value={values?.name}
              onChange={(value) => setValues({ name: value?.target?.value })}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6 }}>
            <NumberInput
              required
              label={t('affiliates.commission_rate')}
              placeholder="5%"
              min={0}
              max={100}
              suffix="%"
              decimalScale={2}
              value={values?.rate}
              onChange={(value) => setValues({ rate: value as number })}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6 }}>
            <StatusSelect
              value={values?.status}
              onChange={(v) =>
                setValues({ status: v?.target?.value as StatusEnum })
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12 }}>
            <SectionFooter>
              <Button loading={creating || updating} type="submit">
                {t('general.save')}
              </Button>
              <Button onClick={onClose} variant="default">
                {t('general.cancel')}
              </Button>
            </SectionFooter>
          </Grid.Col>
        </Grid>
      </Form>
    </Modal>
  );
};
export default AddCommissionRateModal;
