import { useMutation } from '@tanstack/react-query';
import { apiCallV2 } from './ApiCallV2';

export const useGenerateUrls = () => {
  const controller = new AbortController();

  const mutation = useMutation({
    mutationFn: (body) => {
      const url = `admin/media/generate-upload-urls`;

      return apiCallV2({
        method: 'POST',
        url,
        data: body,
        signal: controller.signal,
      });
    },
  });

  return mutation;
};

export const useSyncStatusImage = () => {
  const controller = new AbortController();

  const mutation = useMutation({
    mutationFn: (id) => {
      const url = `admin/media/${id}/sync-status`;
      const headerOptions = {
        'Content-Type': 'multipart/form-data',
      };

      return apiCallV2({
        method: 'PUT',
        url,
        headerOptions,
        signal: controller.signal,
      });
    },
  });

  return mutation;
};

export const useCreateDownloadImageURL = () => {
  const controller = new AbortController();

  const mutation = useMutation({
    mutationFn: ({ ids, isPrivate }) => {
      const url = 'admin/media/generate-download-urls';
      const params = {
        isPrivate,
      };
      const data = {
        ids,
      };

      return apiCallV2({
        method: 'POST',
        url,
        params,
        data,
        signal: controller.signal,
      });
    },
  });

  return mutation;
};
