import RenderOptionWithStatus from 'components/common/inputs/RenderOptionWithStatus';
import { t } from 'i18next';
import { useAuth } from 'contexts/AuthContext';
import { checkPermission } from 'helpers/utils';
import SingleSelect, { SelectItem, SingleSelectProps } from './SingleSelect';
import MultiSelect from './MultiSelect';
import { Flex, Text } from '@mantine/core';
import { StatusEnum } from 'types';
import StatusBadge from '../StatusBadge';

type PropType =
  | {
      multiple: true;
      value: SelectItem[];
      onChange: (ids: SelectItem[]) => void;
      //
      className?: string;
      errorMessage?: string;
      required?: boolean;
    }
  | {
      multiple?: false;
      value: SelectItem;
      onChange: (id: SelectItem) => void;
      //
      className?: string;
      errorMessage?: string;
      required?: boolean;
    };

const CouponSelect = (props: SingleSelectProps & PropType) => {
  // ### CONSTANTs
  const {
    value,
    onChange,
    multiple,
    optionTransformer,
    disabled,
    route,
    ...els
  } = props;
  // sellers
  const { user } = useAuth();
  const isGranted = checkPermission(user, '/coupons');
  // ### FUNCTIONs
  // ### RENDERs
  const generalProps: SingleSelectProps = {
    route: route || '/admin/coupons',
    label: t('navigation.coupon'),
    placeholder: t('general.select'),
    disabled: disabled || !isGranted,
    renderOption: (option: any, isSelected?: boolean) => (
      <Flex gap={'xs'} align={'center'} wrap={'wrap'}>
        <Text size="sm" c={isSelected ? 'primary' : ''}>
          {option?.label} ({option?.code})
        </Text>
        {option?.status && option?.status !== StatusEnum.ACTIVE && (
          <StatusBadge status={option?.status} />
        )}
      </Flex>
      // <RenderOptionWithStatus {...option} isSelected={isSelected} />
    ),
    //
    optionTransformer: optionTransformer
      ? optionTransformer
      : (data: any) =>
          data?.results?.map((x: any) => ({
            label: x.name,
            value: x._id,
            code: x.code,
            status: x.status,
          })),
    searchField: 'name',
    ...els,
  };
  if (multiple) {
    return (
      <MultiSelect
        {...generalProps}
        selected={value}
        onChange={(value) => {
          onChange?.(value as SelectItem[]);
        }}
      />
    );
  } else {
    return (
      <SingleSelect
        {...generalProps}
        selected={value}
        onChange={(value) => {
          onChange?.(value as SelectItem);
        }}
      />
    );
  }
};
export default CouponSelect;
