import { MantineSize, NativeSelect, Pagination, Text } from '@mantine/core';
import { CommonPaginationProps } from 'types';

type PropType = {
  pagination: CommonPaginationProps;
  className?: string;
  size?: MantineSize;
  hideLimit?: boolean;
};

const CommonPagination = (props: PropType) => {
  // ### CONSTANTs
  const { className = '', pagination, size = 'sm', hideLimit = false } = props;
  const {
    total = 0,
    page = 1,
    limit = 25,
    onPaginationChange,
  } = pagination || {};
  // ### FUNCTIONs
  const onChange = (newPage: number = page, newLimit: number = limit) => {
    if (typeof onPaginationChange === 'function') {
      onPaginationChange(newPage, newLimit);
    }
  };
  // ### RENDERs
  return (
    <div
      className={`flex w-full flex-col items-center justify-between gap-2 md:flex-row ${className}`}
    >
      {hideLimit ? (
        <>
          <Text></Text>
        </>
      ) : (
        <div className="flex items-center">
          <NativeSelect
            value={limit.toString()}
            size={'xs'}
            data={['10', '50', '100', '250', '500', '1000']}
            onChange={(e) => onChange(1, parseInt(e?.target?.value))}
          />
          {total ? (
            <Text size="xs" c={'gray.7'} ml={'xs'}>
              of {total}
            </Text>
          ) : null}
        </div>
      )}
      <Pagination
        color="primary"
        size={size}
        radius={size}
        total={total <= limit ? 1 : Math.floor(total / limit) + 1}
        value={page}
        onChange={onChange}
        withEdges
      />
    </div>
  );
};
export default CommonPagination;
