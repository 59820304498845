import { Checkbox, Flex, Loader, Text } from '@mantine/core';
import { StatusEnum } from 'types';
import IconFA from 'components/common/IconFA';
import NailzyImage from 'components/common/NailzyImage';
import StatusBadge from 'components/MantineUI/StatusBadge';
import { currencyFormatter } from 'helpers/utils';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetchPromotionProductVariants } from 'apis/Promotion';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';

type Props = {
  product?: any;
  onSelect?: any;
  viewOnly?: boolean;
  selectOptions?: any;
  errorVariants?: any;
  canSelectVariant?: boolean;
};

const ProductItem = ({
  product,
  onSelect,
  viewOnly = false,
  selectOptions,
  errorVariants,
  canSelectVariant,
}: Props) => {
  const { id: promotion_id } = useParams();
  const { total_variant, total_promotion_variant } = product || {};
  const localProduct = selectOptions?.find(
    (x: any) => x?.type === 'product' && x?.data?._id === product?._id,
  );
  const isProductSelected = localProduct
    ? localProduct?.isSelect
    : !!(total_promotion_variant && total_promotion_variant === total_variant);
  const [isExpand, setExpand] = useState(viewOnly);
  const [variants, setVariants] = useState<any>([]);
  const { mutate: fetchVariants, isPending: fetchingVariants } =
    useFetchPromotionProductVariants();
  const countVariants = localProduct
    ? localProduct?.isSelect
      ? variants?.length
      : 0
    : variants?.reduce((t: number, c: any) => t + (c?.is_selected ? 1 : 0), 0) +
      selectOptions
        ?.filter((x: any) => x?.data?.product_id === product?._id)
        ?.reduce((t: number, c: any) => t + (c?.isSelect ? 1 : -1), 0);
  const isIndeterminate =
    (countVariants && countVariants < variants?.length) ||
    (!localProduct &&
      !!(total_promotion_variant && total_variant !== total_promotion_variant));

  const { t } = useTranslation();

  const onSelectVariant = (variant: any, checked: boolean) => {
    if (countVariants + (checked ? 1 : -1) === variants?.length) {
      onSelect({ ...product, variants }, null, true, variants);
    } else {
      onSelect(product, variant, checked, variants);
    }
  };
  const renderVariant = (variant: any, index: any) => {
    const localVariant = selectOptions?.find(
      (x: any) => x?.type === 'variant' && x?.data?._id === variant?._id,
    );
    const isVariantSelected = localProduct
      ? localProduct?.isSelect
      : localVariant
        ? localVariant?.isSelect
        : variant?.is_selected;
    const isVarError = !!errorVariants?.find(
      (y: any) => y?._id === variant?._id,
    );
    return (
      <div
        key={`variant_${index}`}
        onClick={() => onSelectVariant?.(variant, !isVariantSelected)}
        className="bg-200 flex items-center gap-2 border-b border-gray-200 px-3 py-2"
      >
        {viewOnly ? null : (
          <Checkbox
            size="xs"
            checked={!!isVariantSelected}
            onClick={() => onSelectVariant?.(variant, !isVariantSelected)}
            className="pr-6"
          />
        )}

        <div className="flex flex-1 items-center justify-between">
          <div className="flex w-auto flex-1 items-center gap-2">
            <NailzyImage
              rounded
              image_url={variant?.image_urls?.[0]}
              style={{
                width: '50px',
                height: '50px',
                borderWidth: 1,
                borderColor: '#eee',
                borderStyle: 'solid',
              }}
            />
            <div>
              <div className="flex items-center gap-2">
                <Text
                  c={isVarError ? 'red' : ''}
                  fw={'500'}
                  className="truncate"
                >
                  {variant?.name}{' '}
                </Text>
                {variant?.status && variant?.status !== StatusEnum.ACTIVE ? (
                  <StatusBadge status={variant?.status} />
                ) : null}
                {isVarError ? (
                  <IconFA color="red" icon={faTriangleExclamation} />
                ) : null}
              </div>
              <Text size="xs">
                {variant?.sku ? `SKU: ${variant?.sku}` : null}
              </Text>
            </div>
          </div>

          <Text w={150} className="text-center">
            {variant?.available_stock}
          </Text>
          <Text w={150} className="text-center">
            {variant?.selling_price
              ? currencyFormatter.format(variant?.selling_price)
              : ''}
          </Text>
        </div>
      </div>
    );
  };
  const onSelectAll = () => {
    onSelect({ ...product, variants }, null, !isProductSelected);
  };
  const onClickProduct = () => {
    if (canSelectVariant) {
      if (isExpand) {
        setExpand(false);
      } else {
        if (variants?.length) {
          setExpand(true);
        } else {
          fetchVariants(
            { product_id: product?._id, promotion_id: promotion_id } as any,
            {
              onSuccess: (res: any) => {
                setVariants(res?.data);
                setExpand(true);
              },
            },
          );
        }
      }
    } else {
      onSelectAll();
    }
  };
  const isProdError = !!errorVariants?.find(
    (y: any) => y?.product_id === product?._id,
  );
  return (
    <>
      <div
        onClick={() => onClickProduct()}
        className={`flex cursor-pointer items-center gap-3 border-b border-gray-200 px-3 py-2 ${
          isExpand ? 'bg-200' : ''
        }`}
      >
        {!total_variant || viewOnly ? null : (
          <Checkbox
            size="xs"
            indeterminate={isIndeterminate}
            checked={isProductSelected}
            onClick={(e) => {
              // e.preventDefault();
              e.stopPropagation();
              onSelectAll();
            }}
          />
        )}

        {canSelectVariant && total_variant > 0 ? (
          <IconFA
            icon={!isExpand ? 'chevron-right' : 'chevron-down'}
            color="gray"
            type="light"
          />
        ) : null}

        {/* PRODUCT ROW */}
        <div className="flex w-full items-center justify-between gap-6">
          <div className="flex items-center gap-2">
            <NailzyImage
              rounded
              image_url={product?.image_url}
              style={{
                width: '48px',
                height: '48px',
                borderWidth: 1,
                borderColor: '#eee',
                borderStyle: 'solid',
              }}
            />
            <Text c={isProdError ? 'red' : ''} fw={'600'} className="truncate">
              {product?.name}{' '}
            </Text>
            {product?.status !== StatusEnum.ACTIVE && (
              <StatusBadge status={product?.status} />
            )}
            <IconFA
              color="gray"
              size="sm"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                window.open('/products/' + product?._id, '_blank');
              }}
              icon={faArrowUpRightFromSquare}
            />{' '}
            {isProdError ? (
              <IconFA icon={faTriangleExclamation} color="red" />
            ) : null}
            {fetchingVariants ? <Loader className="ms-1" size={'xs'} /> : null}
          </div>

          <Text size="sm" fw={'500'} w={150}>
            {t('products.total_variants')}: {total_variant}
          </Text>
        </div>
      </div>

      {isExpand && variants?.length ? (
        <div className="">
          {variants?.map(
            (
              x: { _id: any; image_urls?: any; name?: any; sku?: any },
              i: any,
            ) => {
              return renderVariant(x, i);
            },
          )}
        </div>
      ) : null}
    </>
  );
};
export default ProductItem;
