import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import PageLayout from 'components/MantineUI/PageLayout';
import { Button, Grid } from '@mantine/core';
import DCInformation from './component/DCInformation';
import { useCreateDraftCart } from 'apis/DraftCartApi';
import { AddressInfo, CustomerItem, ProductItem } from 'types';
import { useMemo, useState } from 'react';
import {
  SectionFooter,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { toast } from 'react-toastify';
import DeleteWarningModal from 'components/common/DeleteWarningModal';
import SellerCard from './component/SellerCard';
import CustomerCard from 'components/MantineUI/Cards/CustomerCard';
import CustomerAddressBookCard from 'components/MantineUI/Cards/AddressBookCard';

const AddDraftCartsPage = () => {
  const { t } = useTranslation();
  const title = t('draft_carts.add_draft_carts');
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // APIs
  const { mutate: createDraftCart, isPending: creatingDraftCart } =
    useCreateDraftCart();

  // STATES
  const [selectedProducts, setSelectedProducts] = useState<ProductItem[]>([]);
  const [cacheQuantity, setCacheQuantity] = useState({});
  const [deleteVariant, setDeleteVariant] = useState<any>(null);
  const [customer, setCustomer] = useState<CustomerItem>();
  const [coupons, setCoupons] = useState([]);

  // MEMO

  const address = useMemo(() => {
    let addr = customer?.address?.find((x) => !!x?.is_default);
    return addr || customer?.address?.[0];
  }, [customer]);
  const sellersData = useMemo(() => {
    let array: any = [];
    for (let i = 0; i < selectedProducts.length; i++) {
      const product = selectedProducts[i];
      if (product?.seller?._id) {
        let seller = array?.find(
          (x: { _id: string | undefined }) => x?._id === product?.seller?._id,
        );
        if (seller) {
          seller.products.push(product);
        } else {
          array.push({
            _id: product?.seller?._id,
            store_name: product?.seller?.store_name,
            products: [product],
          });
        }
      }
    }
    return array;
  }, [selectedProducts]);

  //FUNCTIONS

  const handleParams = () => {
    let carts = [];
    for (let i = 0; i < selectedProducts.length; i++) {
      const product: any = selectedProducts[i];
      for (let y = 0; y < product?.variants.length; y++) {
        const variant = product?.variants[y];
        carts?.push({
          product_id: product?._id,
          variant_id: variant?.variant_id,
          quantity: (cacheQuantity as any)?.[variant?.variant_id] || 1,
          items_total: variant?.discount_price,
        });
      }
    }
    return {
      customer_id: customer?._id,
      shipping_address_id: address?._id,
      coupon_ids: coupons ? coupons?.map((x: any) => x?._id) : undefined,
      carts,
    };
  };
  const onSaveCart = () => {
    const params = handleParams();
    createDraftCart(params as any, {
      onSuccess: (res: any) => {
        if (res?.status) {
          toast.success(t('general.create_success'));
          navigate('/draft-carts/' + res?.data?._id);
          queryClient.invalidateQueries({ queryKey: ['useGetDraftCart'] });
        }
      },
    });
  };

  const onChangeQuantity = (
    product_id: string,
    variant_id: string,
    quantity: number,
  ) => {
    setCacheQuantity((prev) => ({ ...prev, [variant_id]: quantity }));
  };

  const onDeleteVariant = () => {
    const { product_id, variant_id } = deleteVariant || {};
    let clone = [...selectedProducts];
    let prodIndex = clone?.findIndex?.((x) => x?._id === product_id);
    if (prodIndex > -1) {
      let array = clone?.[prodIndex]?.variants?.filter?.(
        (x) => x?.variant_id !== variant_id,
      );
      if (array?.length) {
        clone[prodIndex].variants = array;
      } else {
        delete clone[prodIndex];
      }
    }
    setSelectedProducts(clone);
    setDeleteVariant(null);
  };
  // RENDER
  const defaultAddress = useMemo(() => {
    const defaultAddr = customer?.address?.find((x) => !!x?.is_default);
    return defaultAddr
      ? [defaultAddr]
      : customer?.address?.[0]
      ? [customer?.address?.[0]]
      : [];
  }, [customer]);

  return (
    <PageLayout
      title={title}
      breadCrumds={[
        {
          title: t('draft_carts.draft_carts'),
          href: '/draft-carts',
        },
        {
          title: title,
        },
      ]}
    >
      <Grid>
        <Grid.Col span={{ base: 12, sm: 8 }}>
          <DCInformation
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
          {sellersData?.length ? (
            <>
              {sellersData?.map((x: any, i: number) => (
                <SellerCard
                  isAdd
                  seller={x}
                  key={x?._id}
                  cacheQuantity={cacheQuantity}
                  onChangeQuantity={onChangeQuantity}
                  onDeleteVariant={setDeleteVariant}
                />
              ))}
            </>
          ) : null}
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 4 }}>
          <div className="sticky-sidebar top-navbar-height d-flex flex-column">
            <CustomerCard
              customer={customer}
              onSave={setCustomer}
              isCompleted={false}
            />

            <CustomerAddressBookCard
              title={t('orders.shipping_address')}
              customer={{
                ...customer,
                address: defaultAddress,
              }}
              deletable={false}
              addable={customer && !customer?.address?.length}
              onUpdateAddress={(address: AddressInfo) => {
                setCustomer({ ...customer, address: [address] });
              }}
            />
            <SectionWrapper>
              <SectionFooter>
                <Button
                  loading={creatingDraftCart}
                  disabled={!customer || !address || !selectedProducts?.length}
                  onClick={() => onSaveCart()}
                >
                  {t('draft_carts.save_draft')}
                </Button>
              </SectionFooter>
            </SectionWrapper>
          </div>
        </Grid.Col>
      </Grid>

      {/* MODALS */}
      <DeleteWarningModal
        name={deleteVariant?.variant_name}
        onShow={!!deleteVariant}
        onClose={() => setDeleteVariant(null)}
        onDelete={onDeleteVariant}
      />
    </PageLayout>
  );
};

export default AddDraftCartsPage;
