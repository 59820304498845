import { Logtail } from '@logtail/browser';
import * as Sentry from '@sentry/react';
import { getProfile } from 'apis/AuthApi';
import axios from 'axios';

// Get Logtail source token
const logtail = new Logtail(process.env.REACT_APP_LOGTAIL_SOURCE_TOKEN, {
  endpoint: 'https://in.logs.betterstack.com',
});

// Log errors and send to Logtail
export const logErrors = async (error) => {
  // Get user IP address
  const ipAddress = await axios.get('https://api.ipify.org/?format=json');

  if (error) {
    logtail.error(
      `${error}. URL: ${window.location.href}. IP: ${ipAddress.data.ip}`,
    );
    logtail.flush();
  }
};

// Log user actions
export const actionEnum = {
  LOGIN: 'Login',
  CREATE_PRODUCT: 'Create product',
  DELETE_PRODUCT: 'Delete product',
  UPDATE_PRODUCT: 'Update product',
  EXPORT_PRODUCT: 'Export product',
  CREATE_PROMOTION: 'Create promotion',
  DELETE_PROMOTION: 'Delete promotion',
  UPDATE_PROMOTION: 'Update promotion',
  UPDATE_PROMOTION_PRODUCT: 'Update promotion product',
  DELETE_PROMOTION_PRODUCT: 'Delete promotion product',
  CREATE_COUPON: 'Create coupon',
  DELETE_COUPON: 'Delete coupon',
  UPDATE_COUPON: 'Update coupon',
  EXPORT_ORDER: 'Export order',
};

// Logs user actions and sends to Sentry
export const logUserAction = async (action, details) => {
  // Get current user
  const currentUser = await getProfile();
  if (currentUser) {
    const user = {
      email: currentUser?.data?.email || '',
      id: currentUser?.data?._id,
    };

    // Log user action to Sentry
    Sentry.captureMessage(action, {
      level: 'info',
      extra: details,
    });

    // Log user action to Logtail
    logtail.info(action, { ...details, user });
    await logtail.flush();
  }
};
