import { CommonTableColumn } from 'types';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import PageLayout from 'components/MantineUI/PageLayout';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, Button, Flex, Grid, Text } from '@mantine/core';
import { CommonTable } from 'components/MantineUI/CommonTable';
import { Link } from 'react-router-dom';
import {
  useDashboardBestSellingItems,
  useExportBestSellingItemsChart,
} from 'hooks/dashboard/dashboard';
import NailzyImage from 'components/common/NailzyImage';
import { textTruncate } from 'helpers/utils';
import ReportRangeSelect, {
  ReportFilter,
} from 'pages/affiliates/ReportRangeSelect';
import PromotionSelect from 'components/MantineUI/Selects/PromotionSelect';
import { usePaginationParams } from 'hooks/usePaginationParams';
import { toast } from 'react-toastify';
import IconFA from 'components/common/IconFA';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { useUserProfile } from 'apis/AuthApis';

const BestSellingItems = () => {
  // ### CONSTANTs
  const { t } = useTranslation();
  const title = t('analytics.best_selling_items');
  const subtitle = t('analytics.best_selling_items_desc');
  const { isAdmin } = useUserProfile();
  // ### FUNCTIONs
  const { page, limit, onPaginationChange } = usePaginationParams();
  const [filter, setFilter] = useState<ReportFilter>();
  const [selectedProm, setProm] = useState<any>();
  const { from, to } = filter || {};
  // ### RENDERs
  const { data, isFetching } = useDashboardBestSellingItems({
    from: from,
    to: to,
    page,
    limit,
    enabled: !!from && !!to,
    promotion_id: selectedProm?.value || undefined,
  });
  const items = (data as any)?.data?.results || [];
  const total = (data as any)?.data?.total || 0;

  // export
  const { mutate: exportBestSellingItems, isPending: exporting } =
    useExportBestSellingItemsChart();

  const onExportClick = () => {
    exportBestSellingItems(
      {
        from: from,
        to: to,
        page,
        limit,
        promotion_id: selectedProm?.value || undefined,
      } as any,
      {
        onSuccess(res: any) {
          if (res?.status) {
            window.open(res?.message, '_blank');
            toast.success(t('general.export_success'));
          } else {
            toast.error(t('general.export_fail'));
          }
        },
      },
    );
  };

  const columns: CommonTableColumn[] = [
    {
      accessor: 'name',
      Header: t('products.product'),
      Cell: (row) => {
        const { variants, name, image_url, _id } = row;
        return (
          <div className="d-flex flex-row items-center">
            <NailzyImage
              style={{
                height: 50,
                width: 50,
                marginRight: 10,
              }}
              className={'border rounded'}
              image_url={image_url}
            />
            <div>
              {name ? (
                <Anchor component={Link} size="sm" to={`${'/products/' + _id}`}>
                  {textTruncate(name) || '--'}
                </Anchor>
              ) : (
                '--'
              )}

              <Text size="xs">{textTruncate(variants?.[0]?.name) || '--'}</Text>
              {variants?.[0]?.sku ? (
                <Text size="xs">SKU: {variants?.[0]?.sku}</Text>
              ) : (
                ''
              )}
            </div>
          </div>
        );
      },
    },
    {
      accessor: 'sold',
      Header: t('products.sold'),
      Cell: (row) => {
        const { number_sold } = row;
        return number_sold || 0;
      },
    },
    {
      accessor: 'store_name',
      Header: t('products.store'),
      Cell: (row) => {
        const { store_name } = row;
        return store_name || '--';
      },
    },
    // {
    //   accessor: 'unit_price',
    //   Header: t('orders.unit_price'),
    //   Cell: (row) => {
    //     const { variants } = row;
    //     return (
    //       <div>
    //         {currencyFormatter.format(variants?.[0]?.selling_price || 0)}
    //       </div>
    //     );
    //   },
    // },
  ];
  return (
    <PageLayout
      title={title}
      breadCrumds={[
        {
          title: t('analytics.reports'),
          href: '/reports',
        },
        { title },
      ]}
    >
      <SectionWrapper>
        <SectionHeader title={title} subtitle={subtitle}></SectionHeader>
        <SectionBody>
          <Grid className="my-3">
            <Grid.Col span={{ base: 12, lg: 8 }}>
              <ReportRangeSelect value={filter} onChange={setFilter} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 4 }}>
              <Flex gap={'sm'} className="flex-1" justify={'end'}>
                {isAdmin ? (
                  <PromotionSelect
                    className="flex-1"
                    placeholder={t('analytics.select_promotion')}
                    label=""
                    value={selectedProm}
                    onChange={setProm}
                  />
                ) : null}
                <Button
                  loading={exporting}
                  onClick={onExportClick}
                  variant="subtle"
                  leftSection={<IconFA icon={faDownload} />}
                >
                  {t('general.export')}
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
          <CommonTable
            loading={isFetching}
            pagination={{
              page,
              limit,
              onPaginationChange,
              total,
            }}
            columns={columns}
            data={items}
          />
        </SectionBody>
      </SectionWrapper>
    </PageLayout>
  );
};
export default BestSellingItems;
