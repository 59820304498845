import {
  Button,
  Grid,
  Modal,
  NativeSelect,
  PasswordInput,
  TextInput,
} from '@mantine/core';
import { STATUS_INFO, StatusEnum, SystemUserItem } from 'types';
import { useEffect } from 'react';
import { t } from 'i18next';
import { SectionBody, SectionFooter } from 'components/MantineUI/CommonSection';
import { useForm, yupResolver } from '@mantine/form';
import PhoneInput from 'components/MantineUI/Inputs/PhoneInput';
import AddressInput from 'components/MantineUI/Inputs/AddressInput';
import {
  useCreateSystemUser,
  useSystemUserDetails,
  useUpdateSystemUser,
} from 'apis/UserApis';
import { systemUserSchema } from './system-user-schema';
import validator from 'validator';
import SingleSelect from 'components/MantineUI/Selects/SingleSelect';
import { useCreatePartner, useUpdatePartner } from 'apis/partners';
import StatusSelect from 'components/MantineUI/Selects/StatusSelect';
type PropType = {
  data?: SystemUserItem;
  opened: boolean;
  isPartner?: boolean;
  onClose: () => void;
};
const UserDetailsModal = (props: PropType) => {
  // ### CONSTANTs
  const { data, opened, onClose, isPartner } = props;
  const isEdit = !!data?._id;
  const form = useForm({
    initialValues: {
      phone_number: '',
    } as SystemUserItem,
    validate: yupResolver(systemUserSchema(isEdit)),
  });
  const { data: userData, isLoading }: any = useSystemUserDetails(data?._id);
  const { mutate: createUser, isPending: creating } = useCreateSystemUser();
  const { mutate: updateUser, isPending: updating } = useUpdateSystemUser();
  // partner
  const { mutate: createPartner, isPending: creatingP } = useCreatePartner();
  const { mutate: updatePartner, isPending: updatingP } = useUpdatePartner();
  const user = userData?.data;
  const { getValues, getInputProps, setValues, onSubmit, errors } = form;
  const values = getValues();
  // ### FUNCTIONs
  useEffect(() => {
    if (data?._id && user) {
      setValues({
        first_name: user?.first_name,
        last_name: user?.last_name,
        address: user?.address,
        email: user?.email,
        permission_id: user?.permission?._id,
        permission_name: user?.permission?.name,
        phone_number: user?.phone_number,
        status: user?.status,
      });
    } else {
      setValues({
        first_name: undefined,
        last_name: undefined,
        address: undefined,
        email: undefined,
        password: undefined,
        confirm_password: undefined,
        permission_id: undefined,
        permission_name: undefined,
        phone_number: undefined,
        status: StatusEnum.ACTIVE,
      });
    }
  }, [data?._id, user]);

  const handleSubmit = (values: any) => {
    const isMobilePhone = validator.isMobilePhone(
      values?.phone_number || '',
      undefined,
      {
        strictMode: false,
      },
    );
    const params: SystemUserItem = {
      ...values,
      _id: isEdit ? data?._id : undefined,
      full_name: undefined,
      permission_name: undefined,
      phone_number: isMobilePhone
        ? values?.phone_number
        : isEdit
          ? null
          : undefined,
    };
    delete params?.address?._id;
    delete params?.confirm_password;

    if (isEdit) {
      if (isPartner) {
        updatePartner(params, {
          onSuccess: () => {
            onClose();
          },
        });
      } else {
        // system user
        updateUser(params, {
          onSuccess: () => {
            onClose();
          },
        });
      }
    } else {
      if (isPartner) {
        createPartner(params, {
          onSuccess: () => {
            onClose();
          },
        });
      } else {
        // system user
        createUser(params, {
          onSuccess: () => {
            onClose();
          },
        });
      }
    }
  };

  const title = isEdit
    ? isPartner
      ? t('partners.edit_partner')
      : t('system_users.edit_system_user')
    : isPartner
      ? t('partners.add_partner')
      : t('system_users.add_system_user');
  // ### RENDERs
  return (
    <Modal size={'xl'} centered opened={opened} onClose={onClose} title={title}>
      <form onSubmit={onSubmit(handleSubmit)}>
        <SectionBody>
          <Grid>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <SingleSelect
                route="admin/permissions"
                optionTransformer={(data) =>
                  data?.results?.map((x: any) => ({
                    label: x?.name,
                    value: x?._id,
                  }))
                }
                required
                label={t('filter.role')}
                placeholder={t('filter.role')}
                selected={{
                  value: values?.permission_id || '',
                  label: values?.permission_name || '',
                }}
                onChange={(value) => {
                  setValues({
                    permission_id: value?.value,
                    permission_name: value?.label,
                  });
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <StatusSelect
                hideDraft
                hidePending={false}
                value={values?.status}
                onChange={(e) => {
                  setValues({
                    status: e.target.value as StatusEnum,
                  });
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <TextInput
                required
                label={t('register.form.first_name')}
                {...getInputProps('first_name')}
                placeholder="John"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <TextInput
                required
                label={t('register.form.last_name')}
                {...getInputProps('last_name')}
                placeholder="Doe"
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <TextInput
                required
                label={t('register.form.email')}
                {...getInputProps('email')}
                placeholder="john@gmail.com"
              />
            </Grid.Col>

            <Grid.Col span={{ base: 12, sm: 6 }}>
              <PhoneInput
                label={t('register.form.mobile')}
                placeholder="+1 (432) 432-4324"
                defaultValue={
                  isEdit && user?.phone_number ? user?.phone_number : undefined
                }
                error={errors?.phone_number}
                // value={values?.phone_number}
                onChangeText={(phone_number) =>
                  setValues({ phone_number: phone_number })
                }
                //   error={errors['phoneNumber'] && 'Invalid phone number'}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12 }}>
              <AddressInput
                address={values?.address}
                label={t('register.form.address')}
                onChange={(address) => {
                  form.setValues({ address });
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <PasswordInput
                required={!isEdit}
                autoComplete="new-password"
                label={t('register.form.password')}
                {...getInputProps('password')}
                placeholder={t('register.form.new_password')}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 6 }}>
              <PasswordInput
                required={!isEdit}
                autoComplete="new-password"
                label={t('register.form.confirm_password')}
                {...getInputProps('confirm_password')}
                placeholder={t('register.form.confirm_password')}
              />
            </Grid.Col>
          </Grid>
        </SectionBody>
        <SectionFooter updatedTime={data?.updated_on}>
          <Button
            loading={creating || updating || creatingP || updatingP}
            type="submit"
          >
            {t('general.save')}
          </Button>
          <Button onClick={onClose} variant="default">
            {t('general.cancel')}
          </Button>
        </SectionFooter>
      </form>
    </Modal>
  );
};
export default UserDetailsModal;
