import { Anchor, Button, Flex } from '@mantine/core';
import { CommonTableColumn, InquiryAdminSort, KeyLabelPair } from 'types';
import { useDeleteInquiry, useInquiriesList } from 'apis/Inquiries';
import FilterButton from 'components/MantineUI/Buttons/FilterButton';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { CommonTable, TableSearchBox } from 'components/MantineUI/CommonTable';
import SortButton from 'components/MantineUI/CommonTable/SortButton';
import PageLayout from 'components/MantineUI/PageLayout';
import { t, use } from 'i18next';
import { ReactNode, useState } from 'react';
import { InquiryItem } from 'types';
import { Link } from 'react-router-dom';
import { dateFormatter, phoneFormatter } from 'helpers/utils';
import StatusBadge from 'components/MantineUI/StatusBadge';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';
import IconFA from 'components/common/IconFA';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { useURLParams } from 'hooks/useURLParams';
import { usePaginationParams } from 'hooks/usePaginationParams';
import CommonDrawer from 'components/MantineUI/CommonDrawer';
import InquiryFilter from './InquiryFilter';
import InquiryModals from './InquiryModals';
import SkeletonWrapper from 'components/MantineUI/Skeleton/SkeletonWrapper';
import SectionPageSkeleton from 'components/MantineUI/Skeleton/SectionPageSkeleton';
import DeleteWarningModal from 'components/common/DeleteWarningModal';
import { useUserProfile } from 'apis/AuthApis';

type PropType = {
  children?: ReactNode;
  className?: string;
};

const InquiriesPage = (props: PropType) => {
  // ### CONSTANTs
  const title = t('inquiries.inquiries');
  const { params }: any = useURLParams();
  const { page, limit, onPaginationChange } = usePaginationParams();
  const [showFilter, setShowFilter] = useState(false);
  const [deleteInquiry, setDeleteInquiry] = useState<InquiryItem | undefined>();
  const { mutate: deleteInq, isPending: deleting } = useDeleteInquiry();
  const [seletedInquiry, setSelectedInquiry] = useState<
    InquiryItem | undefined
  >();
  const { data, isLoading, isFetching } = useInquiriesList({
    page,
    limit,
    ...params,
    product_name: undefined,
  });
  const SortOptions: KeyLabelPair[] = [
    {
      key: InquiryAdminSort.CREATED_ON_DESC,
      label: t('general.created_desc'),
    },
    {
      key: InquiryAdminSort.CREATED_ON_ASC,
      label: t('general.created_asc'),
    },
    {
      key: InquiryAdminSort.UPDATED_ON_DESC,
      label: t('general.updated_desc'),
    },
    {
      key: InquiryAdminSort.UPDATED_ON_ASC,
      label: t('general.updated_asc'),
    },
    {
      key: InquiryAdminSort.FIRST_NAME_ASC,
      label: t('general.first_name_asc'),
    },
    {
      key: InquiryAdminSort.FIRST_NAME_DESC,
      label: t('general.first_name_desc'),
    },
    {
      key: InquiryAdminSort.BUSINESS_NAME_ASC,
      label: t('general.business_name_asc'),
    },
    {
      key: InquiryAdminSort.BUSINESS_NAME_DESC,
      label: t('general.business_name_desc'),
    },
  ];

  const { isAdmin } = useUserProfile();

  // ### FUNCTIONs
  const onDelete = () => {
    if (deleteInquiry?._id) {
      deleteInq(deleteInquiry?._id, {
        onSuccess: () => setDeleteInquiry(undefined),
      });
    }
  };
  // ### RENDERs
  const columns: CommonTableColumn[] = [
    {
      accessor: 'name',
      Header: t('orders.item'),
      Cell: (row: InquiryItem) => {
        const isValid = !!row?.items?.[0]?.product_name;
        return isValid ? (
          <Anchor
            size="sm"
            component={Link}
            to={'/products/' + row?.items?.[0]?.product_id}
          >
            {row?.items?.[0]?.product_name} - {row?.items?.[0]?.variant_name}
          </Anchor>
        ) : (
          '--'
        );
      },
    },
    {
      accessor: 'customers',
      Header: t('orders.customer'),
      Cell: (row: InquiryItem) => {
        const isValid = !!(row?.first_name || row?.last_name);
        const name = isValid
          ? `${row?.first_name || ''} ${row?.last_name || ''}`
          : '--';
        return isValid && isAdmin ? (
          <Anchor
            size="sm"
            component={Link}
            to={'/customers/' + row?.customer_id}
          >
            {name}
          </Anchor>
        ) : (
          name
        );
      },
    },
    {
      accessor: 'business_name',
      Header: t('customer.business_name'),
      Cell: (row: InquiryItem) => {
        return row?.business_name || '--';
      },
    },
    {
      accessor: 'phone_number',
      Header: t('general.phone'),
      Cell: (row: InquiryItem) => {
        return row?.phone_number ? phoneFormatter(row?.phone_number) : '--';
      },
    },
    {
      accessor: 'email',
      Header: t('register.form.email'),
      Cell: (row: InquiryItem) => {
        return row?.email || '--';
      },
    },
    {
      accessor: 'message',
      Header: t('push_notifications.message'),
      Cell: (row: InquiryItem) => {
        return row?.message || '--';
      },
    },
    {
      accessor: 'craeted_at',
      Header: t('general.created_date'),
      Cell: (row: InquiryItem) => {
        return row?.created_on ? dateFormatter(row?.created_on) : '--';
      },
    },
    {
      accessor: 'status',
      Header: t('general.status'),
      Cell: (row: InquiryItem) => {
        return <StatusBadge fullWidth status={row?.status} />;
      },
    },
    {
      accessor: 'actions',
      Cell: (row: InquiryItem) => (
        <DropdownButton
          button={
            <Button size="xs" variant="subtle" color="gray">
              <IconFA icon={faEllipsis} />
            </Button>
          }
          dropdownData={[
            {
              key: 'edit',
              label: t('general.edit'),
              onClick: () => setSelectedInquiry(row),
            },
            { isDivider: true },
            {
              key: 'delete',
              label: t('general.delete'),
              color: 'red',
              onClick: () => setDeleteInquiry(row),
            },
          ]}
        />
      ),
    },
  ];
  return (
    <SkeletonWrapper skeleton={<SectionPageSkeleton />} show={isLoading}>
      <PageLayout title={title} breadCrumds={[{ title }]}>
        <SectionWrapper>
          <SectionHeader
            title={title}
            subtitle={t('inquiries.inquiries_decs')}
          />
          <SectionBody>
            <Flex gap={'sm'} mb={'md'} wrap={'wrap'} align={'center'}>
              <TableSearchBox searchParams="search" />
              <FilterButton onClick={() => setShowFilter(true)} />
              <SortButton sortOptions={SortOptions} />
            </Flex>
            <CommonTable
              loading={isFetching}
              columns={columns}
              data={data?.data?.results || []}
              pagination={{
                page,
                limit,
                onPaginationChange,
                total: data?.data?.total || 0,
              }}
            />
          </SectionBody>
          <CommonDrawer
            title={t('filter.filters')}
            opened={showFilter}
            onClose={() => setShowFilter(false)}
          >
            <InquiryFilter onClose={() => setShowFilter(false)} />
          </CommonDrawer>
          <InquiryModals
            opened={!!seletedInquiry}
            onClose={() => setSelectedInquiry(undefined)}
            data={seletedInquiry}
          />

          <DeleteWarningModal
            name={deleteInquiry?.items?.[0]?.product_name}
            onShow={!!deleteInquiry}
            onClose={() => setDeleteInquiry(undefined)}
            onDelete={onDelete}
            isLoading={deleting}
          />
        </SectionWrapper>
      </PageLayout>
    </SkeletonWrapper>
  );
};
export default InquiriesPage;
