import FalconCloseButton from 'components/common/FalconCloseButton';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../components/common/buttons/LoadingButton';
import IconFA from 'components/common/IconFA';
import { Button, Modal, Text, Textarea } from '@mantine/core';
import { SectionFooter } from 'components/MantineUI/CommonSection';

/**
 * @param {bool} remove A boolean value to determine if the modal is for removing or deleting an item.
 * @param {string} name The name of the item to be deleted.
 * @param {bool} onShow A boolean value to detemine if the modal should be shown.
 * @param {func} onClose A function handle closing modal.
 * @param {func} onDelete A function handle deleting item.
 * @param {boolean} isLoading A boolean to determine if action is executing
 * @returns
 */
const DeleteCustomerModal = (
  {
    remove,
    name = 'this item',
    onShow,
    onClose: onModalClose,
    onDelete,
    isLoading = false,
    title,
  },
  ref,
) => {
  const { t } = useTranslation();
  const [internalShow, setInternalShow] = useState(false);
  const [product, setProduct] = useState(null);
  const [reason, setReason] = useState('');
  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));
  const show = (product) => {
    setInternalShow(true);
    setProduct(product);
  };
  const hide = (product) => {
    setInternalShow(false);
  };
  const action = (() => {
    if (remove && isLoading) return t('general.removing');
    if (remove && !isLoading) return t('general.remove');
    if (!remove && isLoading) return t('general.deleting');
    if (!remove && !isLoading) return t('general.delete');
  })();
  const onClose = () => {
    setInternalShow(false);
    onModalClose?.();
  };
  return (
    <Modal
      opened={internalShow || onShow}
      onClose={onClose}
      centered
      title={
        title ? (
          <Text fw={600} size="lg">
            {title}
          </Text>
        ) : (
          <Text fw={600} size="lg">
            {remove ? t('general.remove') : t('general.delete')}{' '}
            {product?.name || name}?
          </Text>
        )
      }
    >
      <Text c={'red'}>{t('customer.delete_customer_note')}</Text>
      <Textarea
        label={t('customer.delete_customer_reason')}
        rows={3}
        value={reason}
        onChange={(e) => setReason(e?.target?.value)}
      />

      <SectionFooter>
        <Button
          color="red"
          disabled={!reason}
          loading={isLoading}
          onClick={() => {
            onDelete?.(product, reason);
            setReason('');
          }}
        >
          {action}
        </Button>
        <Button variant="default" onClick={onClose}>
          {t('general.cancel')}
        </Button>
      </SectionFooter>
    </Modal>
  );
};

export default forwardRef(DeleteCustomerModal);
