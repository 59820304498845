import { Button, Modal } from '@mantine/core';
import { t } from 'i18next';
import { SectionBody, SectionFooter } from '../CommonSection';
type PropType = {
  opened: boolean;
  onClose: () => void;
  onOK?: () => void;
  message: string;
  title?: string;
  cancelText?: string;
  okText?: string;
  danger?: boolean;
};
const ConfirmModal = (props: PropType) => {
  // ### CONSTANTs
  const {
    opened = false,
    onClose,
    onOK,
    message = '',
    title = '',
    okText,
    cancelText,
    danger,
  } = props;
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <Modal size={'lg'} centered onClose={onClose} opened={opened} title={title}>
      <SectionBody>{message}</SectionBody>
      <SectionFooter className="mt-3">
        <Button
          color={danger ? 'red' : 'primary'}
          onClick={() => {
            onClose();
            onOK?.();
          }}
        >
          {okText || 'OK'}
        </Button>
        <Button variant="default" onClick={() => onClose()}>
          {cancelText || t('general.cancel')}
        </Button>
      </SectionFooter>
    </Modal>
  );
};
export default ConfirmModal;
