import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import IconFA from 'components/common/IconFA';
// import ProductItem from './ProductItem';
import CheckBox from 'components/common/checkbox';
import { useAsyncDebounce } from 'react-table';
import { useAdminProductsMarketplace } from 'apis/DraftCartApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Modal, TextInput } from '@mantine/core';
import { SectionBody, SectionFooter } from 'components/MantineUI/CommonSection';
import CategorySelect from 'components/MantineUI/Selects/CategorySelect';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import BrandSelect from 'components/MantineUI/Selects/BrandSelect';
import SellerStoreSelect from 'components/MantineUI/Selects/SellerStoreSelect';
import ProductItem from './ProductItem';
import { Pagination } from 'components/MantineUI/CommonTable';
import LoadingOverlayWrapper from 'components/MantineUI/LoadingOverlayWrapper';

type Props = {
  onHide: () => void;
  visible: boolean;
  onSave: (products: any) => void;
  selectedProducts: any;
};

const SelectProductModal = ({
  onHide,
  visible,
  onSave,
  selectedProducts: selectedProductsProps,
}: Props) => {
  const LIMIT = 10;
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState<any>({ page: 1, limit: LIMIT });
  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const { page, limit } = filters || {};
  useEffect(() => {
    setSelectedProducts(selectedProductsProps || []);
  }, [selectedProductsProps]);
  // PRODUCT
  const customerFilter = useMemo(
    () => ({
      page: filters?.page || 1,
      limit: filters?.limit || LIMIT,
      search: filters?.name || undefined,
      category_ids: filters?.category_id ? [filters?.category_id] : undefined,
      seller_ids: filters?.seller_id ? [filters?.seller_id] : undefined,
      brand_ids: filters?.brand_id ? [filters?.brand_id] : undefined,
    }),
    [filters],
  );
  const { products, loading, total } = useAdminProductsMarketplace(
    customerFilter as any,
    visible,
  );
  // useAdminProductsMarketplace(
  //   {
  //     ...customerFilter,
  //     page: filters?.page + 1,
  //   },
  //   visible,
  // );
  // useAdminProductsMarketplace(
  //   {
  //     ...customerFilter,
  //     page: filters?.page + 2,
  //   },
  //   visible,
  // );

  useEffect(() => {
    debounceSearch(search);
  }, [search]);
  const debounceSearch = useAsyncDebounce((value) => {
    setFilters({ ...filters, name: search || undefined });
  }, 500);

  const onClickSave = () => {
    onSave?.(selectedProducts);
    onHide?.();
  };

  const onSelect = (product: any, checked = true) => {
    let array = [...selectedProducts];
    array = array?.filter((x) => x?._id !== product?._id);
    if (checked && product?.variants?.length) {
      array.push(product);
    }
    setSelectedProducts(array);
  };
  const onSelectAll = (checked: boolean) => {
    let array = [...selectedProducts];

    for (let i = 0; i < products.length; i++) {
      const prod = products[i];
      array = array?.filter((x) => x?._id !== prod?._id);
      if (checked) {
        array.push({
          ...prod,
          variants: (prod?.variants || [])?.filter(
            (x: any) => x?.available_stock > 0,
          ),
        });
      }
    }
    setSelectedProducts(array);
  };
  const isSelectedAll = useMemo(() => {
    if (selectedProducts?.length >= products?.length) {
      let array = [...selectedProducts];
      array = array?.filter((x) => !!products?.find((y) => x?._id === y?._id));
      if (array?.length >= products?.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [products, selectedProducts]);
  const totalItems = useMemo(() => {
    return selectedProducts?.reduce(
      (p: any, i: any) => p + (i?.variants?.length || 0),
      0,
    );
  }, [selectedProducts]);
  return (
    <Modal
      title={t('promotions.select_products')}
      opened={visible}
      onClose={onHide}
      centered
      size={'90%'}
    >
      <SectionBody>
        <div>
          <Grid className="mb-3">
            <Grid.Col span={{ base: 3 }}>
              <TextInput
                placeholder={t('general.search')}
                leftSection={<IconFA icon={faSearch} size="xs" />}
                label={t('promotions.find_by_name')}
                value={search}
                onChange={(e) => {
                  setSearch(e?.target?.value);
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 3 }}>
              <CategorySelect
                value={{
                  value: filters?.category_id,
                  label: filters?.category_name,
                }}
                label={t('promotions.find_by_cate')}
                onChange={(cate: any) => {
                  setFilters((prev: any) => ({
                    ...prev,
                    category_id: cate?.value,
                    category_name: cate?.label,
                    page: 1,
                  }));
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 3 }}>
              <BrandSelect
                value={{
                  value: filters?.brand_id,
                  label: filters?.brand_name,
                }}
                label={t('promotions.find_by_brand')}
                onChange={(brand) => {
                  setFilters((prev: any) => ({
                    ...prev,
                    brand_id: brand?.value,
                    brand_name: brand?.label,
                    page: 1,
                  }));
                }}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 3 }}>
              <SellerStoreSelect
                label={t('promotions.find_by_seller')}
                value={{
                  value: filters?.seller_id,
                  label: filters?.seller_name,
                }}
                onChange={(seller) => {
                  setFilters((prev: any) => ({
                    ...prev,
                    seller_id: seller?.value,
                    seller_name: seller?.label,
                    page: 1,
                  }));
                }}
              />
            </Grid.Col>
          </Grid>
        </div>

        <div className="px-3">
          <Grid className="bg-100 py-1">
            <Grid.Col span={{ base: 6 }}>
              <div className="d-flex flex-row gap-3">
                <CheckBox
                  checked={isSelectedAll}
                  onChange={(checked) => onSelectAll(checked)}
                />
                <div className="fw-semi-bold fs-0">{t('orders.product')}</div>
              </div>
            </Grid.Col>
            <Grid.Col span={{ base: 2 }}>
              <div className="fw-semi-bold fs-0">{t('orders.sku')}</div>
            </Grid.Col>
            <Grid.Col span={{ base: 2 }}>
              <div className="fw-semi-bold fs-0">
                {t('promotions.available_stock')}
              </div>
            </Grid.Col>
            <Grid.Col span={{ base: 2 }}>
              <div className="fw-semi-bold fs-0">{t('products.price')}</div>
            </Grid.Col>
          </Grid>
        </div>
        <LoadingOverlayWrapper loading={loading}>
          <div style={{ height: '500px', overflowY: 'auto' }}>
            {loading ? (
              <div className="d-flex w-100 justify-content-center my-4">
                <FontAwesomeIcon
                  spin
                  icon="spinner"
                  size="2x"
                  className="text-primary"
                />
              </div>
            ) : null}
            {products?.length ? (
              products?.map((x, i) => {
                return (
                  <ProductItem
                    selectedProducts={selectedProducts}
                    product={x}
                    onChange={onSelect}
                    key={`product_${i}`}
                  />
                );
              })
            ) : loading ? null : (
              <div className="h5 m-3 text-center">{t('general.no_data')}</div>
            )}
          </div>
        </LoadingOverlayWrapper>

        {total > limit ? (
          <div className="border-top mt-3 pt-3">
            <Pagination
              className="mb-3"
              hideLimit
              pagination={{
                limit,
                page,
                total,
                onPaginationChange: (page, limit) => {
                  setFilters((prev: any) => ({ ...prev, page, limit }));
                },
              }}
            />
            {/* <AdvanceTableFooter
              rowCount={total}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
              pageSize={limit}
              perPage={limit}
              pageIndex={page - 1}
              page={{ length: limit }}
              canNextPage={page * limit < total}
              canPreviousPage={page > 1}
              setPageSize={(l) => setFilters((prev) => ({ ...prev, limit: l }))}
              nextPage={() =>
                setFilters((prev) => ({ ...prev, page: page + 1 }))
              }
              previousPage={() =>
                setFilters((prev) => ({ ...prev, page: page - 1 }))
              }
              nextText={t('general.next')}
              previousText={t('general.previous')}
            /> */}
          </div>
        ) : null}
      </SectionBody>

      <SectionFooter>
        <Button variant="primary" disabled={!totalItems} onClick={onClickSave}>
          {t('orders.select_items', { total: totalItems })}
        </Button>
        <Button variant="default" onClick={onHide}>
          {t('general.cancel')}
        </Button>
      </SectionFooter>
    </Modal>
  );
};
export default SelectProductModal;
