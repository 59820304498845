import { AddressInfo, StatusEnum } from './common.type';
import { CustomerItem } from './customer.type';
import { CommonTableColumn } from './table.type';
import { UserInfo } from './user.type';

export type OrderItemType = {
  _id: string;
  seller_id: string;
  order_transaction: {
    _id: string;
    order_codes: string[];
  };
  customer: {
    _id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
  };
  order_status: OrderStatusEnum;
  payment_status: OrderPaymentStatusEnum;
  payment_method: 'stripe' | string;
  code: string;
  note: string;
  cancel_reason: string;
  customer_name: string;
  pricing: {
    items_total: number;
    items_discount: number;
    sub_total: number;
    total: number;
    sales_tax: number;
    shipping: number;
    sales_tax_rates: number;
    items: Array<{
      _id: string;
      product_name: string;
      product_slug: string;
      product_id: string;
      seller_id: string;
      variant_id: string;
      variant_name: string;
      variant_slug: string;
      sku: string;
      barcode: string;
      status: string;
      price: number;
      discount_price: number;
      quantity: number;
      image_urls: string[];
      item_total: number;
      item_discounted: number;
      affiliate_discounted: number;
      promotion: {
        _id: string;
        name: string;
        is_new_user: boolean;
        max_purchase_product: number;
        is_max_purchase_product: boolean;
      };
      is_promotion_limit_available: boolean;
    }>;
    shipping_rate: {
      amount: number;
      provider: string;
      estimated_days: number;
      servicelevel_name: string;
    };
    coupons: Array<{
      amount: number;
      discount_amount: number;
      provider: 'nailzy' | 'seller' | 'pos';
      code: string;
    }>;
    seller: {
      _id: string;
      store_name: string;
      fulfill_type: OrderFulfillTypeEnum;
    };
    coupon_discount_amount: number;
  };
  shipping_address: AddressInfo;
  created_on: number;
  shipment: {
    type: SHIPMENT_TYPE;
    shippo_track: {
      tracking_number: string;
      carrier: string;
      servicelevel: {
        name: string;
        token: string;
      };
      tracking_status: {
        status_date: string;
        status_details: string;
        location: any;
        substatus: {
          action_required: boolean;
          code: string;
          text: string;
        };
        object_created: string;
        object_updated: string;
        object_id: string;
        status: ShippingStatus;
      };
    };
    shippo_rate: {
      amount: number;
      provider: 'nailzy' | string;
      estimated_days: number;
      servicelevel_name: string;
    };
    shippo_transaction: ShipmentInfo;
    shippo_transactions: ShipmentInfo[];
    parcels: ShipmentParcel[];
    carrier_id: 'ups' | 'usps' | 'fedex' | 'dhl_express' | 'dhl_ecommerce';
    tracking_number: string;
    note: string;
    created_on: number;
  };
  order_progress: Array<{
    _id: string;
    note: string;
    created_on: number;
    action_type: string;
    attachment_files: Array<{
      _id: string;
      mineType: string;
      type: string;
      name: string;
      url: string;
    }>;
    actor?: {
      _id: string;
      first_name: string;
      last_name: string;
      email: string;
      phone_number: string;
      profile_image_url: string;
    };
    previous_progress: any[];
  }>;
  payment_information: {
    brand: string;
    exp_month: number;
    exp_year: number;
    funding: string;
    last4: string;
    wallet: any;
    paid_amount: number;
    billing_details: {
      address: {
        city: string;
        country: string;
        line1: string;
        line2: string;
        postal_code: string;
        state: string;
      };
      email: string;
      name: string;
      phone: string;
    };
    fraud_details: Record<string, any>;
    outcome: {
      network_status: string;
      seller_message: string;
      risk_score: number;
      risk_level: string;
      reason: string;
      type: string;
    };
    receipt_url: string;
    refunded_amount: number;
    created_on: number;
  };
  updated_on: number;
  total_items: number;
  source_from: OrderSourceEnum;
};

export type OrderListProps = {
  data: OrderItemType[];
  total?: number;
  page?: number;
  limit?: number;
  customColumn?: CommonTableColumn[];
  hideHeader?: boolean;
  hideSort?: boolean;
  hideFilter?: boolean;
  hideCard?: boolean;
};

export enum OrderPaymentStatusEnum {
  ALL = '',
  UNPAID = 'unpaid',
  PAID = 'paid',
  REFUNDED = 'refunded',
  AWAITING_PAYMENT = 'awaiting_payment',
  PAYMENT_FAILED = 'payment_failed',
}

export enum OrderStatusEnum {
  ALL = 'all',
  PENDING = 'pending',
  PROCESSING = 'processing',
  SHIPPED = 'shipped',
  DELIVERED = 'delivered',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  FAILED_DELIVERY = 'failed_delivery',
}

export type OrderStatusType = {
  [key in OrderStatusEnum]: {
    label: string;
    bg: string;
    icon: string;
  };
};

export const OrderStatuses: OrderStatusType = {
  [OrderStatusEnum.ALL]: {
    label: 'orders.order_all',
    bg: '',
    icon: '',
  },
  [OrderStatusEnum.PENDING]: {
    label: 'orders.order_pending',
    bg: 'warning',
    icon: 'clock',
  },
  [OrderStatusEnum.PROCESSING]: {
    label: 'orders.order_processing',
    bg: 'warning',
    icon: 'rotate',
  },
  [OrderStatusEnum.SHIPPED]: {
    label: 'orders.order_shipped',
    bg: 'info',
    icon: 'truck',
  },
  [OrderStatusEnum.DELIVERED]: {
    label: 'orders.order_delivered',
    bg: 'success',
    icon: 'check-circle',
  },
  [OrderStatusEnum.COMPLETED]: {
    label: 'orders.order_completed',
    bg: 'success',
    icon: 'check-circle',
  },
  [OrderStatusEnum.FAILED_DELIVERY]: {
    label: 'orders.order_failed_delivery',
    bg: 'danger',
    icon: 'triangle-exclamation',
  },
  [OrderStatusEnum.CANCELED]: {
    label: 'orders.order_canceled',
    bg: 'gray',
    icon: 'ban',
  },
};

export const OrderTabStatuses = {
  [OrderStatusEnum.ALL]: {
    label: 'orders.order_all',
  },
  [OrderPaymentStatusEnum.AWAITING_PAYMENT]: {
    label: 'orders.awaiting_payment',
  },
  [OrderStatusEnum.PENDING]: {
    label: 'orders.order_pending',
  },
  [OrderStatusEnum.PROCESSING]: {
    label: 'orders.order_processing',
  },
  [OrderStatusEnum.SHIPPED]: {
    label: 'orders.order_shipped',
  },
  [OrderStatusEnum.DELIVERED]: {
    label: 'orders.order_delivered',
  },
  [OrderStatusEnum.COMPLETED]: {
    label: 'orders.order_completed',
  },
  [OrderStatusEnum.FAILED_DELIVERY]: {
    label: 'orders.order_failed_delivery',
  },
  [OrderStatusEnum.CANCELED]: {
    label: 'orders.order_canceled',
  },
};

export enum OrderSourceEnum {
  WEB = 'web',
  APP = 'app',
  ADMIN = 'admin',
}
export enum OrderFulfillTypeEnum {
  NAILZY = 'nailzy',
  SELLER = 'seller',
}

export enum CartStatusEnum {
  CLOSED = 'closed',
  DRAFT = 'draft',
  COMPLETED = 'completed',
}

export type CartStatusType = {
  [key in CartStatusEnum]: {
    label: string;
    bg: string;
    icon: string;
  };
};

export const CartStatuses: CartStatusType = {
  [CartStatusEnum.CLOSED]: {
    icon: 'archive',
    bg: 'gray',
    label: 'general.closed',
  },
  [CartStatusEnum.DRAFT]: {
    icon: 'save',
    bg: 'info',
    label: 'general.draft',
  },
  [CartStatusEnum.COMPLETED]: {
    icon: 'check-circle',
    bg: 'success',
    label: 'general.completed',
  },
};

export enum ShippingStatus {
  UNKNOWN = 'UNKNOWN',
  PRE_TRANSIT = 'PRE_TRANSIT',
  TRANSIT = 'TRANSIT',
  DELIVERED = 'DELIVERED',
  RETURNED = 'RETURNED',
  FAILURE = 'FAILURE',
}

export type DraftCartItem = {
  _id?: string;
  created_on: number;
  customer: CustomerItem;
  total_items: number;
  created_by: UserInfo;
  total_amount: number;
  status: StatusEnum | OrderStatusEnum;
};

export enum ShippingErrorType {
  NotSupportedYourAddress = 'not_supported_your_address',
  NotHaveDefaultPackage = 'not_have_default_package',
  DefaultPackageInvalid = 'default_package_invalid',
}
export enum ResendOrderNotificationType {
  ORDER = 'order',
  ORDER_TRANSACTION = 'order_transaction',
}

export const ActionTypeEnum = {
  ChangeStatus: 'change_status',
  AddNote: 'add_note',
  ChangeOrderNote: 'change_order_note',
  ChangeShippingAddress: 'change_shipping_address',
};

export const PaymentStatuses = {
  [OrderPaymentStatusEnum.ALL]: {
    label: 'orders.order_all',
    bg: '',
  },
  [OrderPaymentStatusEnum.UNPAID]: {
    label: 'orders.unpaid',
    bg: 'gray',
  },
  [OrderPaymentStatusEnum.PAID]: {
    label: 'orders.paid',
    bg: 'success',
  },
  [OrderPaymentStatusEnum.REFUNDED]: {
    label: 'orders.refunded',
    bg: 'info',
  },
  [OrderPaymentStatusEnum.AWAITING_PAYMENT]: {
    label: 'orders.awaiting_payment',
    bg: 'danger',
  },
  [OrderPaymentStatusEnum.PAYMENT_FAILED]: {
    label: 'orders.payment_failed',
    bg: 'danger',
  },
};

export enum SHIPMENT_TYPE {
  MANUAL = 'manual',
  SHIPPO = 'shippo',
  CUSTOM = 'custom',
}

export const SHIPMENT_TYPE_LABEL = {
  MANUAL: 'orders.shipment_manual',
  SHIPPO: 'orders.shipment_shippo',
  CUSTOM: 'orders.shipment_custom',
};

export type CancelReasonType = {
  out_of_stock: string;
  customer_request: string;
  payment_error: string;
  other: string;
  abandoned_checkout: string;
  wrong_address: string;
  expensive_shipping: string;
  long_shipping: string;
  duplicate_order: string;
  change_of_mind: string;
  order_mistake: string;
  better_price: string;
  forgot_coupon: string;
  payment_issue: string;
  bad_reviews: string;
};

export const CancelReason: CancelReasonType = {
  out_of_stock: 'orders.out_of_stock',
  customer_request: 'orders.customer_request',
  payment_error: 'orders.payment_error',
  other: 'orders.other',
  abandoned_checkout: 'orders.abandoned_checkout',
  wrong_address: 'orders.wrong_address',
  expensive_shipping: 'orders.expensive_shipping',
  long_shipping: 'orders.long_shipping',
  duplicate_order: 'orders.duplicate_order',
  change_of_mind: 'orders.change_of_mind',
  order_mistake: 'orders.order_mistake',
  better_price: 'orders.better_price',
  forgot_coupon: 'orders.forgot_coupon',
  payment_issue: 'orders.payment_issue',
  bad_reviews: 'orders.bad_reviews',
};

export type ShipmentInfo = {
  tracking_number: string;
  tracking_url_provider: string;
  label_url: string;
  tracking_status: ShippingStatus | any;
  rate: string;
};

export type ShipmentParcel = {
  length: number;
  width: number;
  height: number;
  unit: 'in' | 'ft';
  weight: number;
  weight_unit: 'oz' | 'lb';
};

export type OrderFilterFormType = {
  page?: number;
  limit?: number;
  order_status?: OrderStatusEnum;
  payment_status?: OrderPaymentStatusEnum;
  customer_id?: string;
  customer_name_select?: string;
  promotion_id?: string;
  promotion_name?: string;
  coupon_id?: string;
  coupon_name?: string;
  shipment_type?: SHIPMENT_TYPE;
  source_from?: OrderSourceEnum;
  fulfill_type?: OrderFulfillTypeEnum;
  seller_id?: string;
  seller_name?: string;
  date_range?: any;
  affiliate_program_id?: string;
  affiliate_name?: string;
  is_get_line_item?: boolean;
  is_get_attachment?: boolean;
};
