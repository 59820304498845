import { CommonTableColumn } from 'types';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import PageLayout from 'components/MantineUI/PageLayout';
import { useTranslation } from 'react-i18next';
import { Anchor } from '@mantine/core';
import { CommonTable } from 'components/MantineUI/CommonTable';
import { Link } from 'react-router-dom';

const AnalyticsReports = () => {
  // ### CONSTANTs
  const { t } = useTranslation();
  const title = t('analytics.reports');
  const subtitle = t('analytics.reports_desc');
  // ### FUNCTIONs
  // ### RENDERs
  const data = [
    { name: t('analytics.best_selling_items'), slug: 'best-selling-items' },
    { name: t('analytics.low_stock_inventory'), slug: 'low-stock-items' },
  ];
  const columns: CommonTableColumn[] = [
    {
      accessor: 'name',
      Header: t('general.name'),
      Cell: (row: any) => (
        <Anchor component={Link} to={`/reports/${row.slug}`} size="sm">
          {row.name}
        </Anchor>
      ),
    },
  ];
  return (
    <PageLayout title={title} breadCrumds={[{ title }]}>
      <SectionWrapper>
        <SectionHeader title={title} subtitle={subtitle}></SectionHeader>
        <SectionBody>
          <CommonTable columns={columns} data={data} />
        </SectionBody>
      </SectionWrapper>
    </PageLayout>
  );
};
export default AnalyticsReports;
