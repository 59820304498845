import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { Button, Flex, Grid } from '@mantine/core';
import { useUserProfile } from 'apis/AuthApis';
import IconFA from 'components/common/IconFA';
import dayjs from 'dayjs';
import {
  useDashboardCustomerChart,
  useDashboardTotalChart,
  useExportCustomerChart,
} from 'hooks/dashboard/dashboard';
import ReportItem from 'pages/affiliates/ReportItem';
import ReportRangeSelect, {
  ReportFilter,
} from 'pages/affiliates/ReportRangeSelect';
import MultiCharts from 'pages/dashboard/components/MultiCharts';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const InsightCustomers = () => {
  // ### CONSTANTs
  const { isAdmin, isSeller } = useUserProfile();
  const { t } = useTranslation();
  const [type, setType] = useState<string>('total_customer');
  const [filter, setFilter] = useState<ReportFilter>();
  // ### FUNCTIONs
  useEffect(() => {
    if (isSeller && type === 'total_customer') {
      setType('total_buyer');
    }
  }, [isSeller]);

  const { mutate: exportCustomer, isPending: exporting } =
    useExportCustomerChart();
  const onExport = () =>
    exportCustomer(
      {
        from: filter?.from,
        to: filter?.to,
        group_by: filter?.groupType, // hour , week
      } as any,
      {
        onSuccess: (res: any) => {
          if (res?.status) {
            window.open(res?.message, '_blank');
            toast.success(t('general.export_success'));
          }
        },
        onError: () => {
          toast.error(t('general.export_fail'));
        },
      },
    );
  // ### RENDERs
  const { data: currentTotal }: any = useDashboardTotalChart({
    from: filter?.from,
    to: filter?.to,
  });
  const { data: pastTotal }: any = useDashboardTotalChart({
    from: filter?.pastFrom,
    to: filter?.pastTo,
  });

  // customer
  const { data: customerChart } = useDashboardCustomerChart({
    from: filter?.from,
    to: filter?.to,
    group_by: filter?.groupType, // hour , week
    // type: type,
    enabled: true,
  });
  const { data: pastCustomer } = useDashboardCustomerChart({
    from: filter?.pastFrom,
    to: filter?.pastTo,
    group_by: filter?.groupType,
    // type: type,
    enabled: true,
  });

  const calculateHourChart = (chartData: any, isPast = false) => {
    let array = [];
    for (let i = 0; i < (filter?.duration || 0) + 1; i++) {
      let day = dayjs
        .unix(filter?.from as number)
        ?.clone()
        ?.add(i - (isPast ? filter?.duration || 0 : 0), 'hour')
        .format('YYYY-MM-DD HH');

      let data = chartData?.data?.find((x: any) => x.date === day);
      array.push({ label: day, value: data?.total || 0 });
    }
    return array;
  };

  const calculateWeekChart = (chartData: any, isPast = false) => {
    let array = [];
    let tempWeek = '';
    for (let i = 0; i < (filter?.duration || 0); i++) {
      let week: any = dayjs
        .unix(filter?.from as number)
        ?.clone()
        ?.add(i - (isPast ? filter?.duration || 0 : 0), 'day');
      let day = dayjs
        .unix(filter?.from as number)
        ?.clone()
        ?.add(i - (isPast ? filter?.duration || 0 : 0), 'day')
        .format('YYYY-MM-DD');
      week = `${week?.format('YYYY-MM')} ${week?.week?.()}`;
      let data = chartData?.data?.find((x: any) => x.date === week);
      // tempWeek
      if (week !== tempWeek) {
        array.push({ label: day, value: data?.total || 0 });
        tempWeek = week;
      }
    }
    return array;
  };

  const calculateDayChart = (chartData: any, isPast = false) => {
    let array = [];
    for (let i = 0; i < (filter?.duration || 0); i++) {
      let day = dayjs
        .unix(filter?.from as number)
        ?.clone()
        ?.add(i - (isPast ? filter?.duration || 0 : 0), 'day')
        .format('YYYY-MM-DD');

      let data = chartData?.data?.find((x: any) => x.date === day);
      array.push({ label: day, value: data?.total || 0 });
    }
    return array;
  };

  const customerCharts = useMemo(() => {
    let chart1 = [];
    let chart2 = [];
    switch (filter?.groupType) {
      case 'hour':
        chart1 = calculateHourChart(customerChart);
        chart2 = calculateHourChart(pastCustomer, true);
        break;
      case 'week':
        chart1 = calculateWeekChart(customerChart);
        chart2 = calculateWeekChart(pastCustomer, true);
        break;
      default: //day
        chart1 = calculateDayChart(customerChart);
        chart2 = calculateDayChart(pastCustomer, true);
        break;
    }
    return [chart1, chart2];
  }, [filter?.groupType, customerChart, pastCustomer]);

  const multiChartsProps = {
    dateMode: filter?.groupType,
    duration: filter?.duration,
    tab: type,
  };
  const tabsData = useMemo(() => {
    return [
      isAdmin
        ? {
            key: 'total_customer',
            title: t('dashboard.total_customers'),
            description: t('dashboard.total_customers_desc'),
            current: currentTotal?.data?.total_customer || 0,
            previous: pastTotal?.data?.total_customer || 0,
            charts: customerCharts,
            chartProps: multiChartsProps,
            format: (x: string) => x,
          }
        : undefined,
      {
        key: 'total_buyer',
        title: t('dashboard.total_buyer'),
        description: t('dashboard.total_buyer_desc'),
        current: currentTotal?.data?.total_buyer || 0,
        previous: pastTotal?.data?.total_buyer || 0,
        format: (x: string) => x,
        charts: customerCharts,
        chartProps: multiChartsProps,
        disabled: true,
      },
      {
        key: 'returning_buyer',
        title: t('dashboard.total_returning_buyer'),
        description: t('dashboard.total_returning_buyer_desc'),
        current: currentTotal?.data?.total_returning_buyer || 0,
        previous: pastTotal?.data?.total_returning_buyer || 0,
        charts: customerCharts,
        chartProps: multiChartsProps,
        disabled: true,
      },
      {
        key: 'potential_buyer',
        title: t('dashboard.total_potential_buyer'),
        description: t('dashboard.total_potential_buyer_desc'),
        current: currentTotal?.data?.total_potential_buyer || 0,
        previous: pastTotal?.data?.total_potential_buyer || 0,
        format: (x: string) => x,
        charts: customerCharts,
        chartProps: multiChartsProps,
        disabled: true,
      },
      isAdmin
        ? {
            key: 'total_referred_customer',
            title: t('dashboard.total_referred_customer'),
            description: t('dashboard.total_referred_customer_desc'),
            current: currentTotal?.data?.total_referred_customer || 0,
            previous: pastTotal?.data?.total_referred_customer || 0,
            format: (x: string) => x,
            charts: customerCharts,
            chartProps: multiChartsProps,
            disabled: true,
          }
        : undefined,
    ]?.filter((x) => !!x);
  }, [filter, currentTotal, pastTotal, customerCharts, multiChartsProps]);

  const selectedChart: any = useMemo(() => {
    return tabsData?.find((x) => x?.key === type);
  }, [tabsData, type]);

  return (
    <Flex direction="column" gap="xl">
      <Grid>
        <Grid.Col span={{ base: 8 }}>
          <ReportRangeSelect value={filter} onChange={setFilter} />
        </Grid.Col>
        <Grid.Col span={{ base: 4 }} className="flex justify-end">
          <Button
            onClick={onExport}
            loading={exporting}
            variant="subtle"
            leftSection={<IconFA icon={faDownload} />}
          >
            {t('general.export')}
          </Button>
        </Grid.Col>
      </Grid>

      <Grid>
        {tabsData.map((item, i) => (
          <Grid.Col key={i} span={{ base: 6, sm: 3 }}>
            <div onClick={() => setType(item?.key)}>
              <ReportItem
                selected={item.key === type}
                label={item?.title}
                note={item?.description}
                current={item?.current || 0}
                previous={item?.previous || 0}
                format={item?.format || undefined}
              />
            </div>
          </Grid.Col>
        ))}
        {selectedChart?.key === 'total_customer' ? (
          <Grid.Col span={{ base: 12 }}>
            <MultiCharts
              charts={selectedChart?.charts}
              {...selectedChart?.chartProps}
            />
          </Grid.Col>
        ) : null}
      </Grid>
    </Flex>
  );
};
export default InsightCustomers;
