import { useEffect, useMemo, useState } from 'react';
import { useURLParams } from 'hooks/useURLParams';
import { t } from 'i18next';
import { usePaginationState } from 'hooks/usePaginationParams';
import {
  Button,
  Checkbox,
  Flex,
  Loader,
  Pagination,
  Text,
} from '@mantine/core';
import { useParams } from 'react-router-dom';
import ProductItem from 'pages/promotion-add-product/ProductItem';
import {
  useAddCommissionRateProducts,
  useDeleteCommissionRateProducts,
  useGetProducts,
} from 'apis/AffiliateApis';
import { ProductItem as ProductItemType, VariantItem } from 'types';

export type SelectProductItem = {
  isSelect: boolean;
  data?: ProductItemType;
  type: 'product' | 'variant';
};
type Props = {
  onSave: (data?: SelectProductItem[]) => void;
  selectedProducts: ProductItemType[];
};

const SelectProductList = ({ onSave, selectedProducts }: Props) => {
  const { id: commission_rate_id } = useParams();
  const { params }: any = useURLParams();
  const [checkedAll, setCheckedAll] = useState(false);
  const [selectOptions, setSelectOptions] = useState<SelectProductItem[]>([]);

  useEffect(() => {
    let array: SelectProductItem[] = selectedProducts?.map((data) => ({
      data,
      type: 'product',
      isSelect: true,
    }));
    setSelectOptions(array);
  }, [selectedProducts]);
  const { page, onPaginationChange, limit: LIMIT } = usePaginationState();
  const {
    data,
    isLoading: loading,
    isFetching,
  } = useGetProducts({
    ...params,
    page: page,
    limit: LIMIT,
    category_name: undefined,
    brand_name: undefined,
    seller_name: undefined,
  });
  const { mutate: addProducts, isPending: adding } =
    useAddCommissionRateProducts(commission_rate_id as string);
  const { mutate: deleteProducts, isPending: deleting } =
    useDeleteCommissionRateProducts(commission_rate_id as string);
  const products: ProductItemType[] = data?.data?.results || [];
  const total = data?.data?.total || 0;
  const onSelect = (
    product: ProductItemType,
    variant: VariantItem,
    isSelect = true,
  ) => {
    let array = [...selectOptions];
    const data = product?._id ? product : undefined;
    const type = 'product';
    let option = array.find(
      (x) => x?.type === type && x?.data?._id === data?._id,
    );
    // product
    if (option) {
      // selected
      if (option?.isSelect !== isSelect) {
        array = array.filter((x) => x?.data?._id !== data?._id);
      }
    }
    array.push({
      data,
      type,
      isSelect,
    });
    setSelectOptions(array);
  };

  const onSelectAllCurrent = (checkedAll: boolean) => {
    let array: SelectProductItem[] = [];
    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      const { total_promotion_variant, total_variant } = product || {};
      const is_selected =
        total_promotion_variant && total_promotion_variant === total_variant;
      if (
        (total_promotion_variant &&
          total_promotion_variant !== total_variant) ||
        (is_selected && !checkedAll) ||
        (!total_promotion_variant && checkedAll)
      ) {
        array.push({
          data: product,
          type: 'product',
          isSelect: checkedAll,
        });
      }
    }

    setSelectOptions(array);
  };
  const onSaveClicked = () => {
    const products = selectOptions?.filter((x) => x?.isSelect);
    const product_ids = products?.map((x) => x?.data?._id);
    addProducts(
      { product_ids },
      {
        onSuccess: () => {
          const product_ids = selectOptions
            ?.filter((x) => !x?.isSelect)
            ?.map((x) => x?.data?._id);
          deleteProducts(
            { product_ids },
            {
              onSuccess: () => {
                onSave(products);
              },
            },
          );
          onSave(products);
        },
      },
    );
    // const unselected_products = [];
    // const selected_variants = [];
    // const unselected_variants = [];
    // for (let i = 0; i < selectOptions.length; i++) {
    //   const option = selectOptions[i];
    //   if (option?.type === 'product') {
    //     if (option?.isSelect) {
    //       selected_products.push(option?.data?._id);
    //     } else {
    //       unselected_products.push(option?.data?._id);
    //     }
    //   } else {
    //     if (option?.isSelect) {
    //       selected_variants.push(option?.data?._id);
    //     } else {
    //       unselected_variants.push(option?.data?._id);
    //     }
    //   }
    // }
    // const params = {
    //   selected_products: {
    //     product_ids: selected_products?.length ? selected_products : undefined,
    //     variant_ids: selected_variants?.length ? selected_variants : undefined,
    //   },
    //   unselected_products: {
    //     product_ids: unselected_products?.length
    //       ? unselected_products
    //       : undefined,
    //     variant_ids: unselected_variants?.length
    //       ? unselected_variants
    //       : undefined,
    //   },
    // };
    // const product_ids = [
    //   ...new Set([
    //     ...selected_products?.map((x) => x?.product_id),
    //     ...selected_variants?.map((x) => x?.product_id),
    //     ...unselected_products,
    //     ...unselected_variants?.map((x) => x?.product_id),
    //   ]),
    // ];
    // onSave?.(selected_products);
  };

  return (
    <div className="py-4">
      <Flex
        gap={'sm'}
        align={'center'}
        className="rounded bg-neutral-100 px-3 py-2"
        // onClick={() => {
        //   onSelectAllCurrent(!checkedAll);
        //   setCheckedAll(!checkedAll);
        // }}
      >
        <Checkbox
          size="xs"
          checked={checkedAll}
          onChange={(e) => setCheckedAll(e.target.checked)}
          onClick={() => {
            onSelectAllCurrent(!checkedAll);
          }}
        />
        <Text size="sm" fw={'600'} className="flex flex-1">
          {t('promotions.product_list')} ({total})
        </Text>
      </Flex>

      <div style={{ height: '500px', overflowY: 'auto' }}>
        {isFetching ? (
          <div className="my-4 flex w-full justify-center">
            <Loader size={'sm'} />
          </div>
        ) : products?.length > 0 ? (
          products?.map((x: any, i: any) => {
            return (
              <ProductItem
                selectOptions={selectOptions}
                product={x}
                onSelect={onSelect}
                key={`product_${x?._id}`}
              />
            );
          })
        ) : (
          <Text size="sm" color="gray" className="my-4 text-center">
            {t('general.no_data')}
          </Text>
        )}
      </div>
      <Flex justify={'space-between'} className="mt-3 px-3" align={'center'}>
        {total ? (
          <Pagination
            color="primary"
            size={'sm'}
            radius={'sm'}
            total={total <= LIMIT ? 1 : Math.floor(total / LIMIT) + 1}
            value={page}
            onChange={(page: number | undefined) => onPaginationChange(page)}
            withEdges
          />
        ) : (
          <div></div>
        )}
        <Button
          loading={adding}
          disabled={loading || isFetching}
          onClick={onSaveClicked}
        >
          {t('general.save')}
        </Button>{' '}
      </Flex>
    </div>
  );
};
export default SelectProductList;
