import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { Button, Flex, Grid } from '@mantine/core';
import IconFA from 'components/common/IconFA';
import dayjs from 'dayjs';
import { currencyFormatter } from 'helpers/utils';
import {
  useDashboardOrderChart,
  useDashboardTotalChart,
  useExportOrderChart,
} from 'hooks/dashboard/dashboard';
import ReportItem from 'pages/affiliates/ReportItem';
import ReportRangeSelect, {
  ReportFilter,
} from 'pages/affiliates/ReportRangeSelect';
import MultiCharts from 'pages/dashboard/components/MultiCharts';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const InsightOrders = () => {
  // ### CONSTANTs
  const { t } = useTranslation();
  const [type, setType] = useState<string>('total_order');
  const [filter, setFilter] = useState<ReportFilter>();
  // ### FUNCTIONs

  const { mutate: exportOrder, isPending: exportingOrder } =
    useExportOrderChart();
  const onExport = () =>
    exportOrder(
      {
        from: filter?.from,
        to: filter?.to,
        group_by: filter?.groupType, // hour , week
      } as any,
      {
        onSuccess: (res: any) => {
          if (res?.status) {
            window.open(res?.message, '_blank');
            toast.success(t('general.export_success'));
          }
        },
        onError: () => {
          toast.error(t('general.export_fail'));
        },
      },
    );
  // ### RENDERs

  const { data: currentTotal }: any = useDashboardTotalChart({
    from: filter?.from,
    to: filter?.to,
  });
  const { data: pastTotal }: any = useDashboardTotalChart({
    from: filter?.pastFrom,
    to: filter?.pastTo,
  });

  // order
  const { data: orderChart } = useDashboardOrderChart({
    from: filter?.from,
    to: filter?.to,
    type: type,
    group_by: filter?.groupType,
    enabled: type !== 'customers',
  });
  const { data: pastOrder } = useDashboardOrderChart({
    from: filter?.pastFrom,
    to: filter?.pastTo,
    type: type,
    group_by: filter?.groupType,
    enabled: type !== 'customers',
  });

  const calculateHourChart = (chartData: any, isPast = false) => {
    let array = [];
    for (let i = 0; i < (filter?.duration || 0) + 1; i++) {
      let day = dayjs
        .unix(filter?.from as number)
        ?.clone()
        ?.add(i - (isPast ? filter?.duration || 0 : 0), 'hour')
        .format('YYYY-MM-DD HH');

      let data = chartData?.data?.find((x: any) => x.date === day);
      array.push({ label: day, value: data?.total || 0 });
    }
    return array;
  };

  const calculateWeekChart = (chartData: any, isPast = false) => {
    let array = [];
    let tempWeek = '';
    for (let i = 0; i < (filter?.duration || 0); i++) {
      let week: any = dayjs
        .unix(filter?.from as number)
        ?.clone()
        ?.add(i - (isPast ? filter?.duration || 0 : 0), 'day');
      let day = dayjs
        .unix(filter?.from as number)
        ?.clone()
        ?.add(i - (isPast ? filter?.duration || 0 : 0), 'day')
        .format('YYYY-MM-DD');
      week = `${week?.format('YYYY-MM')} ${week?.week?.()}`;
      let data = chartData?.data?.find((x: any) => x.date === week);
      // tempWeek
      if (week !== tempWeek) {
        array.push({ label: day, value: data?.total || 0 });
        tempWeek = week;
      }
    }
    return array;
  };

  const calculateDayChart = (chartData: any, isPast = false) => {
    let array = [];
    for (let i = 0; i < (filter?.duration || 0); i++) {
      let day = dayjs
        .unix(filter?.from as number)
        ?.clone()
        ?.add(i - (isPast ? filter?.duration || 0 : 0), 'day')
        .format('YYYY-MM-DD');

      let data = chartData?.data?.find((x: any) => x.date === day);
      array.push({ label: day, value: data?.total || 0 });
    }
    return array;
  };

  const orderCharts = useMemo(() => {
    let chart1 = [];
    let chart2 = [];
    switch (filter?.groupType) {
      case 'hour':
        chart1 = calculateHourChart(orderChart);
        chart2 = calculateHourChart(pastOrder, true);
        break;
      case 'week':
        chart1 = calculateWeekChart(orderChart);
        chart2 = calculateWeekChart(pastOrder, true);
        break;
      default: //day
        chart1 = calculateDayChart(orderChart);
        chart2 = calculateDayChart(pastOrder, true);
        break;
    }
    return [chart1, chart2];
  }, [filter?.groupType, orderChart, pastOrder]);

  const multiChartsProps = {
    dateMode: filter?.groupType,
    duration: filter?.duration,
    tab: type,
  };

  const tabsData = useMemo(() => {
    return [
      {
        key: 'total_order',
        title: t('dashboard.total_paid_orders'),
        description: t('dashboard.total_paid_orders_desc'),
        current: currentTotal?.data?.total_order || 0,
        previous: pastTotal?.data?.total_order || 0,
        charts: orderCharts,
        chartProps: multiChartsProps,
      },
      {
        key: 'total_sale',
        title: t('dashboard.total_paid_sales'),
        description: t('dashboard.total_paid_sales_desc'),
        current: currentTotal?.data?.total_sale || 0,
        previous: pastTotal?.data?.total_sale || 0,
        format: (x: number) => currencyFormatter.format(x),
        charts: orderCharts,
        chartProps: multiChartsProps,
      },
      {
        key: 'total_unpaid_order',
        title: t('dashboard.total_unpaid_orders'),
        description: t('dashboard.total_unpaid_orders_desc'),
        current: currentTotal?.data?.total_unpaid_order || 0,
        previous: pastTotal?.data?.total_unpaid_order || 0,
        charts: orderCharts,
        chartProps: multiChartsProps,
      },
      {
        key: 'total_unpaid_sale',
        title: t('dashboard.total_unpaid_sales'),
        description: t('dashboard.total_unpaid_sales_desc'),
        current: currentTotal?.data?.total_unpaid_sale || 0,
        previous: pastTotal?.data?.total_unpaid_sale || 0,
        format: (x) => currencyFormatter.format(x),
        charts: orderCharts,
        chartProps: multiChartsProps,
      },
      {
        key: 'total_refunded_order',
        title: t('dashboard.total_refunded_orders'),
        description: t('dashboard.total_refunded_orders_desc'),
        current: currentTotal?.data?.total_refunded_order || 0,
        previous: pastTotal?.data?.total_refunded_order || 0,
        charts: orderCharts,
        chartProps: multiChartsProps,
      },
      {
        key: 'total_refunded_sale',
        title: t('dashboard.total_refunded_sales'),
        description: t('dashboard.total_refunded_sales_desc'),
        current: currentTotal?.data?.total_refunded_sale || 0,
        previous: pastTotal?.data?.total_refunded_sale || 0,
        format: (x) => currencyFormatter.format(x),
        charts: orderCharts,
        chartProps: multiChartsProps,
      },
    ];
  }, [filter, currentTotal, pastTotal, orderCharts]);
  const selectedChart: any = useMemo(() => {
    return tabsData?.find((x) => x?.key === type);
  }, [tabsData, type]);
  return (
    <Flex direction="column" gap="xl">
      <Grid>
        <Grid.Col span={{ base: 8 }}>
          <ReportRangeSelect value={filter} onChange={setFilter} />
        </Grid.Col>
        <Grid.Col span={{ base: 4 }} className="flex justify-end">
          <Button
            loading={exportingOrder}
            onClick={onExport}
            variant="subtle"
            leftSection={<IconFA icon={faDownload} />}
          >
            {t('general.export')}
          </Button>
        </Grid.Col>
      </Grid>

      <Grid>
        {tabsData.map((item, i) => (
          <Grid.Col key={i} span={{ base: 6, md: 4, lg: 3 }}>
            <div onClick={() => setType(item?.key)}>
              <ReportItem
                selected={item.key === type}
                label={item?.title}
                note={item?.description}
                current={item?.current || 0}
                previous={item?.previous || 0}
                format={item.format}
              />
            </div>
          </Grid.Col>
        ))}
      </Grid>

      <Grid>
        <Grid.Col span={{ base: 12 }}>
          <MultiCharts
            charts={selectedChart?.charts}
            {...selectedChart?.chartProps}
          />
        </Grid.Col>
      </Grid>
    </Flex>
  );
};
export default InsightOrders;
