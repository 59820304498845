import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { Button, Grid } from '@mantine/core';
import IconFA from 'components/common/IconFA';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { t } from 'i18next';
import { useMemo, useState } from 'react';
import ReportItem from './ReportItem';
import { DatesRangeValue } from '@mantine/dates';
import {
  useAffiliateReportChart,
  useAffiliateSummaryReport,
} from 'apis/AffiliateApis';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import LoadingOverlayWrapper from 'components/MantineUI/LoadingOverlayWrapper';
import ReportRangeSelect, { ReportFilter } from './ReportRangeSelect';
import MultiLineChart from 'components/common/charts/MultiLineChart';
import { currencyFormatter } from 'helpers/utils';

const ReportCard = ({ affiliate_id: id }: { affiliate_id: string }) => {
  // ### CONSTANTs
  // const { id } = useParams();
  const [selectedCard, setSelectedCard] = useState('total_clicks');
  const [filter, setFilter] = useState<ReportFilter | undefined>();
  const { from, to, pastFrom, pastTo, groupType, duration, durationDay } =
    filter || {};
  // SUMMARY
  const { data: currentData, isFetching: currentFetching } =
    useAffiliateSummaryReport({
      id,
      from: from,
      to: to,
    });
  const { data: pastData, isFetching: pastFetching } =
    useAffiliateSummaryReport({
      id,
      from: pastFrom,
      to: pastTo,
    });
  // CHARTS
  const { data: currentChartData, isFetching: currentChartFetching } =
    useAffiliateReportChart({
      id,
      from: from,
      to: to,
      type: selectedCard,
      group_by: groupType,
    });
  const { data: pastChartData, isFetching: pastChartFetching } =
    useAffiliateReportChart({
      id,
      from: pastFrom,
      to: pastTo,
      type: selectedCard,
      group_by: groupType,
    });

  // ### FUNCTIONs
  // ### RENDERs
  // {
  //   key: 'total_order',
  //   title: t('dashboard.total_paid_orders'),
  //   description: t('dashboard.total_paid_orders_desc'),
  //   current: currentTotal?.data?.total_order || 0,
  //   previous: pastTotal?.data?.total_order || 0,
  //   charts: orderCharts,
  //   chartProps: multiChartsProps,
  // }

  const statCards = [
    {
      label: t('dashboard.total_clicks'),
      decs: t('dashboard.total_clicks_desc'),
      key: 'total_clicks',
      current: currentData?.data?.total_clicks,
      previuos: pastData?.data?.total_clicks,
    },
    {
      label: t('dashboard.total_paid_orders'),
      decs: t('dashboard.total_paid_orders_desc'),
      key: 'paid_orders',
      current: currentData?.data?.paid_orders,
      previuos: pastData?.data?.paid_orders,
    },
    {
      label: t('dashboard.conversion_rate'),
      decs: t('dashboard.conversion_rate_decs'),
      key: 'conversion_rate',
      current: currentData?.data?.conversion_rate,
      previuos: pastData?.data?.conversion_rate,
    },
    {
      label: t('dashboard.items_sold'),
      decs: t('dashboard.items_sold_decs'),
      key: 'sold_items',
      current: currentData?.data?.sold_items,
      previuos: pastData?.data?.sold_items,
    },
    {
      label: t('dashboard.net_sales'),
      decs: t('dashboard.net_sales_desc'),
      key: 'net_sales',
      current: currentData?.data?.net_sales,
      previuos: pastData?.data?.net_sales,
    },
    {
      label: t('dashboard.estimated_commissions'),
      decs: t('dashboard.estimated_commissions_desc'),
      key: 'estimated_commissions',
      current: currentData?.data?.estimated_commissions,
      previuos: pastData?.data?.estimated_commissions,
    },
    {
      label: t('dashboard.earnings'),
      decs: t('dashboard.earnings_desc'),
      key: 'earnings',
      current: currentData?.data?.earnings,
      previuos: pastData?.data?.earnings,
    },
    {
      label: t('dashboard.total_buyer'),
      decs: t('dashboard.total_buyer_desc'),
      key: 'total_buyers',
      current: currentData?.data?.total_buyers,
      previuos: pastData?.data?.total_buyers,
    },
  ];

  const calculateHourChart = (chartData = [], isPast = false) => {
    let array = [];
    if (duration && from) {
      for (let i = 0; i < duration + 1; i++) {
        let day = dayjs
          .unix(from)
          ?.clone()
          ?.add(i - (isPast ? duration : 0), 'hour')
          .format('YYYY-MM-DD HH');

        let data: any = chartData?.find((x: any) => x.date === day);
        array.push({ label: day, value: data?.total || 0 });
      }
    }
    return array;
  };

  const calculateWeekChart = (chartData = [], isPast = false) => {
    let array = [];
    let tempWeek = '';
    if (duration && from) {
      for (let i = 0; i < duration; i++) {
        let week: any = dayjs
          .unix(from)
          ?.clone()
          ?.add(i - (isPast ? duration : 0), 'day');
        let day = dayjs
          .unix(from)
          ?.clone()
          ?.add(i - (isPast ? duration : 0), 'day')
          .format('YYYY-MM-DD');
        week = `${week?.format('YYYY-MM')} ${week?.week?.()}`;
        let data: any = chartData?.find((x: any) => x.date === week);
        // tempWeek
        if (week !== tempWeek) {
          array.push({ label: day, value: data?.total || 0 });
          tempWeek = week;
        }
      }
    }
    return array;
  };

  const calculateDayChart = (chartData = [], isPast = false) => {
    let array = [];
    if (duration && from) {
      for (let i = 0; i < duration; i++) {
        let day = dayjs
          .unix(from)
          ?.clone()
          ?.add(i - (isPast ? duration : 0), 'day')
          .format('YYYY-MM-DD');

        let data: any = chartData?.find((x: any) => x.date === day);
        array.push({ label: day, value: data?.total || 0 });
      }
    }
    return array;
  };

  const charts = useMemo(() => {
    let chart1 = [];
    let chart2 = [];
    switch (groupType) {
      case 'hour':
        chart1 = calculateHourChart(currentChartData?.data);
        chart2 = calculateHourChart(pastChartData?.data, true);
        break;
      case 'week':
        chart1 = calculateWeekChart(currentChartData?.data);
        chart2 = calculateWeekChart(pastChartData?.data, true);
        break;
      default: //day
        chart1 = calculateDayChart(currentChartData?.data);
        chart2 = calculateDayChart(pastChartData?.data, true);
        break;
    }
    return [chart1, chart2];
  }, [groupType, currentChartData, pastChartData]);

  const renderLogs = () => (
    <Grid.Col span={{ base: 12, sm: 12 }}>
      <div>Current:</div>
      <div>{from && dayjs.unix(from).format('DD/MM/YYYY HH:mm:ss')}</div>
      <div>{to && dayjs.unix(to).format('DD/MM/YYYY HH:mm:ss')}</div>
      <div>dur:{duration}</div>
      <div>groupType:{groupType}</div>
      <div>Past:</div>
      <div>
        {pastFrom && dayjs.unix(pastFrom).format('DD/MM/YYYY HH:mm:ss')}
      </div>
      <div>{pastTo && dayjs.unix(pastTo).format('DD/MM/YYYY HH:mm:ss')}</div>
    </Grid.Col>
  );

  const isCurrency = (card: string) =>
    ['net_sales', 'estimated_commissions', 'earnings'].includes(card);

  return (
    <SectionWrapper>
      <SectionHeader
        title={t('general.reports')}
        isSubSection
        // actionButtons={
        //   <Button leftSection={<IconFA icon={faDownload} />} variant="subtle">
        //     {t('general.export')}
        //   </Button>
        // }
      />
      <SectionBody>
        <LoadingOverlayWrapper
          loading={
            currentFetching ||
            pastFetching ||
            currentChartFetching ||
            pastChartFetching
          }
        >
          <Grid>
            <Grid.Col span={{ base: 12, sm: 12 }}>
              <ReportRangeSelect value={filter} onChange={setFilter} />
            </Grid.Col>
            {/* {renderLogs()} */}

            {statCards.map((item, i) => (
              <Grid.Col key={i} span={{ base: 6, md: 4, lg: 3 }}>
                <div onClick={() => setSelectedCard(item?.key)}>
                  <ReportItem
                    selected={item.key === selectedCard}
                    label={item?.label}
                    note={item?.decs}
                    current={item?.current || 0}
                    previous={item?.previuos || 0}
                    format={(e) =>
                      item?.key === 'conversion_rate'
                        ? `${e}%`
                        : isCurrency(item?.key)
                          ? currencyFormatter.format(e)
                          : e
                    }
                  />
                </div>
              </Grid.Col>
            ))}

            <Grid.Col span={{ base: 12 }}>
              <MultiLineChart
                charts={charts as any}
                dateMode={groupType}
                duration={durationDay}
                isCurrency={isCurrency(selectedCard)}
                isPercent={selectedCard === 'conversion_rate'}
              />
            </Grid.Col>
          </Grid>
        </LoadingOverlayWrapper>
      </SectionBody>
    </SectionWrapper>
  );
};
export default ReportCard;
