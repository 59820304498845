import { CustomerItem, StatusEnum } from 'types';
import { t } from 'i18next';
import { Flex, Text } from '@mantine/core';
import { checkPermission, phoneFormatter } from 'helpers/utils';
import { useAuth } from 'contexts/AuthContext';
import SingleSelect, { SelectItem } from './SingleSelect';
import MultiSelect from './MultiSelect';
import StatusBadge from '../StatusBadge';
import { useCallback } from 'react';

type PropType =
  | {
      multiple: true;
      value: any[];
      onChange: (ids: SelectItem[]) => void;
      //
      className?: string;
      errorMessage?: string;
      required?: boolean;
      isSelected?: boolean;
    }
  | {
      multiple?: false;
      value: any;
      onChange: (id: SelectItem) => void;
      //
      className?: string;
      errorMessage?: string;
      required?: boolean;
      isSelected?: boolean;
    };
const CustomerSelect = (props: PropType) => {
  // ### CONSTANTs
  const {
    multiple,
    className = '',
    value = null,
    onChange,
    required,
    isSelected,
    ...els
  } = props;
  const { user } = useAuth();
  const isGranted = checkPermission(user, '/customers');
  // ### FUNCTIONs
  // ### RENDERs
  const generalProps: any = {
    disabled: !isGranted,
    required: required,
    label: t('orders.customer'),
    placeholder: t('general.select'),
    renderOption: (option: any, isSelected: boolean) => (
      <Flex gap={'xs'} align={'center'}>
        <Text size="sm" c={isSelected ? 'primary' : ''}>
          <span>{option?.label}</span> <span>({option?.information})</span>
        </Text>
        {option?.status && option?.status !== StatusEnum.ACTIVE && (
          <StatusBadge status={option?.status} />
        )}
      </Flex>
    ),
    searchable: true,
    searchField: 'name',
    route: '/admin/customers',
    optionTransformer: (data: any) =>
      data?.results?.map((x: any) => ({
        label:
          x.first_name || x.last_name
            ? `${x.first_name || ''} ${x.last_name || ''}`
            : '--',
        value: x._id,
        status: x?.status,
        information: x?.email || phoneFormatter(x?.phone_number) || '--',
        origin: x,
      })),
    ...els,
  };

  const transformData = useCallback(
    (x: CustomerItem | any) => {
      return {
        ...x,
        label:
          x?.label ||
          (x?.first_name || x?.last_name
            ? `${x?.first_name || ''} ${x?.last_name || ''}`
            : '') ||
          '',
        value: x?.value || x?._id || '',
      } as SelectItem;
    },
    [value],
  );

  if (multiple) {
    return (
      <MultiSelect
        {...generalProps}
        selected={(value as any)?.map((x: CustomerItem) => transformData(x))}
        onChange={(value) => {
          onChange?.(value as SelectItem[]);
        }}
      />
    );
  } else {
    return (
      <SingleSelect
        {...generalProps}
        selected={transformData(value)}
        onChange={(value) => {
          onChange?.(value as SelectItem);
        }}
      />
    );
  }
};
export default CustomerSelect;
