import { faEllipsis, faStar } from '@fortawesome/pro-solid-svg-icons';
import { Anchor, Button, Checkbox, Flex, Text } from '@mantine/core';
import {
  useCommissionRateDetails,
  useCommissionRateProducts,
  useDeleteCommissionRateProducts,
} from 'apis/AffiliateApis';
import IconFA from 'components/common/IconFA';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';
import {
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import PageLayout from 'components/MantineUI/PageLayout';
import ListPageSkeleton from 'components/MantineUI/Skeleton/ListPageSkeleton';
import SkeletonWrapper from 'components/MantineUI/Skeleton/SkeletonWrapper';
import StatusBadge from 'components/MantineUI/StatusBadge';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SelectProductModal from './SelectProductModal';
import { useEffect, useMemo, useState } from 'react';
import { CommissionRateItem, CommonTableColumn, ProductItem } from 'types';
import NailzyImage from 'components/common/NailzyImage';
import { useUserProfile } from 'apis/AuthApis';
import { getItemLink } from 'helpers/utils';
import { CommonTable } from 'components/MantineUI/CommonTable';
import AddCommissionRateModal from './AddCommissionRateModal';
import FixedCard from 'pages/products/components/FixedBar';
import ConfirmModal from 'components/MantineUI/Modals/ConfirmModal';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { usePaginationParams } from 'hooks/usePaginationParams';

const CommissionRateDetails = () => {
  // ### CONSTANTs
  const { isAdmin, isSeller } = useUserProfile();
  const { id } = useParams();
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const { limit, page, onPaginationChange } = usePaginationParams();
  const [selectedProducts, setSelectedProducts] = useState<ProductItem[]>([]);
  const [checkedProducts, setCheckedProducts] = useState<ProductItem[]>([]);
  const [deleteProduct, setDeleteProduct] = useState<ProductItem>();
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const { mutate: deleteProducts, isPending: deleting } =
    useDeleteCommissionRateProducts(id as string);
  const queryClient = useQueryClient();
  const { data, isLoading, isRefetching } = useCommissionRateDetails(id || '');
  const {
    data: productData,
    isLoading: loadingProduct,
    isRefetching: productFetching,
  } = useCommissionRateProducts(id || '', {
    page,
    limit,
  });
  const total = productData?.data?.total;
  const products: ProductItem[] = productData?.data?.results || [];
  const { commission_rate, created_on, name, status, updated_on, _id } =
    data?.data || {};
  const title = data?.data?.name || t('affiliates.commission_rates');
  // ### FUNCTIONs
  useEffect(() => {
    setSelectedProducts(products);
  }, [products]);
  const navigate = useNavigate();

  const [selectedCommissionRate, setSelectedCR] = useState<
    CommissionRateItem | undefined | {}
  >();
  const ids = useMemo(() => {
    return products?.map((x: ProductItem) => x?._id);
  }, [products]);
  const gotoDetails = (id?: string) => {
    if (id)
      navigate(`/products/${id}`, {
        state: { ids },
      });
  };
  const onCheckProductAll = () => {
    if (checkedProducts?.length === products?.length) {
      setCheckedProducts([]);
    } else {
      setCheckedProducts([...products]);
    }
  };
  const onCheckProduct = (checked: boolean, product: ProductItem) => {
    let array = [...checkedProducts];
    array = array?.filter((x) => x?._id !== product?._id);
    if (checked) {
      array.push(product);
    }
    setCheckedProducts(array);
  };
  const onDeleteProduct = () => {
    if (deleteProduct) {
      onRemoveProducts([deleteProduct]);
    }
  };
  const onRemoveProducts = (products = checkedProducts) => {
    const product_ids = products?.map((x) => x?._id);
    deleteProducts(
      { product_ids },
      {
        onSuccess: () => {
          toast.success(t('general.delete_success'));
          queryClient.invalidateQueries(['useCommissionRateDetails'] as any);
          setDeleteProduct(undefined);
          setCheckedProducts([]);
        },
        onError: () => {
          toast.error(t('general.delete_fail'));
        },
      },
    );
  };
  const columns: CommonTableColumn[] = useMemo(
    () =>
      [
        {
          accessor: 'check',
          Header: (
            <Checkbox
              indeterminate={
                !!(
                  checkedProducts?.length &&
                  checkedProducts?.length < products?.length
                )
              }
              onChange={(e) => onCheckProductAll()}
              checked={
                !!(
                  checkedProducts?.length &&
                  checkedProducts?.length === products?.length
                )
              }
            />
          ),
          Cell: (row: ProductItem) => {
            const isChecked = !!checkedProducts?.find(
              (x) => x?._id === row?._id,
            );
            return (
              <Checkbox
                checked={isChecked}
                onChange={(e) => onCheckProduct(e.target.checked, row)}
              />
            );
          },
        },
        {
          accessor: 'product',
          Header: t('products.product'),
          Cell: (row: ProductItem) => {
            const { name, _id, image_url } = row;
            return (
              <Flex align={'center'} gap={'sm'}>
                <NailzyImage
                  style={{
                    height: 50,
                    aspectRatio: 1,
                  }}
                  className={'rounded border'}
                  image_url={image_url}
                />
                <Anchor
                  component={Link}
                  to={`/products/${_id}`}
                  size="sm"
                  onClick={(event) => {
                    event.preventDefault();
                    gotoDetails(_id);
                  }}
                >
                  <Text>{name}</Text>
                </Anchor>
              </Flex>
            );
          },
        },
        {
          accessor: 'variants',
          Header: t('products.variants'),
          Cell: (row: ProductItem) => row?.total_variant || 0,
        },
        {
          accessor: 'seller_name',
          Header: isAdmin ? t('products.store') : null,
          Cell: (row: ProductItem) => {
            const { seller } = row;
            return isAdmin ? (
              <Anchor size="sm" component={Link} to={`/sellers/${seller?._id}`}>
                {seller?.store_name || '--'}
              </Anchor>
            ) : null;
          },
        },
        // {
        //   accessor: 'brand',
        //   Header: t('products.brand'),
        //   Cell: (row: ProductItem) => row?.brand?.name || '--',
        // },
        // {
        //   accessor: `category`,
        //   Header: t('products.category'),
        //   Cell: (row: ProductItem) => row?.category?.name || '--',
        // },
        // {
        //   accessor: 'sub_category',
        //   Header: t('products.subcategory'),
        //   Cell: (row: ProductItem) => row?.sub_category?.name || '--',
        // },
        // {
        //   accessor: 'number_sold',
        //   Header: t('products.sold'),
        //   Cell: (row: ProductItem) => row?.number_sold || 0,
        // },
        // {
        //   accessor: 'totalRating',
        //   Header: t('products.ratings'),
        //   Cell: (row: ProductItem) => {
        //     const { average_rating } = row;
        //     const { _id } = row;
        //     return (
        //       <Anchor
        //         size="sm"
        //         component={Link}
        //         to={`/products/${_id}/reviews`}
        //       >
        //         {average_rating?.toFixed(1) || 0} <IconFA icon={faStar} />
        //       </Anchor>
        //     );
        //   },
        // },
        {
          accessor: 'status',
          Header: t('general.status'),
          Cell: (row: ProductItem) => {
            const { status } = row;
            return <StatusBadge status={status} />;
          },
        },
        // {
        //   accessor: 'action',
        //   Cell: (row: ProductItem) => {
        //     const itemLink = getItemLink('products', row?.slug);
        //     return (
        //       <DropdownButton
        //         button={
        //           <Button variant="subtle" color="gray" size="xs">
        //             <IconFA icon={faEllipsis} />
        //           </Button>
        //         }
        //         dropdownData={[
        //           {
        //             key: 'edit',
        //             label: t('general.edit'),
        //             to: `/products/${row?._id}`,
        //           },
        //           {
        //             key: 'view_web',
        //             label: t('general.view_web'),
        //             href: itemLink,
        //           },
        //           {
        //             key: 'product_reviews',
        //             label: t('products.product_reviews'),
        //             to: `/products/${row?._id}/reviews`,
        //           },
        //           { isDivider: true },
        //           {
        //             key: 'delete',
        //             color: 'red',
        //             label: t('general.delete'),
        //             onClick: () => {
        //               setDeleteProduct(row);
        //             },
        //           },
        //         ]}
        //       />
        //     );
        //   },
        // },
      ]?.filter((x) => !!x),
    [products, selectedProducts, t, checkedProducts],
  );
  // ### RENDERs
  return (
    <SkeletonWrapper show={isLoading} skeleton={<ListPageSkeleton />}>
      <PageLayout
        title={title}
        breadCrumds={[
          {
            title: t('affiliates.affiliates'),
            href: '/affiliates',
          },
          {
            title: t('affiliates.commission_rates'),
            href: '/affiliates/commission-rates',
          },
          { title },
        ]}
      >
        <SectionWrapper>
          <SectionHeader
            title={title}
            subtitle={
              <>
                {t('affiliates.commission_rate')}:{' '}
                {(commission_rate * 100).toFixed(2)}%
                {status && <StatusBadge status={status} className="mt-2" />}
              </>
            }
            actionButtons={
              <DropdownButton
                button={
                  <Button size="xs" variant="default" color="gray">
                    <IconFA icon={faEllipsis} />
                  </Button>
                }
                dropdownData={[
                  {
                    key: 'edit',
                    label: t('general.edit'),
                    onClick: () => setSelectedCR(data?.data),
                  },
                ]}
              />
            }
          ></SectionHeader>
        </SectionWrapper>

        <div className="mb-[100px]">
          <SectionWrapper>
            <Button
              className="self-start"
              variant="outline"
              onClick={() => setOpened(true)}
            >
              {t('promotions.select_products')}
            </Button>
            <CommonTable
              loading={loadingProduct || productFetching}
              data={products}
              columns={columns}
              pagination={{ limit, page, onPaginationChange, total }}
            />
          </SectionWrapper>
        </div>

        {checkedProducts?.length ? (
          <FixedCard>
            <Button
              disabled={deleting}
              size="xs"
              variant="default"
              onClick={() => setShowRemoveModal(true)}
            >
              {t('products.remove_product')}
            </Button>
            <Text size="sm">
              {checkedProducts?.length} {t('products.product_selected')}
            </Text>
          </FixedCard>
        ) : null}
        <SelectProductModal
          opened={opened}
          onClose={() => setOpened(false)}
          selectedProducts={selectedProducts}
          onChange={setSelectedProducts}
        />
        <ConfirmModal
          title={t('products.remove_product')}
          message={t('products.remove_product_desc', {
            count: checkedProducts?.length,
          })}
          onClose={() => setShowRemoveModal(false)}
          opened={showRemoveModal}
          onOK={() => onRemoveProducts()}
          okText={t('general.remove')}
          danger
        />

        <AddCommissionRateModal
          onClose={() => setSelectedCR(undefined)}
          opened={!!selectedCommissionRate}
          data={selectedCommissionRate}
        />
      </PageLayout>
    </SkeletonWrapper>
  );
};
export default CommissionRateDetails;
