import { NativeSelect, NativeSelectProps, Select } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  CartStatusEnum,
  ProductStatusEnum,
  STATUS_INFO,
  StatusEnum,
} from 'types';
import { t } from 'i18next';
import { ReactNode, useMemo } from 'react';

type PropType = NativeSelectProps & {
  children?: ReactNode;
  className?: string;
  form?: UseFormReturnType<any>;
  hidePending?: boolean;
  hideDraft?: boolean;
  hideClose?: boolean;
  hideReject?: boolean;
  required?: boolean;
  hideActive?: boolean;
  hideComplete?: boolean;
  nativeSelect?: boolean;
};

const StatusSelect = (props: PropType) => {
  // ### CONSTANTs
  const {
    hidePending = true,
    hideDraft = false,
    nativeSelect = true,
    required = true,
    hideClose = false,
    hideReject = true,
    hideActive = false,
    hideComplete = true,
  } = props;
  // ### FUNCTIONs
  // ### RENDERs
  const data = useMemo(() => {
    let array: {
      label: string;
      value: StatusEnum | ProductStatusEnum | CartStatusEnum;
    }[] = [];
    if (!hideActive) {
      array.push({
        label: t(STATUS_INFO[StatusEnum.ACTIVE]?.label),
        value: StatusEnum.ACTIVE,
      });
    }
    if (!hideComplete) {
      array.push({
        label: t(STATUS_INFO[CartStatusEnum.COMPLETED]?.label),
        value: CartStatusEnum.COMPLETED,
      });
    }
    if (!hideDraft) {
      array.push({
        label: t(STATUS_INFO[StatusEnum.DRAFT]?.label),
        value: StatusEnum.DRAFT,
      });
    }
    if (!hideClose) {
      array.push({
        label: t(STATUS_INFO[StatusEnum.CLOSED]?.label),
        value: StatusEnum.CLOSED,
      });
    }
    if (!hidePending) {
      array.push({
        label: t(STATUS_INFO[StatusEnum.PENDING]?.label),
        value: StatusEnum.PENDING,
      });
    }
    if (!hideReject) {
      array.push({
        label: t(STATUS_INFO[ProductStatusEnum.REJECTED]?.label),
        value: ProductStatusEnum.REJECTED,
      });
    }
    return array;
  }, [hidePending]);
  return nativeSelect ? (
    <NativeSelect
      required={required}
      label={t('general.status')}
      data={data}
      {...props}
    />
  ) : (
    <Select
      {...(props as any)}
      placeholder={t('general.select')}
      required={required}
      label={t('general.status')}
      data={data}
    />
  );
};
export default StatusSelect;
