import { useQuery, useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { dataFetchingQueryConfig } from 'config';
import { apiCallV2 } from './ApiCallV2';

const config = dataFetchingQueryConfig;

export const useGetPackages = (params = {}) => {
  const url = 'admin/packages';
  return useQuery({
    queryKey: ['useGetPackages', params],
    queryFn: () => apiCallV2({ url, params }),
    ...config,
  });
};
export const useCreatePackage = () => {
  const url = 'admin/packages';
  return useMutation({
    mutationFn: (data = {}) => apiCallV2({ method: 'post', url, data }),
    ...config,
  });
};

export const useUpdatePackage = () => {
  return useMutation({
    mutationFn: (params = {}) => {
      const { _id, ...data } = params;
      const url = 'admin/packages/' + _id;
      apiCallV2({ method: 'put', url, data });
    },
    ...config,
  });
};
export const useDeletePackage = () => {
  return useMutation({
    mutationFn: (_id) => {
      const url = 'admin/packages/' + _id;
      apiCallV2({ method: 'delete', url });
    },
    ...config,
  });
};
