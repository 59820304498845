import { CommonTableColumn } from 'types';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import PageLayout from 'components/MantineUI/PageLayout';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Anchor, Button, Grid, Text } from '@mantine/core';
import { CommonTable } from 'components/MantineUI/CommonTable';
import {
  useDashboardLowStockInventory,
  useExportBLowStockInventory,
} from 'hooks/dashboard/dashboard';
import ReportRangeSelect, {
  ReportFilter,
} from 'pages/affiliates/ReportRangeSelect';
import { usePaginationParams } from 'hooks/usePaginationParams';
import { toast } from 'react-toastify';
import IconFA from 'components/common/IconFA';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { textTruncate } from 'helpers/utils';
import { Link } from 'react-router-dom';

const LowStockInventory = () => {
  // ### CONSTANTs
  const { t } = useTranslation();
  const title = t('analytics.low_stock_inventory');
  const subtitle = t('analytics.low_stock_inventory_desc');
  // ### FUNCTIONs
  const { page, limit, onPaginationChange } = usePaginationParams();
  const [filter, setFilter] = useState<ReportFilter>();
  const { from, to } = filter || {};
  // ### RENDERs
  const { data, isFetching } = useDashboardLowStockInventory({
    from: from,
    to: to,
    page,
    limit,
    enabled: !!from && !!to,
  });
  const items = (data as any)?.data?.results || [];
  const total = (data as any)?.data?.total || 0;

  // export
  const { mutate: exportBestSellingItems, isPending: exporting } =
    useExportBLowStockInventory();

  const onExportClick = () => {
    exportBestSellingItems(
      {
        from: from,
        to: to,
        page,
        limit,
      } as any,
      {
        onSuccess(res: any) {
          if (res?.status) {
            window.open(res?.message, '_blank');
            toast.success(t('general.export_success'));
          } else {
            toast.error(t('general.export_fail'));
          }
        },
      },
    );
  };

  const columns: CommonTableColumn[] = [
    {
      accessor: 'name',
      Header: t('products.product'),
      Cell: (row) => {
        const { product_id, product_name, variant_name, sku } = row;
        return (
          <div className="d-flex flex-row items-center">
            <div>
              {product_name ? (
                <Anchor
                  component={Link}
                  size="sm"
                  to={`${'/products/' + product_id}`}
                >
                  {textTruncate(product_name) || '--'}
                </Anchor>
              ) : (
                '--'
              )}

              <Text size="xs">{textTruncate(variant_name) || '--'}</Text>
              {sku ? <Text size="xs">SKU: {sku}</Text> : ''}
            </div>
          </div>
        );
      },
    },
    {
      accessor: 'current_stock',
      Header: t('analytics.current_stock'),
      Cell: (row) => {
        return row?.current_stock || '0';
      },
    },
    {
      accessor: 'store_name',
      Header: t('products.store'),
      Cell: (row) => {
        return row?.store_name || '--';
      },
    },
  ];
  return (
    <PageLayout
      title={title}
      breadCrumds={[
        {
          title: t('analytics.reports'),
          href: '/reports',
        },
        { title },
      ]}
    >
      <SectionWrapper>
        <SectionHeader title={title} subtitle={subtitle}></SectionHeader>
        <SectionBody>
          <Grid className="mb-3">
            <Grid.Col span={{ base: 12, lg: 8 }}>
              <ReportRangeSelect value={filter} onChange={setFilter} />
            </Grid.Col>
            <Grid.Col className="text-end" span={{ base: 12, lg: 4 }}>
              <Button
                loading={exporting}
                onClick={onExportClick}
                variant="subtle"
                leftSection={<IconFA icon={faDownload} />}
              >
                {t('general.export')}
              </Button>
            </Grid.Col>
          </Grid>
          <CommonTable
            loading={isFetching}
            pagination={{
              page,
              limit,
              onPaginationChange,
              total,
            }}
            columns={columns}
            data={items}
          />
        </SectionBody>
      </SectionWrapper>
    </PageLayout>
  );
};
export default LowStockInventory;
