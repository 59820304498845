import i18next from 'i18next';

export const DATETIME_FORMAT = {
  MMMDDYYYY: 'MMM DD, YYYY', // Aug 12, 2024
  MMMMDDYYYYHHMMA: 'MMMM DD, YYYY hh:mm A', // Aug 12, 2024
  DDMMMYYYY: `DD MMM, YYYY`, // 12 Aug, 2024
  YYYYDDMM: 'YYYY-MM-DD', // 2024-08-12
  DDMMYYYY: 'DD-MM-YYYY', // 12-08-2024
  MMDDYYYY: 'MM-DD-YYYY', // 08-12-2024
  DDMMYY: 'DD/MM/YY', // 12/08/24
  HHmm: 'HH:mm', // 14:30
  hhmm_A: 'hh:mm A', // 02:30 PM
  YYYYMMDDHHmmss: 'YYYY-MM-DD HH:mm:ss', // 2024-08-12 14:30:00
  YYYYMMDDHHmmssA: 'YYYY-MM-DD hh:mm:ss A', // 2024-08-12 02:30:00 PM
  MMMMYYYY: 'MMMM YYYY', // August 2024
  YYYY: 'YYYY', // 2024
  FULL_DATE: 'dddd, MMMM D, YYYY', // Monday, August 12, 2024
  FULL_DATETIME_WITH_DAY: `dddd, MMMM DD YYYY [at] hh:mm A`, // Monday, August 12 2024 at 02:30 PM
  SHORT_DATE_TIME: `DD MMM, YYYY [at] HH:mm`, // Aug 12, 2024 at 14:30
};
