import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons';
import { Flex, Grid, Loader, Text } from '@mantine/core';
import IconFA from 'components/common/IconFA';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { currencyFormatter, getColor } from 'helpers/utils';
import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
type PropType = {
  children?: ReactNode;
  className?: string;

  selectedProducts: any;
  cacheQuantity: any;
  isCompleted: boolean;
  pricing: any;
  onRefetchPricing: any;
  gettingPricing: any;
};
const DCSummary = (props: PropType) => {
  // ### CONSTANTs
  const {
    selectedProducts,
    cacheQuantity,
    isCompleted,
    pricing,
    onRefetchPricing,
    gettingPricing,
  } = props;
  const { t } = useTranslation();
  // ### FUNCTIONs
  // ### RENDERs

  const renderLine = (name: string, amout: number, type = 'default') => {
    return (
      <div className="d-flex flex-row align-item-center justify-content-between mb-1 border-b border-b-gray-100">
        <Text>{name}</Text>
        <Text
          fw={type === 'total' ? 'bold' : ''}
          c={type === 'discount' ? 'red' : ''}
        >
          {amout
            ? (type === 'discount' ? '-' : '') + currencyFormatter.format(amout)
            : '--'}
        </Text>
      </div>
    );
  };

  const [tempTotal, tempDiscount, tempSubtotal] = useMemo(() => {
    let total = 0,
      subtotal = 0;
    for (let i = 0; i < selectedProducts.length; i++) {
      const product = selectedProducts[i];
      if (product?.variants?.length) {
        for (let y = 0; y < product?.variants.length; y++) {
          const variant = product?.variants[y];
          const qty =
            cacheQuantity?.[variant?.variant_id] || variant?.quantity || 1;
          total += variant?.price * qty;
          subtotal += variant?.discount_price * qty;
        }
      }
    }
    return [total, total - subtotal, subtotal];
  }, [selectedProducts, cacheQuantity]);

  const {
    coupon_discount_amount,
    items_discount,
    items_total,
    sales_tax,
    shipping: shipping_amount,
    sub_total,
    total,
  } = pricing || {};

  return (
    <SectionWrapper>
      <SectionHeader
        isSubSection
        title={t('orders.summary')}
        actionButtons={
          <>
            {isCompleted || !pricing ? null : (
              <IconFA
                onClick={() => onRefetchPricing()}
                icon={faArrowsRotate}
                color={getColor('gray-700')}
              />
            )}
          </>
        }
      />

      <SectionBody className="pt-0">
        {gettingPricing ? (
          <Flex align={'center'} direction={'column'}>
            <Loader className="ms-1" size={15} />
            <p className="mt-2 fs--1">{t('orders.fetching_pricing')}</p>
          </Flex>
        ) : (
          <Grid>
            <Grid.Col span={{ base: 12, sm: 4 }}></Grid.Col>
            <Grid.Col span={{ base: 12, sm: 8 }}>
              {renderLine(t('orders.items_total'), tempTotal)}
              {renderLine(t('orders.items_discount'), tempDiscount, 'discount')}
              {renderLine(t('orders.subtotal'), tempSubtotal)}
              {!pricing ? null : (
                <>
                  {renderLine(
                    t('orders.coupon_discount'),
                    coupon_discount_amount,
                    'discount',
                  )}
                  {renderLine(t('orders.shipping'), shipping_amount)}
                  {renderLine(t('orders.sales_tax'), sales_tax)}
                  {renderLine(t('orders.total_pricing'), total, 'total')}
                </>
              )}
            </Grid.Col>
          </Grid>
        )}
      </SectionBody>
    </SectionWrapper>
  );
};
export default DCSummary;
