import { Badge } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { STATUS_INFO, StatusEnum } from 'types';
import IconFA from 'components/common/IconFA';

type Props = {
  status: StatusEnum;
  className?: string;
  fullWidth?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
};

const StatusBadge = ({
  status,
  className,
  fullWidth = false,
  size = 'sm',
}: Props) => {
  const { t } = useTranslation();
  const statusIcon = STATUS_INFO[status]?.icon ?? undefined;
  const color = STATUS_INFO[status]?.color ?? 'light';
  const label = t(STATUS_INFO[status]?.label) ?? 'N/A';
  return (
    <Badge
      fullWidth={fullWidth}
      className={className}
      size={size}
      variant="light"
      color={color}
    >
      {statusIcon && <IconFA className={`mr-1`} icon={statusIcon} />}
      {label}
    </Badge>
  );
};

export default StatusBadge;
