import { AddressInfo, StatusEnum } from './common.type';
import { WeightUniEnum } from './setting.type';
import { PermissionItem, SystemUserItem, UserInfo } from './user.type';

export type SellerItem = {
  _id?: string;
  first_name?: string;
  last_name?: string;
  slug?: string;
  store_name?: string;
  fulfill_type?: string;
  status: StatusEnum;
  address?: AddressInfo;
  phone_number?: string;
  logo?: any;
  logo_url?: string;
  email?: string;
  business_type?: string;
  legal_business_name?: string;
  tax_id?: string;
  files?: any[];
  banners?: any[];
  created_on?: number;
  updated_on?: number;

  bank_name?: string;
  bank_account_holder_name?: string;
  bank_account_number?: string;
  IfSC_code?: string;
  totalOrders?: number;
  totalAmount?: number;
  totalCompleteOrders?: number;
  totalCompleteReceived?: number;
  system_user?: SystemUserItem;
};

export type FlatRateItem = {
  _id?: string;
  name?: string;
  amount?: number;
  updated_on?: number;
  is_free_ship?: boolean;
  is_new_user?: boolean;
  estimated_days?: number;
  conditions?: FlatRateCondition;
  type?: RateTypeEnum;
};
export type FlatRateForm = {
  name: string;
  amount: number;
  estimated_days: number;
  is_turn_on: boolean;
  is_free_ship?: boolean;
  is_new_user?: boolean;
  type: SellerFlatRateConditionType;
  min_price?: number;
  max_price?: number;
  weight_unit?: WeightUniEnum;
  min_weight?: number;
  max_weight?: number;
  rate_type?: RateTypeEnum;
};
export enum RateTypeEnum {
  PERCENTAGE = 'percentage',
  FIXED = 'fixed',
}

export type FlatRateCondition = {
  is_turn_on: boolean;
  type: SellerFlatRateConditionType;
  min_price?: number;
  max_price?: number;
  weight_unit?: WeightUniEnum;
  min_weight?: number;
  max_weight?: number;
};

export enum SellerFlatRateConditionType {
  ORDER_PRICE = 'order_price',
  ITEM_WEIGHT = 'item_weight',
}
export type ShippingConfig = {
  _id: string;
  is_live_rates: boolean;
  zones: { country_code: string; state_codes: string[] }[];
};
export type StateItem = { code: string; name: string };
export type CountryItem = {
  code: string;
  name: string;
  states: StateItem[];
};
export type WarehouseItem = { address: AddressInfo; name: string };
