import { Tabs, Text } from '@mantine/core';
import { KeyLabelPair } from 'types';
import { ReactNode, useState } from 'react';
import IconFA from 'components/common/IconFA';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { getColor } from 'helpers/utils';

type PropType = {
  tabs: KeyLabelPair[];
  defaultTabKey?: string;
  selectedTab?: string;
  onChangeTab?: (tab: string) => void;
  className?: string;
  panels?: ReactNode;
};

const TabList = (props: PropType) => {
  // ### CONSTANTs
  const { tabs, onChangeTab, className = '', selectedTab, panels } = props;
  const [currentTab, setCurrentTab] = useState(tabs?.[0]?.key);
  // ### FUNCTIONs
  const onChange = (key: string | null) => {
    setCurrentTab(key || '');
    onChangeTab?.(key || '');
  };
  // ### RENDERs
  return (
    <div className={'mb-4' + className}>
      <Tabs
        value={selectedTab || currentTab}
        onChange={onChange}
        defaultValue={'all'}
        variant="outline"
      >
        <Tabs.List grow>
          {tabs.map((item, index) => {
            return (
              <Tabs.Tab
                key={index}
                value={item?.key}
                leftSection={
                  selectedTab === item.key ? (
                    <IconFA
                      icon={faCircle}
                      size="xs"
                      color={getColor('primary')}
                    />
                  ) : null
                }
              >
                <Text size="sm" fw={selectedTab === item.key ? 600 : 400}>
                  {item.label}
                </Text>
              </Tabs.Tab>
            );
          })}
        </Tabs.List>
        {panels}
      </Tabs>
    </div>
  );
};
export default TabList;
