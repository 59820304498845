const sellerRoutes = [
  {
    label: 'Home',
    icon: 'home',
    to: '/',
  },
  {
    label: 'Analytics',
    icon: 'chart-simple',
    children: [
      {
        label: 'Insights',
        to: '/insights/orders',
      },
      {
        label: 'Reports',
        to: '/reports',
      },
    ],
  },
  {
    label: 'Orders',
    to: '/orders',
    icon: 'shopping-cart',
  },
  {
    label: 'Inquiries',
    to: '/inquiries',
    icon: 'list',
  },
  {
    label: 'Products',
    to: '/products',
    icon: 'tag',
  },
  {
    label: 'Reviews',
    to: '/reviews',
    icon: 'star',
  },
  {
    label: 'Coupons',
    to: '/coupons',
    icon: 'percentage',
  },
  {
    label: 'Settings',
    icon: 'gear',
    children: [
      {
        label: 'Profile & security',
        to: '/account',
      },
      {
        label: 'Store details',
        to: '/store',
      },
      {
        label: 'Warehouse',
        to: '/warehouse',
      },
      {
        label: 'Packages',
        to: '/packages',
      },
      {
        label: 'Shipping',
        to: '/shipping',
      },
    ],
  },
];

export default sellerRoutes;
