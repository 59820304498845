import {
  faLanguage,
  faQuestionCircle,
  faSignOutAlt,
  faUserCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mantine/core';
import CommonAvatar from 'components/MantineUI/CommonAvatar';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';
import Avatar from 'components/common/Avatar';
import IconFA from 'components/common/IconFA';
import { supportLink } from 'config';
import { useAuth } from 'contexts/AuthContext';
import i18next from 'i18next';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ProfileDropdown = () => {
  const { user } = useAuth();
  const fullName = user ? `${user?.first_name}` : '';
  const email = user?.email;
  const storeName = user?.seller?.store_name;

  // Changing language
  const { t } = useTranslation();
  const toggleLanguage = () => {
    const currentLanguage = localStorage.getItem('languages');
    const newLanguage = currentLanguage === 'en' ? 'vi' : 'en';
    localStorage.setItem('languages', newLanguage);
    i18next.changeLanguage(newLanguage);
  };

  return (
    <>
      <DropdownButton
        button={
          <Button variant="transparent" className="flex justify-start">
            {user && <CommonAvatar user={user} size={32} />}

            <div className="ps-2 sm:flex flex-col items-start gap-1 hidden ">
              <p className="m-0 fw-medium text-700">
                {fullName} {storeName && `(${storeName})`}
              </p>
              <p className="m-0 fw-normal text-700">{email}</p>
            </div>
          </Button>
        }
        dropdownData={[
          {
            label: (
              <span>
                <IconFA className={' me-3'} icon="user-circle" />
                {t('account.account')}
              </span>
            ),
            key: 'account',
            to: '/account',
          },
          {
            label: (
              <span>
                <IconFA className={' me-3'} icon="sign-out-alt" />
                {t('login.logout')}
              </span>
            ),
            key: 'logout',
            to: '/logout',
          },
          {
            isDivider: true,
          },
          {
            label: (
              <span>
                <IconFA className={' me-3'} icon="language" />
                {t('navigation.language')}
              </span>
            ),
            key: 'language',
            onClick: () => toggleLanguage(),
          },
          {
            label: (
              <span>
                <IconFA className={' me-3'} icon="question-circle" />
                {t('navigation.help_center')}
              </span>
            ),
            key: 'help',
            href: supportLink,
          },
        ]}
      />
      {/* <Dropdown navbar={true} as="li">
        <Dropdown.Toggle
          bsPrefix="toggle"
          as={Link}
          className="nav-link d-flex align-items-center"
        >
          <Avatar
            src={user ? user.profile_image_url : null}
            name={user ? `${user?.first_name} ${user?.last_name}` : 'Anonymous'}
            className="align-self-center"
          />

          <div className="ps-2">
            <p className="m-0 fw-medium text-700">
              {user ? `${user?.first_name} ${user?.last_name}` : ''}
            </p>
            <p className="m-0 fw-normal text-700">{user?.email}</p>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
          <div className="bg-white rounded-2 py-2 dark__bg-1000">
            <Dropdown.Item as={Link} to={'/account'}>
              <FontAwesomeIcon icon={faUserCircle} className="me-2" />
              {t('account.account')}
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={'/logout'}>
              <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />
              {t('login.logout')}
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item onClick={() => toggleLanguage()}>
              <FontAwesomeIcon icon={faLanguage} className="me-2" />
              {t('navigation.language')}
            </Dropdown.Item>

            <Dropdown.Item href={supportLink} target="_blank">
              <FontAwesomeIcon icon={faQuestionCircle} className="me-2" />
              {t('navigation.help_center')}
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown> */}
    </>
  );
};

export default ProfileDropdown;
