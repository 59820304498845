import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import { Badge, Flex, Text, Tooltip } from '@mantine/core';
import IconFA from 'components/common/IconFA';
import { getColor } from 'helpers/utils';

type Props = {
  label: string;
  current?: number;
  previous?: number;
  note: string;
  selected?: boolean;
  format?: (a: any) => string;
  viewOnly?: boolean;
};

const ReportItem = ({
  label = '',
  selected,
  current,
  previous,
  note,
  format,
  viewOnly = false,
}: Props) => {
  return (
    <div
      className={`rounded-lg border p-2 shadow-sm ${selected ? 'border-primary' : ''} ${viewOnly ? '' : 'cursor-pointer hover:bg-gray-50'}`}
    >
      <Flex align={'center'} justify={'space-between'}>
        <Text c={'gray.7'} size="xs" fw={'600'}>
          {label.toUpperCase()}
        </Text>
        <Tooltip label={note}>
          <div>
            <IconFA
              size="sm"
              icon={faCircleQuestion}
              className={'mb-[2px] ms-1'}
              color={'gray'}
            />
          </div>
        </Tooltip>
      </Flex>
      <Text fz={typeof previous === 'number' ? 20 : 22} fw="700">
        {typeof current === 'number'
          ? format
            ? format(current)
            : current.toLocaleString()
          : '-'}
      </Text>
      <Flex gap={'xs'}>
        <Text c={'gray.7'} size="sm">
          {typeof previous === 'number'
            ? format
              ? format(previous)
              : previous.toLocaleString()
            : ''}
        </Text>
        {typeof current !== 'number' ||
        typeof previous !== 'number' ||
        current === previous ? null : (
          <Badge color={previous <= current ? 'green' : 'red'} variant="light">
            <IconFA
              className="me-1"
              icon={`caret-${previous <= current ? 'up' : 'down'}`}
              color={getColor(previous <= current ? 'success' : 'danger')}
            />
            {current === previous
              ? 0
              : current < 0
                ? 100
                : parseFloat(
                    (
                      (Math.abs((previous || 0) - current) /
                        (previous || current)) *
                      100
                    ).toFixed(0),
                  )}
            %
          </Badge>
        )}
      </Flex>
    </div>
  );
};

export default ReportItem;
