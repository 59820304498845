import React, { ReactNode } from 'react';
import { CopyButton as MTCopyButton, ActionIcon } from '@mantine/core';
import IconFA from 'components/common/IconFA';

type PropType = {
  className?: string;
  value?: string;
};

const CopyButton = (props: PropType) => {
  // ### CONSTANTs
  const { value, className = '' } = props;
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <span className={className}>
      <MTCopyButton value={value || ''} timeout={2000}>
        {({ copied, copy }) => (
          <ActionIcon
            color={copied ? 'teal' : 'gray'}
            variant="subtle"
            onClick={copy}
          >
            {copied ? (
              <IconFA icon={'check'} />
            ) : (
              <IconFA icon={'copy'} type="light" />
            )}
          </ActionIcon>
        )}
      </MTCopyButton>
    </span>
  );
};
export default CopyButton;
