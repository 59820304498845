import { Flex, Text } from '@mantine/core';
import { dateTimeFormatter } from 'helpers/utils';
import { t } from 'i18next';
import { ReactNode } from 'react';

type PropType = {
  children?: ReactNode;
  className?: string;
  updatedTime?: number;
};

const SectionFooter = (props: PropType) => {
  // ### CONSTANTs
  const { children, className, updatedTime } = props;
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <Flex
      align={{ base: 'end', md: 'center' }}
      justify={'space-between'}
      direction={{ base: 'column', md: 'row-reverse' }}
      gap={'sm'}
      wrap={'wrap'}
      className="mt-3"
    >
      <Flex
        direction={'row-reverse'}
        gap={'sm'}
        className={className}
        wrap={'wrap'}
      >
        {children}
      </Flex>
      {updatedTime ? (
        <Text size="xs" flex={1} c="dimmed">{`${t(
          'general.updated_date',
        )}: ${dateTimeFormatter(updatedTime)}`}</Text>
      ) : null}
    </Flex>
  );
};
export default SectionFooter;
