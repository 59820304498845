// import icons from 'assets/fontawesome/metadata/icons.json';
import IconFA from 'components/common/IconFA';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Button,
  Flex,
  Grid,
  InputError,
  InputLabel,
  Modal,
  TextInput,
} from '@mantine/core';
import { SectionFooter } from 'components/MantineUI/CommonSection';

type Props = {
  icon: string;
  errorMessage?: string;
  required?: boolean;
  onIconChange: (icon: string) => void;
};
const SelectIconComponent = ({
  icon,
  onIconChange,
  required,
  errorMessage = '',
}: Props) => {
  const { t } = useTranslation();

  const [modalShow, setModalShow] = useState(false);
  const [clicked, setClickedIcon] = useState('');
  const ICON_COLUMNS = 8;
  const ICON_PAGE_LIMIT = 48;

  const array = [
    'bolt',
    'star',
    'heart',
    'camera-retro',
    'truck-fast',
    'bell',
    'cart-shopping',
    'gift',
    'tag',
    'bookmark',
    'thumbs-up',
    'key',
    'truck',
    'handshake',
    'shop',
    'shirt',
    'bag-shopping',
    'cart-plus',
    'credit-card',
    'store',
    'cash-register',
    'trophy',
    'cartificate',
    'receipt',
    'gifts',
    'gem',
    'cart-arrow-down',
    'bullhorn',
    'basket-shopping',
    'glasses',
    'vest',
    'mitten',
    'hat-cowboy-side',
    'graduation-cap',
    'hat-wizard',
    'fire',
    'sun',
    'water',
    'leaf',
    'seeding',
    'plug',
    'wind',
    'lightbulb',
    'plug-circle-bolt',
    'gas-pump',
    'fire-flame-simple',
    'fire-flame-curved',
    'fan',
    // 'bolt',
    // 'arrows-up-down-left-right',
    // 'caret-down',
    // 'caret-up',
    // 'chart-pie',
    // 'circle-question',
    // 'house',
    // 'rotate-left',
    // 'arrow-down-short-wide',
    // 'square',
    // 'square-check',
    // 'trash',
    // 'arrows-rotate',
    // 'add',
    // 'upload',
    // 'ban',
    // 'image',
    // 'check-circle',
    // 'save',
    // 'archive',
    // 'clock',
    // 'check',
    // 'filter',
    // 'magnifying-glass',
    // 'angles-up-down',
    // 'chevron-down',
    // 'home',
    // 'user-circle',
    // 'sign-out-alt',
    // 'language',
    // 'question-circle',
    // 'envelope',
    // 'phone',
    // 'lock',
    // 'ellipsis',
    // 'chevron-right',
    // 'file',
    // 'arrow-down-to-line',
    // 'trash-can',
    // 'globe',
    // 'note-sticky',
    // 'search',
    // 'star',
    // 'download',
    // 'paperclip',
    // 'at',
    // 'store',
    // 'edit',
    // 'eye',
    // 'map',
    // 'plus',
    // 'circle',
    // 'rotate',
    // 'truck',
    // 'circle-check',
    // 'chevron-left',
    // 'chevron-up',
    // 'grip-dots-vertical',
    // 'box',
    // 'shopping-cart',
    // 'percentage',
  ].splice(0, ICON_PAGE_LIMIT);
  const [selectedIcon, setSelectedIcon] = useState(icon || array?.[0]);

  useEffect(() => {
    if (icon !== selectedIcon) {
      setSelectedIcon(icon);
    }
  }, [icon]);

  return (
    <Grid>
      <Grid.Col span={{ base: 12 }}>
        <InputLabel required={required}>Icon</InputLabel>
        <Flex>
          <TextInput
            error={!!errorMessage}
            className="flex-1"
            leftSection={<IconFA icon={selectedIcon} color="black" />}
            value={selectedIcon}
            onChange={(e) => {
              onIconChange(e.target.value);
              setSelectedIcon(e.target.value);
            }}
            rightSection={
              <Button
                variant="subtle"
                size="xs"
                // transform="shrink-3"
                // className="text-700"
                onClick={() => {
                  setModalShow(true);
                }}
              >
                {t('general.select')}
              </Button>
            }
            rightSectionWidth={'auto'}
          />
        </Flex>
        <InputError className="mt-1">{errorMessage}</InputError>

        <Modal
          title={t('general.select_icon')}
          opened={modalShow}
          onClose={() => setModalShow(false)}
          size="xl"
          centered
        >
          <Flex className="flex-wrap" w={'100%'}>
            {array?.map((x, i) => {
              return (
                <div
                  style={{
                    aspectRatio: 1,
                    width: `${100 / ICON_COLUMNS}%`,
                  }}
                  key={x + i}
                  className=" p-2"
                >
                  <div
                    className={`bg-white h-100 flex flex-col items-center justify-center rounded-2
                      cursor-pointer border border-${
                        clicked.replace('fa-', '') === x.replace('fa-', '')
                          ? 'primary'
                          : 'white'
                      }`}
                    onClick={() => {
                      setSelectedIcon('fa-' + x?.replace('fa-', ''));
                      setClickedIcon('fa-' + x?.replace('fa-', ''));
                      onIconChange?.('fa-' + x?.replace('fa-', ''));
                    }}
                  >
                    <IconFA icon={x} />
                    <div className=" mt-2 text-center" style={{ fontSize: 10 }}>
                      {x}
                    </div>
                  </div>
                </div>
              );
            })}
          </Flex>
          <SectionFooter>
            <Button onClick={() => setModalShow(false)}>
              {t('general.save')}
            </Button>
          </SectionFooter>
        </Modal>
      </Grid.Col>
    </Grid>
  );
};
export default SelectIconComponent;
