import IconFA from 'components/common/IconFA';
import { currencyFormatter } from 'helpers/utils';
import { t } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { getColor } from '../../../helpers/utils';
import OrderStatus from 'pages/orders/components/OrderStatus';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import {
  SectionBody,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { Anchor, Flex, Grid, NumberInput, Text } from '@mantine/core';
import OrderItem from 'pages/add-draft-carts/components/OrderItem';
import ShippingMethodCard from './ShippingMethodCard';

type Props = {
  seller?: any;
  onChangeQuantity?: any;
  onDeleteVariant?: any;
  cacheQuantity?: any;
  isAdd?: boolean;
  shipping?: any;
  onChangeShipping?: any;
  onRefetchShipping?: any;
  gettingShipping?: any;
  isCompleted?: boolean;
};

const SellerCard = ({
  seller,
  onChangeQuantity,
  onDeleteVariant,
  cacheQuantity,
  isAdd,
  shipping,
  onChangeShipping,
  onRefetchShipping,
  gettingShipping,
  isCompleted,
}: Props) => {
  const { store_name, _id, products, order } = seller || {};
  const storeLink = `/sellers/${_id}`;
  const [selectedShipping, setSelectedShipping] = useState<any>();
  const items = useMemo(() => {
    let array = [];
    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      for (let y = 0; y < product?.variants.length; y++) {
        const variant = product?.variants[y];
        array.push({ ...variant, product_id: product?._id });
      }
    }
    return array;
  }, [seller]);
  useEffect(() => {
    if (selectedShipping?.id) {
      onChangeShipping?.(selectedShipping);
    }
  }, [selectedShipping]);
  useEffect(() => {
    if (
      !selectedShipping ||
      !shipping?.shipping_items?.find(
        (x: { id: any }) => x?.id === selectedShipping?.id,
      )
    ) {
      setSelectedShipping(shipping?.shipping_items?.[0]);
    }
  }, [shipping]);
  return (
    <SectionWrapper>
      <SectionBody>
        {order ? (
          <Flex className="mb-3" justify={'space-between'}>
            <Text>
              {t('orders.order')}{' '}
              <Anchor component={Link} to={`/orders/${order?._id}`}>
                #{order?.code}
              </Anchor>{' '}
              {t('general.created_date').toLowerCase()}{' '}
              {dayjs
                .unix(order?.created_on)
                .format('MMMM DD YYYY, [' + t('general.at') + '] HH:mm')}
            </Text>
            <OrderStatus status={order?.order_status} />
          </Flex>
        ) : null}
        <Flex align={'center'}>
          <IconFA icon="store" className={' me-1'} type="light" />
          <Text>{t('orders.store')}: </Text>
          <Anchor className="ms-1" component={Link} to={storeLink}>
            {store_name}
          </Anchor>
        </Flex>
        {/* HEADER */}
        <Grid className="mt-3">
          <Grid.Col span={{ base: 5, sm: 5 }}>
            <Text fw={'bold'} size="sm">
              {t('orders.products')}
            </Text>
          </Grid.Col>
          <Grid.Col
            className="justify-center flex self-center"
            span={{ base: 2, sm: 2 }}
          >
            <Text fw={'bold'} size="sm" ta={'right'}>
              {t('orders.quantity')}
            </Text>
          </Grid.Col>
          <Grid.Col
            className="justify-center flex self-center"
            span={{ base: 2, sm: 2 }}
          >
            <Text fw={'bold'} size="sm" ta={'right'}>
              {t('orders.unit_price')}
            </Text>
          </Grid.Col>
          <Grid.Col
            className="justify-center flex self-center"
            span={{ base: 2, sm: 2 }}
          >
            <Text fw={'bold'} size="sm" ta={'right'}>
              {t('orders.amount')}
            </Text>
          </Grid.Col>
          <Grid.Col
            className="justify-center flex self-center"
            span={{ base: 1, sm: 1 }}
          ></Grid.Col>
        </Grid>
        {/* ITEMS */}
        {items?.map((item, index) => {
          const { price = 0, discount_price = 0 } = item || {};
          const quantity =
            cacheQuantity?.[item?.variant_id] || item?.quantity || 1;
          const isOnSale = discount_price < price;
          const amount = price * quantity || 0;
          const discountAmount = discount_price * quantity || 0;
          return (
            <Grid key={index}>
              <Grid.Col span={{ base: 5, sm: 5 }}>
                <OrderItem key={index} item={item} />
              </Grid.Col>
              <Grid.Col
                className="justify-center flex self-center"
                span={{ base: 2, sm: 2 }}
              >
                {isCompleted ? (
                  <div className="">{quantity}</div>
                ) : (
                  <NumberInput
                    size="xs"
                    min={1}
                    max={item?.available_stock}
                    value={quantity}
                    defaultValue={item?.quantity || 1}
                    onChange={(value) => {
                      const qty =
                        value > item?.available_stock
                          ? item?.available_stock
                          : value;

                      onChangeQuantity?.(
                        item?.product_id,
                        item?.variant_id,
                        parseInt(qty),
                      );
                    }}
                  />
                )}
              </Grid.Col>
              <Grid.Col
                className="justify-center flex self-center"
                span={{ base: 2, sm: 2 }}
              >
                <div>
                  <div className={`${isOnSale ? 'text-danger ' : ''}`}>
                    {currencyFormatter.format(discount_price)}
                  </div>
                  {isOnSale && (
                    <div className="text-500 text-decoration-line-through">
                      {currencyFormatter.format(price)}
                    </div>
                  )}
                </div>
              </Grid.Col>
              <Grid.Col
                className="justify-center flex self-center"
                span={{ base: 2, sm: 2 }}
              >
                <div>
                  <div className={`${isOnSale ? 'text-danger ' : ''}`}>
                    {currencyFormatter.format(discountAmount)}
                  </div>
                  {isOnSale && (
                    <div className="text-500 text-decoration-line-through">
                      {currencyFormatter.format(amount)}
                    </div>
                  )}
                </div>
              </Grid.Col>
              <Grid.Col
                className="justify-center flex self-center"
                span={{ base: 1, sm: 1 }}
              >
                {isCompleted ? null : (
                  <IconFA
                    icon={faTrash}
                    onClick={() => onDeleteVariant?.(item)}
                    color={getColor('gray-500')}
                  />
                )}
              </Grid.Col>
            </Grid>
          );
        })}
        {isAdd || isCompleted ? null : (
          <ShippingMethodCard
            key={seller?._id}
            shipping={shipping}
            selectedShipping={selectedShipping}
            setSelectedShipping={setSelectedShipping}
            onRefetchShipping={onRefetchShipping}
            gettingShipping={gettingShipping}
          />
        )}
      </SectionBody>
    </SectionWrapper>
  );
};
export default SellerCard;
